import React, { useEffect, useState } from 'react';
import {
  Button,
  FormGroup,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  Row,
  Col,
  Container,
  Input,
  Label,
  NavLink as TabLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { requestApi } from '../../../request-api';
import { useParams, useNavigate } from 'react-router-dom';
import { directUrl } from '../../../helpers/Constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function EditDisposition() {
  let navigate = useNavigate();
  const { id } = useParams();
  let [currentActiveTab, setCurrentActiveTab] = useState('1');
  let [showCalender, setShowCalender] = useState(false);
  let [selectedDate, setSelectedDate] = useState(null);

  let [dispositionName, setDispositionName] = useState('');

  let [remark, setRemark] = useState('');

  let [formErrors, setFormErrors] = useState({});
  let [loading, setLoading] = useState(false);
  let [modal, setModal] = useState(false);

  const successToast = (message) =>
    toast.success(message, {
      position: 'bottom-right',
    });

  const errorToast = (error) =>
    toast.error(error, {
      position: 'bottom-right',
    });

  const toggle = (tab) => {
    if (currentActiveTab !== tab) {
      setCurrentActiveTab(tab);
    }
  };

  const customModal = () => {
    setModal(!modal);
  };

  const formValidation = () => {
    setLoading(true);
    const errors = {};

    if (dispositionName === '') {
      // errorToast('Kindly Enter Disposition Name');
      errors.error = 'Kindly Enter Disposition Name';
    }

    setLoading(false);
    return errors;
  };

  useEffect(() => {
    setLoading(true);
    getDispositionData();
    setLoading(false);
  }, []);

  const getDispositionData = async () => {
    setLoading(true);
    requestApi(`/v1/disposition/${id}`, {
      method: 'get',
    })
      .then((data) => {
        setDispositionName(data.data.name);
        setRemark(data.data.remark);

        setLoading(false);
      })
      .catch((error) => {
        errorToast(error);
        setLoading(false);
      });
  };
  const createDispositionData = async () => {
    setLoading(true);
    requestApi(
      `/v1/disposition/${id}`,
      {
        method: 'put',
        body: JSON.stringify({
          name: dispositionName,
          remark: remark,
        }),
      },
      navigate
    )
      .then((data) => {
        console.log(data);
        if (data.statusCode === 200) {
          setLoading(false);
          successToast('Successfully created Disposition');
          navigate(`${directUrl}admin/disposition`);
        }
      })
      .catch((error) => {
        setLoading(false);
        errorToast(error.message);
        console.log(error);
      });
  };

  const validateBanner = async () => {
    const validate = formValidation();
    setFormErrors(validate);
    if (Object.keys(validate).length === 0) {
      createDispositionData();
    }
  };

  const handleDateChange = (date) => {
    console.log(new Date(date));
    setSelectedDate(new Date(date));
  };
  // useEffect(() => {
  //   setSelectedDate(
  //     `${calendarValue.getDate()}/${
  //       calendarValue.getMonth() + 1
  //     }/${calendarValue.getFullYear()}`
  //   );
  // }, [calendarValue]);
  return (
    <main onClick={() => setShowCalender(!showCalender)}>
      <section className="lyt-section"></section>
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : null}

      <section className="lyt-section p-0">
        <Container fluid className="typ-container">
          <div className="bs-card">
            <Row>
              <div className="bs-tabs">
                <Nav tabs>
                  <NavItem>
                    <TabLink
                      className={classnames({
                        active: currentActiveTab === '1',
                      })}
                      onClick={() => {
                        toggle('1');
                      }}
                    >
                      Edit Disposition
                    </TabLink>
                  </NavItem>
                  <div></div>
                </Nav>

                {/* <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                  <div
                    className="dropdown-bx report-date"
                    // className="calender-area"
                  >
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat="dd/MM/yyyy" // Specify the date format
                      placeholderText="Select Payment Date" // Placeholder text for the input
                      className="typ-date"
                    />
                  </div>
                </Col> */}
              </div>

              <TabContent activeTab={currentActiveTab} className="px-0">
                <TabPane tabId="1">
                  <Container fluid>
                    <FormGroup className="bs-form">
                      <Row>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            Remark
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="remark"
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                          />
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            Disposition Name{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Name"
                            value={dispositionName}
                            onChange={(e) => setDispositionName(e.target.value)}
                          />
                          <p className="error-message">{formErrors.error}</p>
                        </Col>

                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <button
                            style={{ marginTop: '10px' }}
                            className="bs-btn "
                            type="button"
                            onClick={(e) => {
                              validateBanner(e);
                            }}
                          >
                            SUBMIT
                          </button>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Container>
                </TabPane>
              </TabContent>
            </Row>
          </div>
          <ToastContainer />
        </Container>
      </section>
    </main>
  );
}

export default EditDisposition;
