import React from 'react';
import { jwtDecode } from 'jwt-decode';
import { Navigate, Outlet } from 'react-router-dom';

const useAuth = () => {
  let user = localStorage.getItem('x-access-token');

  user = user ? jwtDecode(user) : null;
  console.log('--->', user);
  if (user) {
    return {
      auth: true,
      role: user.role_id,
    };
  } else {
    return {
      auth: false,
      role: null,
    };
  }
};

const ProtectedRoutes = (props) => {
  const { auth, role } = useAuth();
  console.log('xxxxxxxxx', props.roleRequired, role, auth);
  if (props.roleRequired) {
    return auth ? (
      props.roleRequired === role ? (
        <Outlet />
      ) : (
        <Navigate to={`sign-in`} />
      )
    ) : (
      <Navigate to={`sign-in`} />
    );
  } else {
    return auth ? <Outlet /> : <Navigate to={`sign-in`} />;
  }
};

export default ProtectedRoutes;
