import React, { useEffect, useState } from 'react';
import {
  Button,
  FormGroup,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  Row,
  Col,
  Container,
  Input,
  Label,
  NavLink as TabLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
import { requestApi } from '../../../request-api';
import { useNavigate, useParams } from 'react-router-dom';
import { directUrl, homePageUrl } from '../../../helpers/Constants';

//assets
import upload from '../../../assets/image/icons/upload.svg';
import { async } from 'q';
import { API_URL } from '../../../config';
import { userStore } from '../../../stores';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Triangle } from 'react-loader-spinner';
import { width } from '@mui/system';
import FileSaver from 'file-saver';
import xls from '../../../assets/bulkBranchUplod.xlsx';
import { jwtDecode } from 'jwt-decode';

function UserSetting() {
  let navigate = useNavigate();
  const [user, setUser] = useState({});

  const getUserToken = async () => {
    const token = localStorage.getItem('x-access-token');
    let users;
    if (token) {
      users = jwtDecode(token);
      return users;
    }
    navigate(`${directUrl}sign-in`);
  };

  let [currentActiveTab, setCurrentActiveTab] = useState('1');
  let [showCalender, setShowCalender] = useState(false);
  let [branchName, setBranchName] = useState('');
  let [employeeName, setEmployeeName] = useState('');
  let [empId, setEmpId] = useState('');
  let [phoneNumber, setPhoneNumber] = useState('');
  let [userRole, setUserRole] = useState('');
  let [emailId, setEmailId] = useState('');
  let [password, setPassword] = useState('');
  let [confirmPassword, setConfirmPassword] = useState('');
  let [allBranch, setAllBranch] = useState([]);
  let [role, setRole] = useState(['admin', 'user']);
  const [fileData, setFileData] = useState([]);
  let [formErrors, setFormErrors] = useState({});
  let [isSubmit, setIsSubmit] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  let [loading, setLoading] = useState(false);
  let [modal, setModal] = useState(false);
  let { id } = useParams();
  const successToast = (message) =>
    toast.success(message, {
      position: 'bottom-right',
    });

  const errorToast = (error) =>
    toast.error(error, {
      position: 'bottom-right',
    });

  const toggle = (tab) => {
    if (currentActiveTab !== tab) {
      setCurrentActiveTab(tab);
    }
  };

  const customModal = () => {
    console.log('method called', modal);
    setModal(!modal);
  };
  const getUser = async () => {
    setLoading(true);
    const users = await getUserToken();
    console.debug('sending code', userRole);
    requestApi(
      `/v1/get-user/${users.id}`,
      {
        method: 'get',
      },
      navigate
    )
      .then(async (data) => {
        console.log(data);
        if (data.statusCode === 200) {
          let branch = await getBranch();
          // branch = branch.filter((b) => b.id === data.data.branch_id);
          // console.log('=========>', branch);
          // setBranchName(branch[0].branch_name);
          setBranchName(data.data.branch_id);
          setEmailId(data.data.email);
          setEmployeeName(data.data.name);
          setEmpId(data.data.emp_id);
          setPhoneNumber(data.data.phone);
          setUserRole(data.data.role);

          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        errorToast(error.message);
        console.log(error);
      });
  };
  const getBranch = async () => {
    setLoading(true);
    console.debug('sending code', userRole);
    return requestApi(
      '/v1/branch/get-active-branch',
      {
        method: 'get',
      },
      navigate
    )
      .then((data) => {
        console.log(data);
        if (data.statusCode === 200) {
          setLoading(false);
          setAllBranch(data.data);
          return data.data;
        }
      })
      .catch((error) => {
        setLoading(false);
        errorToast(error.message);
        console.log(error);
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    getBranch();
    getUser();
  }, []);

  const formValidation = (values) => {
    setLoading(true);
    console.log(role);
    const errors = {};
    const mobRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (currentActiveTab === '1') {
      if (employeeName == '') {
        errors.employeeName = 'Employee Name is required';
      }
      console.log(branchName, typeof branchName);
      if (branchName == '' || typeof parseInt(branchName) !== 'number') {
        errors.branchName = 'Branch Name is required';
      }
      if (userRole == '') {
        errors.userRole = 'Role is required';
      }
      if (empId == '') {
        errors.empId = 'Employee ID is required';
      }
      if (phoneNumber == '') {
        errors.phoneNumber = 'Phone Number is required';
      } else if (!mobRegex.test(phoneNumber)) {
        errors.phoneNumber = 'Invalid Number';
      }
      if (emailId == '') {
        errors.email = 'Email Address is required';
      } else if (!emailRegex.test(emailId)) {
        errors.email = 'Invalid Email Address';
      }
      // if (password == '') {
      //   errors.password = 'Password is required';
      // } else
      if (password.length < 8 && password.length != 0) {
        errors.password = 'Password must be at least 8 characters long';
      }
      // if (confirmPassword === '') {
      //   errors.confirmPassword = 'Confirm Password is required';
      // }
      if (password !== confirmPassword) {
        errors.confirmPassword = 'Confirm Password is not similar to Password ';
      }

      setLoading(false);
    }
    if (currentActiveTab === '2') {
      if (fileData.length == 0) {
        errors.file = 'File is required';
      } else {
      }
      setLoading(false);
    }

    return errors;
  };
  const createEmployee = async () => {
    setLoading(true);
    const users = await getUserToken();
    requestApi(
      `/v1/update-user/${users.id}`,
      {
        method: 'put',
        body: JSON.stringify({
          emp_id: empId,
          name: employeeName,
          email: emailId,
          role: userRole,
          phone: phoneNumber,
          password: password ? password : null,
          branch_id: branchName,
        }),
      },
      navigate
    )
      .then((data) => {
        console.log(data);
        if (data.statusCode === 200) {
          setLoading(false);
          successToast('Successfully Update User');
          navigate(`${directUrl}admin/employee`);
        }
        if (data.statusCode === 400) {
          setLoading(false);
          errorToast('User Already Exist');
        }
      })
      .catch((error) => {
        setLoading(false);
        errorToast(error.message);
        console.log(error);
      });
  };

  const validateBanner = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    const validate = formValidation();
    setFormErrors(validate);
    console.log('validate', validate);

    if (Object.keys(validate).length == 0) {
      createEmployee(e);
    }
  };
  return (
    <main onClick={() => setShowCalender(!showCalender)}>
      <section className="lyt-section"></section>
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : null}

      <section className="lyt-section p-0">
        <Container fluid className="typ-container">
          <div className="bs-card">
            <Row>
              <div className="bs-tabs">
                <Nav tabs>
                  <NavItem>
                    <TabLink
                      className={classnames({
                        active: currentActiveTab === '1',
                      })}
                      onClick={() => {
                        toggle('1');
                      }}
                    >
                      Admin
                    </TabLink>
                  </NavItem>
                </Nav>
              </div>
              <TabContent activeTab={currentActiveTab} className="px-0">
                <TabPane tabId="1">
                  <Container fluid>
                    <FormGroup className="bs-form">
                      <Row>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            Branch Name<span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            className="typ-select mob-margin cstm-input"
                            type="select"
                            name="name"
                            id="exampleSelect"
                            defaultValue={branchName}
                            value={branchName}
                            readOnly={true}
                            onChange={(e) => {
                              setBranchName(e.target.value);
                            }}
                          >
                            <option key={0} value={''}>
                              Select Branch
                            </option>
                            {allBranch.map((comp) => {
                              return (
                                <option key={comp.id} value={comp.id}>
                                  {comp.branch_name}
                                </option>
                              );
                            })}
                          </Input>
                          <p className="error-message">
                            {formErrors.branchName}
                          </p>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            Employee Name
                            <span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            name="text"
                            id="examplePassword"
                            className="cstm-input"
                            placeholder="Enter Employee Name"
                            value={employeeName}
                            onChange={(e) => {
                              setEmployeeName(e.target.value);
                            }}
                          />
                          <p className="error-message">
                            {formErrors.employeeName}
                          </p>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="exampleEmail"
                            className="mr-sm-2 cstm-label"
                          >
                            Employee ID<span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            name="text"
                            id="exampleEmail"
                            className="cstm-input"
                            placeholder="Enter Employee ID"
                            readOnly={true}
                            value={empId}
                            onChange={(e) => {
                              setEmpId(e.target.value);
                            }}
                          />
                          <p className="error-message">{formErrors.empId}</p>
                        </Col>

                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="exampleEmail"
                            className="mr-sm-2 cstm-label"
                          >
                            Phone Number<span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            name="text"
                            id="exampleEmail"
                            className="cstm-input"
                            placeholder="Enter Phone Number"
                            value={phoneNumber}
                            onChange={(e) => {
                              setPhoneNumber(e.target.value);
                            }}
                          />
                          <p className="error-message">
                            {formErrors.phoneNumber}
                          </p>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            User Role
                          </Label>
                          <Input
                            // className="typ-select mob-margin"
                            type="text"
                            name="text"
                            id="exampleSelect"
                            readOnly={true}
                            value={userRole}
                            // onChange={(e) => {
                            //   setUserRole(e.target.value);
                            // }}
                          >
                            {/* <option key={0} value={''}>
                              Select Role
                            </option>
                            {role.map((comp) => {
                              return (
                                <option key={comp} value={comp}>
                                  {comp}
                                </option>
                              );
                            })} */}
                          </Input>
                          <p className="error-message">{formErrors.userRole}</p>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="exampleEmail"
                            className="mr-sm-2 cstm-label"
                          >
                            Email ID<span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="email"
                            name="email"
                            className="cstm-input"
                            id="exampleEmail"
                            placeholder="Enter Email ID"
                            value={emailId}
                            onChange={(e) => {
                              setEmailId(e.target.value);
                            }}
                          />
                          <p className="error-message">{formErrors.email}</p>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="examplepassword"
                            className="mr-sm-2 cstm-label"
                          >
                            Password
                          </Label>
                          <Input
                            type="password"
                            name="password"
                            className="cstm-input"
                            id="examplepassword"
                            placeholder="Enter password"
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                          />
                          <p className="error-message">{formErrors.password}</p>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            Confirm Password
                            {password.length > 0 ? (
                              <span style={{ color: 'red' }}>*</span>
                            ) : (
                              ''
                            )}
                          </Label>
                          <Input
                            type="password"
                            name="Confirm Password"
                            className="cstm-input"
                            id="examplePassword"
                            placeholder="Enter Confirm Password"
                            onChange={(e) => {
                              setConfirmPassword(e.target.value);
                            }}
                          />
                          <p className="error-message">
                            {formErrors.confirmPassword}
                          </p>
                        </Col>

                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <button
                            className="bs-btn "
                            type="button"
                            onClick={(e) => {
                              validateBanner(e);
                            }}
                          >
                            SUBMIT
                          </button>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Container>
                </TabPane>
              </TabContent>
            </Row>
          </div>
          <ToastContainer />
        </Container>
      </section>

      <Modal
        isOpen={modal}
        toggle={customModal}
        size={'lg'}
        centered={true}
        className="bs-modal"
      >
        <ModalHeader toggle={customModal}></ModalHeader>
        <ModalBody toggle={customModal}>
          <p className="title">
            A report with same ID is already in the system – Report ID
            HDFC_worli_12234. Do you wish to replace the Report?
          </p>
        </ModalBody>
        <ModalFooter>
          <button
            onClick={validateBanner}
            className="bs-btn typ-replace min-width margin-right"
          >
            replace
          </button>{' '}
          <button
            onClick={customModal}
            className="bs-btn typ-outline min-width"
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </main>
  );
}

export default UserSetting;
