import React from 'react';
import Avatar from './Avatar';
import { Link } from 'react-router-dom';

const UserSearchCard = ({ user, onClose }) => {
  console.log(user);
  return (
    <Link
      to={`/chat/${user?.id}`}
      onClick={onClose}
      aria-label={`Chat with ${user?.name}`}
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '0.75rem',
        padding: '0.75rem',
        border: '1px solid transparent',
        borderBottom: '1px solid #e5e7eb',
        borderRadius: '0.5rem',
        transition: 'all 0.3s ease-in-out',
        textDecoration: 'none',
        backgroundColor: '#fff',
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.borderColor = '#6ee167';
        e.currentTarget.style.backgroundColor = '#f1f2f2';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.borderColor = 'transparent';
        e.currentTarget.style.backgroundColor = '#fff';
      }}
    >
      <div>
        <Avatar
          width={50}
          height={50}
          name={user?.name}
          userId={user?.id}
          imageUrl={user?.profile_pic}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            fontWeight: '600',
            fontSize: '1rem',
            color: '#1c1c1c',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {user?.name}
        </div>
        <p
          style={{
            fontSize: '0.875rem',
            color: '#6b7280',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {user?.email}
        </p>
      </div>
    </Link>
  );
};

export default UserSearchCard;
