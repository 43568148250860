import React, { useEffect, useState } from 'react';
import {
  Button,
  FormGroup,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  Row,
  Col,
  Container,
  Input,
  Label,
  NavLink as TabLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { requestApi } from '../../../request-api';
import { useNavigate } from 'react-router-dom';
import { directUrl } from '../../../helpers/Constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AddBranch() {
  let navigate = useNavigate();

  let [currentActiveTab, setCurrentActiveTab] = useState('1');
  let [calendarValue, setCalenderValue] = useState(new Date());
  let [showCalender, setShowCalender] = useState(false);
  let [selectedDate, setSelectedDate] = useState('');
  let [branchId, setBranchId] = useState('');
  let [branchName, setBranchName] = useState('');
  let [phoneNumber, setPhoneNumber] = useState('');
  let [userRole, setUserRole] = useState('');
  let [emailId, setEmailId] = useState('');
  let [address, setAddress] = useState('');
  let [contactPerson, setContactPerson] = useState('');
  let [formErrors, setFormErrors] = useState({});
  let [loading, setLoading] = useState(false);
  let [modal, setModal] = useState(false);

  const successToast = (message) =>
    toast.success(message, {
      position: 'bottom-right',
    });

  const errorToast = (error) =>
    toast.error(error, {
      position: 'bottom-right',
    });

  const toggle = (tab) => {
    if (currentActiveTab !== tab) {
      setCurrentActiveTab(tab);
    }
  };

  const customModal = () => {
    setModal(!modal);
  };

  const formValidation = () => {
    setLoading(true);
    const errors = {};
    const mobRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (currentActiveTab === '1') {
      if (branchId === '') {
        errors.branchId = 'Branch Id is required';
      }
      if (branchName === '') {
        errors.branchName = 'Branch Name is required';
      }
      if (phoneNumber === '') {
        errors.phoneNumber = 'Phone Number is required';
      } else if (!mobRegex.test(phoneNumber)) {
        errors.phoneNumber = 'Invalid Number';
      }
      if (emailId === '') {
        errors.email = 'Email Address is required';
      } else if (!emailRegex.test(emailId)) {
        errors.email = 'Invalid Email Address';
      }
      if (address === '') {
        errors.address = 'Office Address is required';
      }
      if (contactPerson === '') {
        errors.contactPerson = 'Contact Person is required';
      }
      setLoading(false);
    }
    return errors;
  };

  const createNewBranch = async () => {
    setLoading(true);
    requestApi(
      '/v1/branch/add-branch',
      {
        method: 'post',
        body: JSON.stringify({
          branch_id: branchId,
          branch_name: branchName,
          email: emailId,
          contact_person: contactPerson,
          address: address,
          phone: phoneNumber,
        }),
      },
      navigate
    )
      .then((data) => {
        console.log(data);
        if (data.statusCode === 201) {
          setLoading(false);
          successToast('Successfully created branch');
          navigate(`${directUrl}admin/branch`);
        }
        if (data.statusCode === 400) {
          setLoading(false);
          errorToast('Branch Already Exist');
        }
      })
      .catch((error) => {
        setLoading(false);
        errorToast(error.message);
        console.log(error);
      });
  };

  const validateBanner = async () => {
    const validate = formValidation();
    setFormErrors(validate);
    if (Object.keys(validate).length === 0) {
      createNewBranch();
    }
  };
  

  useEffect(() => {
    setLoading(true);
    setLoading(false);
  }, []);

  return (
    <main onClick={() => setShowCalender(!showCalender)}>
      <section className="lyt-section"></section>
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : null}

      <section className="lyt-section p-0">
        <Container fluid className="typ-container">
          <div className="bs-card">
            <Row>
              <div className="bs-tabs">
                <Nav tabs>
                  <NavItem>
                    <TabLink
                      className={classnames({
                        active: currentActiveTab === '1',
                      })}
                      onClick={() => {
                        toggle('1');
                      }}
                    >
                      Add Branch
                    </TabLink>
                  </NavItem>
                </Nav>
              </div>
              <TabContent activeTab={currentActiveTab} className="px-0">
                <TabPane tabId="1">
                  <Container fluid>
                    <FormGroup className="bs-form">
                      <Row>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label className="mr-sm-2 cstm-label">
                            Branch Id
                            <span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Enter Branch Name"
                            onChange={(e) => setBranchId(e.target.value)}
                          />
                          <p className="error-message">{formErrors.branchId}</p>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label className="mr-sm-2 cstm-label">
                            Branch Name
                            <span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Enter Branch Name"
                            onChange={(e) => setBranchName(e.target.value)}
                          />
                          <p className="error-message">
                            {formErrors.branchName}
                          </p>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label className="mr-sm-2 cstm-label">
                            Phone Number<span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Enter Phone Number"
                            onChange={(e) => setPhoneNumber(e.target.value)}
                          />
                          <p className="error-message">
                            {formErrors.phoneNumber}
                          </p>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label className="mr-sm-2 cstm-label">
                            Contact Person
                            <span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Enter Contact Person"
                            onChange={(e) => setContactPerson(e.target.value)}
                          />
                          <p className="error-message">
                            {formErrors.contactPerson}
                          </p>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label className="mr-sm-2 cstm-label">
                            Email ID<span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="email"
                            className="cstm-input"
                            placeholder="Enter Email ID"
                            onChange={(e) => setEmailId(e.target.value)}
                          />
                          <p className="error-message">{formErrors.email}</p>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label className="mr-sm-2 cstm-label">
                            Address<span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Enter Address"
                            onChange={(e) => setAddress(e.target.value)}
                          />
                          <p className="error-message">{formErrors.address}</p>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <button
                            className="bs-btn "
                            type="button"
                            onClick={(e) => {
                              validateBanner(e);
                            }}
                          >
                            SUBMIT
                          </button>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Container>
                </TabPane>
              </TabContent>
            </Row>
          </div>
          <ToastContainer />
        </Container>
      </section>

      <Modal
        isOpen={modal}
        toggle={customModal}
        size={'lg'}
        centered={true}
        className="bs-modal"
      >
        <ModalHeader toggle={customModal}></ModalHeader>
        <ModalBody toggle={customModal}>
          <p className="title">
            A report with the same ID is already in the system – Report ID
            HDFC_worli_12234. Do you wish to replace the Report?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={validateBanner}
            className="bs-btn typ-replace min-width margin-right"
          >
            Replace
          </Button>{' '}
          <Button
            onClick={customModal}
            className="bs-btn typ-outline min-width"
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </main>
  );
}

export default AddBranch;
