import React, { useEffect, useState } from 'react';
import {
  Button,
  FormGroup,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  Row,
  Col,
  Container,
  Input,
  Label,
  NavLink as TabLink,
} from 'reactstrap';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { requestApi } from '../../../request-api';
import { useNavigate, useParams } from 'react-router-dom';
import { directUrl } from '../../../helpers/Constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function EditHolding() {
  const { id } = useParams();
  const navigate = useNavigate();

  let [showCalender, setShowCalender] = useState(false);
  let [selectedDate, setSelectedDate] = useState(null);
  let [fetchGross, setFetchGross] = useState(0);
  let [HoldingName, setHoldingName] = useState('');
  const [currentActiveTab, setCurrentActiveTab] = useState('1');
  const [startDate, setStartDate] = useState(new Date());
  const [level, setLevel] = useState('');
  const [qty, setQty] = useState(0);
  const [lots, setLots] = useState(0);
  const [price, setPrice] = useState(0);
  const [remark, setRemark] = useState('');
  const [remark2, setRemark2] = useState('');
  const [remark3, setRemark3] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch existing holding data
    const fetchHoldingData = async () => {
      setLoading(true);
      try {
        const data = await requestApi(`/v1/holding/${id}`, {
          method: 'get',
        });
        if (data) {
          // setStartDate(new Date(data.date));
          console.log(data);
          setLevel(data?.data.level);
          setQty(data?.data.qty);
          setLots(data?.data.lots);
          setPrice(data?.data.buy_price);
          setRemark(data?.data.remark);
          setRemark2(data?.data.remark2);
          setRemark3(data?.data.remark3);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);

        errorToast('Failed to fetch holding data');
      }
    };
    fetchHoldingData();
  }, [id]);

  const successToast = (message) =>
    toast.success(message, {
      position: 'bottom-right',
    });

  const errorToast = (error) =>
    toast.error(error, {
      position: 'bottom-right',
    });

  const toggle = (tab) => {
    if (currentActiveTab !== tab) {
      setCurrentActiveTab(tab);
    }
  };

  const formValidation = () => {
    setLoading(true);
    const errors = {};
    if (!startDate) {
      errors.datepicker = 'Date is required';
    }
    if (!level) {
      errors.level = 'Level is required';
    }
    if (!qty) {
      errors.qty = 'QTY is required';
    }
    if (!lots) {
      errors.lots = 'Lots is required';
    }
    if (!price) {
      errors.price = 'Buying Price is required';
    }
    if (remark?.length > 250 || remark?.length <= 0) {
      errors.remark = 'Remark cannot exceed 250 characters or Empty';
    }
    if (remark2?.length > 250 || remark2?.length <= 0) {
      errors.remark2 = 'Remark2 cannot exceed 250 characters ';
    }
    if (remark3?.length > 250 || remark3?.length <= 0) {
      errors.remark3 = 'Remark3 cannot exceed 250 characters';
    }
    console.log(remark2,remark3);
    setLoading(false);
    return errors;
  };

  const updateHoldingData = async () => {
    setLoading(true);
    try {
      const data = await requestApi(
        `/v1/holding/${id}`,
        {
          method: 'put',
          body: JSON.stringify({
            holdingId: id,
            date: startDate,
            level,
            qty,
            lots,
            buyPrice: price,
            remarks: remark,
            remark2: remark2,
            remark3: remark3,
          }),
        },
        navigate
      );
      if (data.statusCode === 200) {
        setLoading(false);
        successToast('Successfully updated holding');
        navigate(`${directUrl}employee/holdings`);
      }
    } catch (error) {
      setLoading(false);
      errorToast(error.message);
    }
  };

  const validateBanner = () => {
    const validate = formValidation();
    setFormErrors(validate);
    if (Object.keys(validate).length === 0) {
      updateHoldingData();
    }
  };
  return (
    <main onClick={() => setShowCalender(!showCalender)}>
      <section className="lyt-section"></section>
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : null}

      <section className="lyt-section p-0">
        <Container fluid className="typ-container">
          <div className="bs-card">
            <Row>
              <div className="bs-tabs">
                <Nav tabs>
                  <NavItem>
                    <TabLink
                      className={classnames({
                        active: currentActiveTab === '1',
                      })}
                      onClick={() => {
                        toggle('1');
                      }}
                    >
                      Edit Holding
                    </TabLink>
                  </NavItem>
                  <div></div>
                </Nav>

                {/* <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                  <div
                    className="dropdown-bx report-date"
                    // className="calender-area"
                  >
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat="dd/MM/yyyy" // Specify the date format
                      placeholderText="Select Payment Date" // Placeholder text for the input
                      className="typ-date"
                    />
                  </div>
                </Col> */}
              </div>

              <TabContent activeTab={currentActiveTab} className="px-0">
                <TabPane tabId="1">
                  <Container fluid>
                    <FormGroup className="bs-form">
                      <Row>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            Date
                            <span style={{ color: 'red' }}>*</span>
                          </Label>
                          {/* <div class="searchbox-wrap">
                                                <Input type="text" name="date" id="examplePassword" className='cstm-input' placeholder="Enter Date of Joining" />
                                                <button className='bs-btn typ-date'><span class="icon-calendar"></span></button>
                                            </div> */}
                          {/* {showCalender && <div className='bs-calender'>
                                                <Calendar onChange={setCalenderValue} value={calendarValue} />
                                            </div>} */}
                          <div className="calender-area">
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              readOnly
                              placeholderText="Enter Date "
                            />
                          </div>
                          <p className="error-message">
                            {formErrors.datepicker}
                          </p>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            Level<span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Level"
                            value={level}
                            onChange={(e) => setLevel(e.target.value)}
                          />
                          <p className="error-message">{formErrors.level}</p>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            QTY<span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="QTY"
                            value={qty}
                            pattern="[0-9]*" // Only allow numeric characters
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value) || 0
                              )
                                .toString()
                                .slice(0, 20); // Limit the input to 10 characters
                            }}
                            onChange={(e) => setQty(e.target.value)}
                          />
                          <p className="error-message">{formErrors.qty}</p>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            Lots<span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Lots"
                            value={lots}
                            pattern="[0-9]*" // Only allow numeric characters
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value) || 0
                              )
                                .toString()
                                .slice(0, 20); // Limit the input to 10 characters
                            }}
                            onChange={(e) => setLots(e.target.value)}
                          />
                          <p className="error-message">{formErrors.lots}</p>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            Buying Price<span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Lots"
                            value={price}
                            pattern="[0-9]*" // Only allow numeric characters
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value) || 0
                              )
                                .toString()
                                .slice(0, 20); // Limit the input to 10 characters
                            }}
                            onChange={(e) => setPrice(e.target.value)}
                          />
                          <p className="error-message">{formErrors.price}</p>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            Remark
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Remark"
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                          />
                          <p className="error-message">{formErrors.remark}</p>
                        </Col>

                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            Remark2
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Remark"
                            value={remark2}
                            onChange={(e) => setRemark2(e.target.value)}
                          />
                          <p className="error-message">{formErrors.remark2}</p>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            Remark3
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Remark3"
                            value={remark3}
                            onChange={(e) => setRemark3(e.target.value)}
                          />
                          <p className="error-message">{formErrors.remark3}</p>
                        </Col>

                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <button
                            style={{ marginTop: '10px' }}
                            className="bs-btn "
                            type="button"
                            onClick={(e) => {
                              validateBanner(e);
                            }}
                          >
                            SUBMIT
                          </button>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Container>
                </TabPane>
              </TabContent>
            </Row>
          </div>
          <ToastContainer />
        </Container>
      </section>
    </main>
  );
}

export default EditHolding;
