import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Label, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
//assets

import logo from '../../../../assets/image/logo.png';
import { directUrl } from '../../../../helpers/Constants';
import { requestApi } from '../../../../request-api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const { id, token } = useParams();

  const navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  const successToast = (message) =>
    toast.success(message, {
      position: 'bottom-right',
    });

  const errorToast = (error) =>
    toast.error(error, {
      position: 'bottom-right',
    });
  const onSubmitDetails = async () => {
    setLoading(true);
    requestApi(
      `/v1/resetpassword/${id}/${token}`,
      {
        method: 'post',
        body: JSON.stringify({
          password,
          password_confirm: confirmPassword,
        }),
      },
      navigate
    )
      .then((data) => {
        console.log(data);
        if (data.statusCode === 200) {
          setLoading(false);
          successToast(data.message);
          navigate(`${directUrl}sign-in`);
        }
      })
      .catch((error) => {
        setLoading(false);
        errorToast(error.message);
        console.log(error);

        setTimeout(() => {
          navigate(`${directUrl}sign-in`);
        }, 3000);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (password.length < 8 && password.length > 16) {
      setError('Please enter an password between 8 to 16.');
    } else if (!password || !confirmPassword) {
      setError('Please enter a password');
    } else if (password !== confirmPassword) {
      setError('Confirm password does not match with password.');
    } else {
      // Proceed with the submission if the password is valid

      onSubmitDetails();
    }
  };
  // const [password, setPassword] = useState('');
  return (
    <section className="lyt-section typ-login">
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : null}
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col xs={12} sm={12} md={12} lg={6} xl={5}>
            <div className="bs-card typ-login">
              <div className="img-area">
                <picture>
                  <source media="(max-width:992px)" srcSet={logo} />
                  <img
                    src={logo}
                    style={{ maxWidth: '25%' }}
                    alt="JK Trading"
                  />
                </picture>
              </div>
              <form className="bs-form" onSubmit={(e) => onSubmit(password, e)}>
                <Label for="mail">Reset Password</Label>
                <div className="input-area">
                  <Input
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    type="password"
                    id="mail"
                    placeholder="Enter Your password"
                  />
                  <FontAwesomeIcon className="icon " icon={faLock} />
                </div>
                {/* <div className='mod-divider typ-or'></div>
                 */}
                <div className="input-area">
                  <Input
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    type="password"
                    id="mail"
                    placeholder="Enter Your confirm password"
                  />
                  <FontAwesomeIcon className="icon " icon={faLock} />
                </div>

                {error && (
                  <p
                    style={{
                      position: 'absolute',
                      fontSize: '12px',
                      color: '#d73038',
                      margin: '0 0px 10px',
                      fontFamily: 'roboto',
                      fontWeight: 500,
                    }}
                  >
                    {error}
                  </p>
                )}

                <button
                  onClick={(e) => onSubmit(e)}
                  className="bs-btn typ-login"
                  style={{ marginTop: 15 }}
                  type="button"
                >
                  Submit
                </button>
              </form>
            </div>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </section>
  );
};
