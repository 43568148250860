import React, { useEffect, useState } from 'react';
import {
  Button,
  FormGroup,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  Row,
  Col,
  Container,
  Input,
  Label,
  NavLink as TabLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { requestApi } from '../../../request-api';
import { useNavigate } from 'react-router-dom';
import { directUrl } from '../../../helpers/Constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CreatePayUpdate() {
  let navigate = useNavigate();

  let [currentActiveTab, setCurrentActiveTab] = useState('1');
  let [showCalender, setShowCalender] = useState(false);
  let [selectedDate, setSelectedDate] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  let [fetchGross, setFetchGross] = useState(0);
  let [HoldingName, setHoldingName] = useState('');

  let [remark, setRemark] = useState('');
  let [payment, setPayment] = useState(0);
  let [level, setLevel] = useState('');
  let [qty, setQty] = useState(0);
  let [lots, setLots] = useState(0);
  let [price, setPrice] = useState(0);
  let [formErrors, setFormErrors] = useState({});
  let [loading, setLoading] = useState(false);
  let [modal, setModal] = useState(false);

  const successToast = (message) =>
    toast.success(message, {
      position: 'bottom-right',
    });

  const errorToast = (error) =>
    toast.error(error, {
      position: 'bottom-right',
    });

  const toggle = (tab) => {
    if (currentActiveTab !== tab) {
      setCurrentActiveTab(tab);
    }
  };

  const customModal = () => {
    setModal(!modal);
  };

  const formValidation = () => {
    setLoading(true);
    const errors = {};
    if (!startDate) {
      errors.datepicker = 'Date is required';
    }
   
    
    if (!price) {
      errors.price = 'Payment is required';
    }

    if (remark.length > 250 || remark.length <= 0) {
      errors.remark = 'Remark cannot exceed 250 characters or Empty';
    }
    console.log(errors);
    setLoading(false);
    return errors;
  };

  const createHoldingData = async () => {
    setLoading(true);
    requestApi(
      '/v1/payment-update',
      {
        method: 'post',
        body: JSON.stringify({
          date: startDate || new Date(),
          payment: price,
          remark: remark,
        }),
      },
      navigate
    )
      .then((data) => {
        console.log(data);
        if (data.statusCode === 201) {
          setLoading(false);
          successToast('Successfully created Payment.');
          navigate(`${directUrl}employee/payment-updates`);
        }
      })
      .catch((error) => {
        setLoading(false);
        errorToast(error.message);
        console.log(error);
      });
  };

  const validateBanner = async () => {
    const validate = formValidation();
    setFormErrors(validate);
    if (Object.keys(validate).length === 0) {
      createHoldingData();
    }
  };

  const handleDateChange = (date) => {
    console.log(new Date(date));
    setSelectedDate(new Date(date));
  };
  // useEffect(() => {
  //   setSelectedDate(
  //     `${calendarValue.getDate()}/${
  //       calendarValue.getMonth() + 1
  //     }/${calendarValue.getFullYear()}`
  //   );
  // }, [calendarValue]);
  return (
    <main onClick={() => setShowCalender(!showCalender)}>
      <section className="lyt-section"></section>
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : null}

      <section className="lyt-section p-0">
        <Container fluid className="typ-container">
          <div className="bs-card">
            <Row>
              <div className="bs-tabs">
                <Nav tabs>
                  <NavItem>
                    <TabLink
                      className={classnames({
                        active: currentActiveTab === '1',
                      })}
                      onClick={() => {
                        toggle('1');
                      }}
                    >
                      Add Payment update
                    </TabLink>
                  </NavItem>
                  <div></div>
                </Nav>

                {/* <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                  <div
                    className="dropdown-bx report-date"
                    // className="calender-area"
                  >
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat="dd/MM/yyyy" // Specify the date format
                      placeholderText="Select Payment Date" // Placeholder text for the input
                      className="typ-date"
                    />
                  </div>
                </Col> */}
              </div>

              <TabContent activeTab={currentActiveTab} className="px-0">
                <TabPane tabId="1">
                  <Container fluid>
                    <FormGroup className="bs-form">
                      <Row>
                        <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            Date
                            <span style={{ color: 'red' }}>*</span>
                          </Label>
                          {/* <div class="searchbox-wrap">
                                                <Input type="text" name="date" id="examplePassword" className='cstm-input' placeholder="Enter Date of Joining" />
                                                <button className='bs-btn typ-date'><span class="icon-calendar"></span></button>
                                            </div> */}
                          {/* {showCalender && <div className='bs-calender'>
                                                <Calendar onChange={setCalenderValue} value={calendarValue} />
                                            </div>} */}
                          <div className="calender-area">
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              placeholderText="Enter Date "
                            />
                          </div>
                          <p className="error-message">
                            {formErrors.datepicker}
                          </p>
                        </Col>

                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            Payment<span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Lots"
                            value={price}
                            pattern="[0-9]*" // Only allow numeric characters
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value) || 0
                              )
                                .toString()
                                .slice(0, 20); // Limit the input to 10 characters
                            }}
                            onChange={(e) => setPrice(e.target.value)}
                          />
                          <p className="error-message">{formErrors.price}</p>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            Remark <span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Remark"
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                          />
                          <p className="error-message">{formErrors.remark}</p>
                        </Col>

                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <button
                            style={{ marginTop: '10px' }}
                            className="bs-btn "
                            type="button"
                            onClick={(e) => {
                              validateBanner(e);
                            }}
                          >
                            SUBMIT
                          </button>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Container>
                </TabPane>
              </TabContent>
            </Row>
          </div>
          <ToastContainer />
        </Container>
      </section>
    </main>
  );
}

export default CreatePayUpdate;
