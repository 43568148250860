import React, { useEffect, useState } from 'react';
import {
  Button,
  FormGroup,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  Row,
  Col,
  Container,
  Input,
  Label,
  NavLink as TabLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { requestApi } from '../../../request-api';
import { useNavigate, useParams } from 'react-router-dom';
import { directUrl } from '../../../helpers/Constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function EditTransaction() {
  let navigate = useNavigate();

  let [currentActiveTab, setCurrentActiveTab] = useState('1');
  let [calendarValue, setCalenderValue] = useState(new Date());
  let [showCalender, setShowCalender] = useState(false);
  let [selectedDate, setSelectedDate] = useState(null);
  let [transactionId, setTransactionId] = useState('');
  let [customer, setCustomer] = useState('');
  let [fetchCustomer, setFetchCustomer] = useState([]);
  let [fetchPayment, setFetchPayment] = useState([]);
  let [paymentType, setPaymentType] = useState('');
  let [fetchPartner, setFetchPartner] = useState([]);
  let [userRole, setUserRole] = useState('');
  let [emailId, setEmailId] = useState('');
  let [address, setAddress] = useState('');
  let [contactPerson, setContactPerson] = useState('');
  let [partner, setPartner] = useState('');
  let [gross, setGross] = useState(0);
  let [gst, setGst] = useState(0);
  let [net, setNet] = useState(0);
  let [formErrors, setFormErrors] = useState({});
  let [loading, setLoading] = useState(false);
  let [modal, setModal] = useState(false);
  let [is50, setIs50] = useState(false);
  let [isCust, setIsCust] = useState(false);
  let [upi, setUpi] = useState();
let { id } = useParams();
  const successToast = (message) =>
    toast.success(message, {
      position: 'bottom-right',
    });

  const errorToast = (error) =>
    toast.error(error, {
      position: 'bottom-right',
    });

  const toggle = (tab) => {
    if (currentActiveTab !== tab) {
      setCurrentActiveTab(tab);
    }
  };

  const customModal = () => {
    setModal(!modal);
  };

  const formValidation = () => {
    setLoading(true);
    const errors = {};
    const mobRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (currentActiveTab === '1') {
      if (transactionId === '') {
        errors.transactionId = 'Transaction Id is required';
      }
      if (customer === '') {
        errors.customer = 'Customer Name is required';
      }
      if (paymentType === '') {
        errors.paymentType = 'Payment Type is required';
      }
      if ((is50 || isCust) && partner === '') {
        errors.partner = 'Partner is required';
      }
      if (gross === 0 || gross === '') {
        errors.gross = 'Gross Amount is required';
      }
      if (!selectedDate) {
        errors.selectedDate = 'selectedDate is required';
        errorToast(`Payment Date is required.`);
      }
      setLoading(false);
    }
    return errors;
  };

  const createTransaction = async () => {
    setLoading(true);
    requestApi(
      '/v1/add-transaction',
      {
        method: 'post',
        body: JSON.stringify({
          customer_id: customer,
          transaction_id: transactionId,
          paymentType: paymentType,
          is50: is50,
          partner_id: is50 || isCust ? partner : 0,
          gross_amt: gross,
          gst_amt: gst,
          net_amt: net,
          is_cust_handling: isCust,
          date: selectedDate,
        }),
      },
      navigate
    )
      .then((data) => {
        console.log(data);
        if (data.statusCode === 201) {
          setLoading(false);
          successToast('Successfully created Transaction');
          navigate(`${directUrl}employee/home`);
        }
        if (data.statusCode === 400) {
          setLoading(false);
          errorToast('Branch Already Exist');
        }
      })
      .catch((error) => {
        setLoading(false);
        errorToast(error.message);
        console.log(error);
      });
  };

  
  const getCustomer = async () => {
    setLoading(true);
    requestApi(
      '/v1/get-customer',
      {
        method: 'get',
      },
      navigate
    )
      .then((data) => {
        console.log(data);
        if (data.statusCode === 200) {
          setLoading(false);
          // successToast('Successfully created branch');
          setFetchCustomer(data.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        errorToast(error.message);
        console.log(error);
      });
  };
  const getPayment = async () => {
    setLoading(true);
    requestApi(
      '/v1/payment',
      {
        method: 'get',
      },
      navigate
    )
      .then((data) => {
        console.log(data);
        if (data.statusCode === 200) {
          setLoading(false);
          // successToast('Successfully created branch');
          setFetchPayment(data.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        errorToast(error.message);
        console.log(error);
      });
  };
  const getEmployee = async () => {
    setLoading(true);
    requestApi(
      '/v1/get-user-by-branch   ',
      {
        method: 'get',
      },
      navigate
    )
      .then((data) => {
        console.log(data);
        if (data.statusCode === 200) {
          setLoading(false);
          // successToast('Successfully created branch');
          setFetchPartner(data.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        // errorToast(error.message);
        console.log(error);
      });
  };
  const validateBanner = async () => {
    const validate = formValidation();
    setFormErrors(validate);
    if (Object.keys(validate).length === 0) {
      createTransaction();
    }
  };
  const handleis50Change = (e) => {
    console.log(e.target.checked);
    setIs50(e.target.checked);
    if (e.target.checked) setIsCust(false);
  };
  const handleIsCust = (e) => {
    console.log(e.target.checked);
    setIsCust(e.target.checked);
    if (e.target.checked) setIs50(false);
  };
  const calculateTransaction = (e) => {
    setLoading(true);
    const gross = e.target.value;
    const gst = parseFloat((gross * 0.18).toFixed(2));
    const net = parseFloat((gross - gst).toFixed(2));
    setGross(gross);
    setGst(gst);
    setNet(net);
    setLoading(false);
  };
  const handleDateChange = (date) => {
    console.log(new Date(date));
    setSelectedDate(new Date(date));
  };
  useEffect(() => {
    setLoading(true);
    getCustomer();
    getPayment();
    getEmployee();
    setLoading(false);
  }, []);

  useEffect(() => {
    getTransactions();
  }, []);
  const getTransactions = async () => {
    setLoading(true);
    requestApi(
      `/v1/get-edit-transaction/${id}`,
      {
        method: 'get',
      },
      navigate
    )
      .then((data) => {
        console.log(data);
        return data.data;
      })
      .then((data) => {
        setSelectedDate(data.date);
        setEmailId(data.email);
        setCustomer(data.custName);
        setContactPerson(data.contact_person);
        setAddress(data.address);
        setLoading(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        errorToast(error.message);
        setLoading(false);
      });
  };

  return (
    <main onClick={() => setShowCalender(!showCalender)}>
      <section className="lyt-section"></section>
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : null}

      <section className="lyt-section p-0">
        <Container fluid className="typ-container">
          <div className="bs-card">
            <Row>
              <div className="bs-tabs">
                <Nav tabs>
                  <NavItem>
                    <TabLink
                      className={classnames({
                        active: currentActiveTab === '1',
                      })}
                      onClick={() => {
                        toggle('1');
                      }}
                    >
                      Add Transaction
                    </TabLink>
                  </NavItem>
                  <div>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                      <div className="calender-area">
                        <DatePicker
                          selected={selectedDate}
                          onChange={handleDateChange}
                          dateFormat="dd/MM/yyyy" // Specify the date format
                          placeholderText="Select Payment Date" // Placeholder text for the input
                          className="typ-date"
                        />
                      </div>
                    </Col>
                    {/* <Col xs={2} sm={2} md={5} lg={5} xl={5}>
                      <DatePicker
                        className="typ-select cstm-input"
                        selected={selectedDate}
                        onChange={handleDateChange}
                        dateFormat="dd/MM/yyyy" // Specify the date format
                        placeholderText="Select Payment Date" // Placeholder text for the input
                      />
                    </Col> */}
                  </div>
                </Nav>
              </div>

              <TabContent activeTab={currentActiveTab} className="px-0">
                <TabPane tabId="1">
                  <Container fluid>
                    <FormGroup className="bs-form">
                      <Row>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            Customer <span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            className="typ-select mob-margin cstm-input"
                            type="select"
                            name="name"
                            id="exampleSelect"
                            defaultValue={customer}
                            value={customer}
                            readOnly={true}
                            onChange={(e) => {
                              setCustomer(e.target.value);
                              console.log(e.target.value);
                            }}
                          >
                            <option key={0} value={''}>
                              Select Customer
                            </option>
                            {fetchCustomer.map((comp) => {
                              return (
                                <option key={comp.id} value={comp.id}>
                                  {comp.name}
                                </option>
                              );
                            })}
                          </Input>
                          <p className="error-message">{formErrors.customer}</p>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label className="mr-sm-2 cstm-label">
                            Transaction Id
                            <span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Enter Transaction Id"
                            onChange={(e) => setTransactionId(e.target.value)}
                          />
                          <p className="error-message">
                            {formErrors.transactionId}
                          </p>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            Payment <span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            className="typ-select mob-margin cstm-input"
                            type="select"
                            name="name"
                            id="exampleSelect"
                            defaultValue={paymentType}
                            value={paymentType}
                            onChange={(e) => {
                              setPaymentType(e.target.value);
                              const pay = e.target.value;
                              const payment = fetchPayment.filter(
                                (p) => p.id === parseInt(pay)
                              );
                              console.log(pay, payment, fetchPayment);
                              setUpi(payment[0]?.upi ? payment[0]?.upi : '');
                            }}
                          >
                            <option key={0} value={''}>
                              Select Payment
                            </option>
                            {fetchPayment.map((comp) => {
                              return (
                                <option key={comp.id} value={comp.id}>
                                  {comp.name}
                                </option>
                              );
                            })}
                          </Input>
                          <p className="error-message">{formErrors.customer}</p>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label className="mr-sm-2 cstm-label">
                            UPI<span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="UPI"
                            readOnly={true}
                            value={upi}
                          />
                        </Col>

                        <Col xs={4} sm={4} md={1.5} lg={1.5} xl={1.5}>
                          <Label className="mr-sm-2 cstm-label">
                            Gross
                            <span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            pattern="[0-9]*"
                            inputMode="numeric"
                            className="cstm-input"
                            placeholder="Enter Gross Amount"
                            value={gross}
                            onChange={calculateTransaction}
                            onKeyPress={(e) => {
                              const charCode = e.which ? e.which : e.keyCode;
                              if (
                                charCode > 31 &&
                                (charCode < 48 || charCode > 57)
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                          <p className="error-message">{formErrors.gross}</p>
                        </Col>
                        <Col xs={4} sm={4} md={2} lg={2} xl={2}>
                          <Label className="mr-sm-2 cstm-label">
                            GST(18%)
                            <span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="GST"
                            value={gst}
                            readOnly={true}
                            // onChange={(e) => setGst(e.target.value)}
                          />
                        </Col>
                        <Col xs={4} sm={4} md={1.5} lg={1.5} xl={1.5}>
                          <Label className="mr-sm-2 cstm-label">
                            Net
                            <span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            value={net}
                            readOnly={true}
                          />
                        </Col>

                        <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                          <Label className="mr-sm-2 cstm-label">
                            Type
                            <span style={{ color: 'red' }}>*</span>
                          </Label>

                          <div>
                            <label>
                              <input
                                type="checkbox"
                                name="is50"
                                checked={is50}
                                onChange={handleis50Change}
                              />
                              Is50?
                            </label>

                            <label style={{ paddingLeft: '10px' }}>
                              <input
                                type="checkbox"
                                name="isCust"
                                checked={isCust}
                                onChange={handleIsCust}
                              />
                              IsAccountHandling?
                            </label>
                          </div>

                          <div></div>

                          {is50 || isCust ? (
                            <div>
                              <Label
                                for="examplePassword"
                                className="mr-sm-2 cstm-label"
                              >
                                Partner name{' '}
                                <span style={{ color: 'red' }}>*</span>
                              </Label>
                              <Input
                                className="typ-select mob-margin cstm-input"
                                type="select"
                                name="name"
                                id="exampleSelect"
                                value={partner}
                                readOnly={true}
                                onChange={(e) => {
                                  setPartner(e.target.value);
                                }}
                              >
                                <option key={0} value={''}>
                                  Select Partner
                                </option>
                                {fetchPartner.map((comp) => {
                                  return (
                                    <option key={comp.id} value={comp.id}>
                                      {comp.name}
                                    </option>
                                  );
                                })}
                              </Input>
                            </div>
                          ) : (
                            ''
                          )}

                          <p className="error-message">{formErrors.partner}</p>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <button
                            className="bs-btn "
                            type="button"
                            onClick={(e) => {
                              validateBanner(e);
                            }}
                          >
                            SUBMIT
                          </button>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Container>
                </TabPane>
              </TabContent>
            </Row>
          </div>
          <ToastContainer />
        </Container>
      </section>

      <Modal
        isOpen={modal}
        toggle={customModal}
        size={'lg'}
        centered={true}
        className="bs-modal"
      >
        <ModalHeader toggle={customModal}></ModalHeader>
        <ModalBody toggle={customModal}>
          <p className="title">
            A report with the same ID is already in the system – Report ID
            HDFC_worli_12234. Do you wish to replace the Report?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={validateBanner}
            className="bs-btn typ-replace min-width margin-right"
          >
            Replace
          </Button>{' '}
          <Button
            onClick={customModal}
            className="bs-btn typ-outline min-width"
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </main>
  );
}

export default EditTransaction;
