import React, { useEffect, useState } from 'react';
import {
  Button,
  FormGroup,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  Row,
  Col,
  Container,
  Input,
  Label,
  NavLink as TabLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { requestApi } from '../../../request-api';
import { useNavigate, useParams } from 'react-router-dom';
import { directUrl } from '../../../helpers/Constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function EditMPS() {
  let navigate = useNavigate();
  let { id } = useParams();
  let [currentActiveTab, setCurrentActiveTab] = useState('1');
  let [calendarValue, setCalenderValue] = useState(new Date());
  let [showCalender, setShowCalender] = useState(false);
  let [selectedDate, setSelectedDate] = useState(null);
  let [transactionId, setTransactionId] = useState('');
  let [customer, setCustomer] = useState('');
  let [fetchGross, setFetchGross] = useState(0);
  let [expenses, setExpenses] = useState(0);
  let [sebi, setSebi] = useState(0);
  let [salary, setSalary] = useState(0);
  let [inHand, setInHand] = useState(0);
  let [fetchPayment, setFetchPayment] = useState([]);
  let [paymentType, setPaymentType] = useState('');
  let [fetchPartner, setFetchPartner] = useState([]);
  let [userRole, setUserRole] = useState('');
  let [emailId, setEmailId] = useState('');
  let [address, setAddress] = useState('');
  let [contactPerson, setContactPerson] = useState('');
  let [expen, setExpen] = useState(0);
  let [gross, setGross] = useState(0);
  let [gst, setGst] = useState(0);
  let [net, setNet] = useState(0);
  let [formErrors, setFormErrors] = useState({});
  let [loading, setLoading] = useState(false);
  let [modal, setModal] = useState(false);
  let [is50, setIs50] = useState(false);
  let [isCust, setIsCust] = useState(false);
  let [junaid, setJunaid] = useState({ name: 'Junaid', percent: 0, amount: 0 });
  let [extra, setExtra] = useState({ name: 'Extra', percent: 0, amount: 0 });
  let [mehdi, setMehdi] = useState({ name: 'Mehdi', percent: 0, amount: 0 });
  let [hashim, sethHashim] = useState({
    name: 'Hashim',
    percent: 0,
    amount: 0,
  });
  let [remainPercent, setRemainPercent] = useState(100);

  let [arbaz, setArbaz] = useState({ name: 'Arbaz', percent: 0, amount: 0 });
  let [upi, setUpi] = useState();

  const successToast = (message) =>
    toast.success(message, {
      position: 'bottom-right',
    });

  const errorToast = (error) =>
    toast.error(error, {
      position: 'bottom-right',
    });

  const toggle = (tab) => {
    if (currentActiveTab !== tab) {
      setCurrentActiveTab(tab);
    }
  };

  const customModal = () => {
    setModal(!modal);
  };

  const formValidation = () => {
    setLoading(true);
    const errors = {};
    const mobRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const sum =
      junaid.percent +
      extra.percent +
      mehdi.percent +
      hashim.percent +
      arbaz.percent;

    if (sum > 100) {
      errorToast(
        'Sum of Partner percent must be less than 100 but it is ' + sum
      );
      errors.error = 'Sum of Partner percent must be less than 100';
    }
    const gross = parseFloat(fetchGross);
    if (gross < expen) {
      errorToast('Expenses is more than Gross');
      errors.error = 'Expenses is more than Gross';
    }
    setLoading(false);
    return errors;
  };

  const createMPS = async () => {
    setLoading(true);
    requestApi(
      `/v1/mps/${id}`,
      {
        method: 'put',
        body: JSON.stringify({
          gross_amt: parseFloat(fetchGross)?.toFixed(2),
          gst_amt: parseFloat(gst)?.toFixed(2),
          net_amt: parseFloat(inHand)?.toFixed(2),
          expenses: parseFloat(expenses)?.toFixed(2),
          sebi: parseFloat(sebi)?.toFixed(2),
          salary: parseFloat(salary)?.toFixed(2),
          inhand: parseFloat(inHand)?.toFixed(2),
          partners: JSON.stringify([junaid, extra, mehdi, hashim, arbaz]),
          date: selectedDate,
        }),
      },
      navigate
    )
      .then((data) => {
        console.log(data);
        if (data.statusCode === 201) {
          setLoading(false);
          successToast('Successfully created MPS');
          navigate(`${directUrl}admin/mps`);
        }
      })
      .catch((error) => {
        setLoading(false);
        errorToast(error.message);
        console.log(error);
      });
  };

  const getGross = async () => {
    setLoading(true);
    requestApi(
      `/v1/mps/${id}`,
      {
        method: 'get',
      },
      navigate
    )
      .then((data) => {
        console.log(data);
        if (data.statusCode === 200) {
          setLoading(false);
          // successToast('Successfully created branch');
          console.log('------------', data.data[0]);
          const result = data.data[0];
          setSelectedDate(result.date);
          setFetchGross(parseFloat(result.gross_amt)?.toFixed(2));
          setInHand(parseFloat(result.inhand)?.toFixed(2));
          setSebi(parseFloat(result.sebi)?.toFixed(2));
          setExpenses(parseFloat(result.expenses)?.toFixed(2));
          setSalary(parseFloat(result.salary)?.toFixed(2));
          setGst(parseFloat(result.gst_amt)?.toFixed(2));
          const partner = JSON.parse(result.partners);
          setJunaid(partner[0]);
          setExtra(partner[1]);
          setMehdi(partner[2]);
          sethHashim(partner[3]);
          setArbaz(partner[4]);
        }
      })
      .catch((error) => {
        setLoading(false);
        errorToast(error.message);
        console.log(error);
      });
  };

  const validateBanner = async () => {
    const validate = formValidation();
    setFormErrors(validate);
    if (Object.keys(validate).length === 0) {
      createMPS();
    }
  };

  const handleDateChange = (date) => {
    console.log(new Date(date));
    setSelectedDate(new Date(date));
  };

  useEffect(() => {
    const gross = parseFloat(fetchGross);
    const exp =
      parseFloat(gst) +
      parseFloat(expenses) +
      parseFloat(sebi) +
      parseFloat(salary);
    console.log(gst, expenses, sebi, salary);

    setExpen(exp);
    const inhandCal = gross - exp;
    setInHand(inhandCal);
    const inHand = inhandCal;
    let per, amt;
    per = parseInt(junaid.percent);
    amt = inHand * (per / 100);
    setJunaid({ ...junaid, amount: parseFloat(amt)?.toFixed(2) });
    per = parseInt(extra.percent);
    amt = inHand * (per / 100);
    setExtra({ ...extra, amount: parseFloat(amt)?.toFixed(2) });
    per = parseInt(mehdi.percent);
    amt = inHand * (per / 100);
    setMehdi({ ...mehdi, amount: parseFloat(amt)?.toFixed(2) });
    per = parseInt(hashim.percent);
    amt = inHand * (per / 100);
    sethHashim({ ...hashim, amount: parseFloat(amt)?.toFixed(2) });
    per = parseInt(arbaz.percent);
    amt = inHand * (per / 100);
    setArbaz({ ...arbaz, amount: parseFloat(amt)?.toFixed(2) });
  }, [
    junaid.percent,
    extra.percent,
    mehdi.percent,
    hashim.percent,
    arbaz.percent,
    gst,
    expenses,
    sebi,
    salary,
  ]);
  // useEffect(() => {
  //   const gross = parseFloat(fetchGross);
  //   const exp =
  //     parseFloat(gst) +
  //     parseFloat(expenses) +
  //     parseFloat(sebi) +
  //     parseFloat(salary);
  //   console.log(gst, expenses, sebi, salary);
  //   if (gross < exp) {
  //     errorToast('Expenses is more than Gross');
  //     return;
  //   }
  //   const inhandCal = gross - exp;
  //   setInHand(inhandCal);
  // }, [gst, expenses, sebi, salary]);

  useEffect(() => {
    getGross();
  }, []);

  return (
    <main onClick={() => setShowCalender(!showCalender)}>
      <section className="lyt-section"></section>
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : null}

      <section className="lyt-section p-0">
        <Container fluid className="typ-container">
          <div className="bs-card">
            <Row>
              <div className="bs-tabs">
                <Nav tabs>
                  <NavItem>
                    <TabLink
                      className={classnames({
                        active: currentActiveTab === '1',
                      })}
                      onClick={() => {
                        toggle('1');
                      }}
                    >
                      Add MPS
                    </TabLink>
                  </NavItem>
                  <div></div>
                </Nav>

                <div className="main-header">
                  <form className="bs-form">
                    <div className="dropdown-bx report-date">
                      <DatePicker
                        readOnly={true}
                        selected={selectedDate}
                        onChange={handleDateChange}
                        dateFormat="MM/yyyy" // Format to display only month and year
                        showMonthYearPicker // Show only month and year picker
                        placeholderText="Month"
                        className="typ-date"
                        style={{
                          width: '100%', // Make the date picker input full-width
                          padding: '10px', // Add padding for better spacing
                          border: '1px solid #ccc', // Add a border
                          borderRadius: '5px', // Add border radius for rounded corners
                          fontSize: '16px', // Increase font size for better readability
                        }}
                      />
                    </div>
                  </form>
                </div>

                {/* <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                  <div
                    className="dropdown-bx report-date"
                    // className="calender-area"
                  >
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat="dd/MM/yyyy" // Specify the date format
                      placeholderText="Select Payment Date" // Placeholder text for the input
                      className="typ-date"
                    />
                  </div>
                </Col> */}
              </div>

              <TabContent activeTab={currentActiveTab} className="px-0">
                <TabPane tabId="1">
                  <Container fluid>
                    <FormGroup className="bs-form">
                      <Row>
                        <Col xs={4} sm={4} md={1.5} lg={1.5} xl={1.5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            Total Gross
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Gross"
                            value={fetchGross}
                            readOnly={true}
                            pattern="[0-9]*" // Only allow numeric characters
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value) || 0
                              )
                                .toString()
                                .slice(0, 20); // Limit the input to 10 characters
                            }}
                          />
                        </Col>
                        <Col xs={4} sm={4} md={1.5} lg={1.5} xl={1.5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            18%GST
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="18%GST"
                            value={gst}
                            readOnly={selectedDate ? false : true}
                            pattern="[0-9]*" // Only allow numeric characters
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value) || 0
                              )
                                .toString()
                                .slice(0, 20); // Limit the input to 10 characters
                            }}
                            onChange={(e) => setGst(e.target.value)}
                          />
                        </Col>
                        <Col xs={4} sm={4} md={1.5} lg={1.5} xl={1.5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            Expenses
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Gross"
                            value={expenses}
                            readOnly={selectedDate ? false : true}
                            pattern="[0-9]*" // Only allow numeric characters
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value) || 0
                              )
                                .toString()
                                .slice(0, 20); // Limit the input to 10 characters
                            }}
                            onChange={(e) => setExpenses(e.target.value)}
                          />
                        </Col>
                        <Col xs={4} sm={4} md={1.5} lg={1.5} xl={1.5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            SEBI
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Gross"
                            value={sebi}
                            readOnly={selectedDate ? false : true}
                            pattern="[0-9]*" // Only allow numeric characters
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value) || 0
                              )
                                .toString()
                                .slice(0, 20); // Limit the input to 10 characters
                            }}
                            onChange={(e) => setSebi(e.target.value)}
                          />
                        </Col>
                        <Col xs={4} sm={4} md={1.5} lg={1.5} xl={1.5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            SalaryInc
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Gross"
                            value={salary}
                            readOnly={selectedDate ? false : true}
                            pattern="[0-9]*" // Only allow numeric characters
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value) || 0
                              )
                                .toString()
                                .slice(0, 20); // Limit the input to 10 characters
                            }}
                            onChange={(e) => setSalary(e.target.value)}
                          />
                        </Col>
                        <Col xs={4} sm={4} md={1.5} lg={1.5} xl={1.5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            Inhand Amt
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Gross"
                            value={inHand}
                            pattern="[0-9]*" // Only allow numeric characters
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value) || 0
                              )
                                .toString()
                                .slice(0, 20); // Limit the input to 10 characters
                            }}
                            readOnly={true}
                          />
                        </Col>
                        <Label
                          style={{ paddingTop: '10px' }}
                          for="examplePassword"
                          className="mr-sm-2 cstm-label"
                        >
                          ----- Partners ----
                        </Label>
                        <Col
                          xs={4}
                          sm={4}
                          md={1.5}
                          lg={1.5}
                          xl={1.5}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          ></Label>
                          <div
                            className="cstm-input"
                            style={{
                              justifyContent: 'center',
                              textAlign: 'center',
                            }}
                          >
                            <span style={{ textAlign: 'center' }}>
                              {junaid.name}
                            </span>
                          </div>
                        </Col>
                        <Col xs={4} sm={4} md={1.5} lg={1.5} xl={1.5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            %
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Gross"
                            value={junaid.percent}
                            readOnly={selectedDate ? false : true}
                            pattern="[0-9]*" // Only allow numeric characters
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value) || 0
                              )
                                .toString()
                                .slice(0, 3); // Limit the input to 10 characters
                            }}
                            onChange={(e) =>
                              setJunaid({
                                ...junaid,
                                percent: parseInt(e.target.value),
                              })
                            }
                          />
                        </Col>
                        <Col xs={4} sm={4} md={1.5} lg={1.5} xl={1.5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            Amount
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Gross"
                            value={junaid.amount}
                            pattern="[0-9]*" // Only allow numeric characters
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value) || 0
                              )
                                .toString()
                                .slice(0, 20); // Limit the input to 10 characters
                            }}
                            readOnly={true}
                          />
                        </Col>

                        <Col
                          xs={4}
                          sm={4}
                          md={1.5}
                          lg={1.5}
                          xl={1.5}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          ></Label>
                          <div
                            className="cstm-input"
                            style={{
                              justifyContent: 'center',
                              textAlign: 'center',
                            }}
                          >
                            <span style={{ textAlign: 'center' }}>
                              {extra.name}
                            </span>
                          </div>
                        </Col>
                        <Col xs={4} sm={4} md={1.5} lg={1.5} xl={1.5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          ></Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Gross"
                            value={extra.percent}
                            readOnly={selectedDate ? false : true}
                            pattern="[0-9]*" // Only allow numeric characters
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value) || 0
                              )
                                .toString()
                                .slice(0, 3); // Limit the input to 10 characters
                            }}
                            onChange={(e) =>
                              setExtra({
                                ...extra,
                                percent: parseInt(e.target.value),
                              })
                            }
                          />
                        </Col>
                        <Col xs={4} sm={4} md={1.5} lg={1.5} xl={1.5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          ></Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Gross"
                            value={extra.amount}
                            pattern="[0-9]*" // Only allow numeric characters
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value) || 0
                              )
                                .toString()
                                .slice(0, 20); // Limit the input to 10 characters
                            }}
                            readOnly={true}
                          />
                        </Col>

                        <Col
                          xs={4}
                          sm={4}
                          md={1.5}
                          lg={1.5}
                          xl={1.5}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          ></Label>
                          <div
                            className="cstm-input"
                            style={{
                              justifyContent: 'center',
                              textAlign: 'center',
                            }}
                          >
                            <span style={{ textAlign: 'center' }}>
                              {mehdi.name}
                            </span>
                          </div>
                        </Col>
                        <Col xs={4} sm={4} md={1.5} lg={1.5} xl={1.5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          ></Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Gross"
                            value={mehdi.percent}
                            readOnly={selectedDate ? false : true}
                            pattern="[0-9]*" // Only allow numeric characters
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value) || 0
                              )
                                .toString()
                                .slice(0, 3); // Limit the input to 10 characters
                            }}
                            onChange={(e) =>
                              setMehdi({
                                ...mehdi,
                                percent: parseInt(e.target.value),
                              })
                            }
                          />
                        </Col>
                        <Col xs={4} sm={4} md={1.5} lg={1.5} xl={1.5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          ></Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Gross"
                            value={mehdi.amount}
                            pattern="[0-9]*" // Only allow numeric characters
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value) || 0
                              )
                                .toString()
                                .slice(0, 20); // Limit the input to 10 characters
                            }}
                            readOnly={true}
                          />
                        </Col>

                        <Col
                          xs={4}
                          sm={4}
                          md={1.5}
                          lg={1.5}
                          xl={1.5}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          ></Label>
                          <div
                            className="cstm-input"
                            style={{
                              justifyContent: 'center',
                              textAlign: 'center',
                            }}
                          >
                            <span style={{ textAlign: 'center' }}>
                              {hashim.name}
                            </span>
                          </div>
                        </Col>
                        <Col xs={4} sm={4} md={1.5} lg={1.5} xl={1.5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          ></Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Gross"
                            value={hashim.percent}
                            readOnly={selectedDate ? false : true}
                            pattern="[0-9]*" // Only allow numeric characters
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value) || 0
                              )
                                .toString()
                                .slice(0, 3); // Limit the input to 10 characters
                            }}
                            onChange={(e) =>
                              sethHashim({
                                ...hashim,
                                percent: parseInt(e.target.value),
                              })
                            }
                          />
                        </Col>
                        <Col xs={4} sm={4} md={1.5} lg={1.5} xl={1.5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          ></Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Gross"
                            value={hashim.amount}
                            pattern="[0-9]*" // Only allow numeric characters
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value) || 0
                              )
                                .toString()
                                .slice(0, 20); // Limit the input to 10 characters
                            }}
                            readOnly={true}
                          />
                        </Col>
                        <Col
                          xs={4}
                          sm={4}
                          md={1.5}
                          lg={1.5}
                          xl={1.5}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          ></Label>
                          <div
                            className="cstm-input"
                            style={{
                              justifyContent: 'center',
                              textAlign: 'center',
                            }}
                          >
                            <span style={{ textAlign: 'center' }}>
                              {arbaz.name}
                            </span>
                          </div>
                        </Col>
                        <Col xs={4} sm={4} md={1.5} lg={1.5} xl={1.5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          ></Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Gross"
                            value={arbaz.percent}
                            readOnly={selectedDate ? false : true}
                            pattern="[0-9]*" // Only allow numeric characters
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value) || 0
                              )
                                .toString()
                                .slice(0, 3); // Limit the input to 10 characters
                            }}
                            onChange={(e) =>
                              setArbaz({
                                ...arbaz,
                                percent: parseInt(e.target.value),
                              })
                            }
                          />
                        </Col>
                        <Col xs={4} sm={4} md={1.5} lg={1.5} xl={1.5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          ></Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Gross"
                            value={arbaz.amount}
                            pattern="[0-9]*" // Only allow numeric characters
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value) || 0
                              )
                                .toString()
                                .slice(0, 20); // Limit the input to 10 characters
                            }}
                            readOnly={true}
                          />
                        </Col>

                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <p className="error-message">{formErrors.error}</p>
                          <button
                            style={{ marginTop: '10px' }}
                            className="bs-btn "
                            type="button"
                            onClick={(e) => {
                              validateBanner(e);
                            }}
                          >
                            SUBMIT
                          </button>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Container>
                </TabPane>
              </TabContent>
            </Row>
          </div>
          <ToastContainer />
        </Container>
      </section>

      <Modal
        isOpen={modal}
        toggle={customModal}
        size={'lg'}
        centered={true}
        className="bs-modal"
      >
        <ModalHeader toggle={customModal}></ModalHeader>
        <ModalBody toggle={customModal}>
          <p className="title">
            A report with the same ID is already in the system – Report ID
            HDFC_worli_12234. Do you wish to replace the Report?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={validateBanner}
            className="bs-btn typ-replace min-width margin-right"
          >
            Replace
          </Button>{' '}
          <Button
            onClick={customModal}
            className="bs-btn typ-outline min-width"
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </main>
  );
}

export default EditMPS;
