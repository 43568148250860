import React, { useEffect, useState } from 'react';
import {
  Button,
  FormGroup,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  Row,
  Col,
  Container,
  Input,
  Label,
  NavLink as TabLink,
} from 'reactstrap';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { requestApi } from '../../../request-api';
import { useNavigate, useParams } from 'react-router-dom';
import { directUrl } from '../../../helpers/Constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function EditFtCount() {
  const { id } = useParams();
  const navigate = useNavigate();

  let [showCalender, setShowCalender] = useState(false);
  let [selectedDate, setSelectedDate] = useState(null);
  let [fetchGross, setFetchGross] = useState(0);
  let [ftName, setftName] = useState('');
  const [currentActiveTab, setCurrentActiveTab] = useState('1');
  const [startDate, setStartDate] = useState(new Date());
  const [level, setLevel] = useState('');
  const [qty, setQty] = useState(0);
  const [lots, setLots] = useState(0);
  const [count, setCount] = useState(0);
  const [remark, setRemark] = useState('');
  const [remark2, setRemark2] = useState('');
  const [remark3, setRemark3] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch existing ft data
    const fetchftData = async () => {
      setLoading(true);
      try {
        const data = await requestApi(`/v1/ft-count/${id}`, {
          method: 'get',
        });
        if (data) {
          // setStartDate(new Date(data.date));
          console.log(data);

          setCount(data?.data.count);
          setRemark(data?.data.remark);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);

        errorToast('Failed to fetch ft count data');
      }
    };
    fetchftData();
  }, [id]);

  const successToast = (message) =>
    toast.success(message, {
      position: 'bottom-right',
    });

  const errorToast = (error) =>
    toast.error(error, {
      position: 'bottom-right',
    });

  const toggle = (tab) => {
    if (currentActiveTab !== tab) {
      setCurrentActiveTab(tab);
    }
  };

  const formValidation = () => {
    setLoading(true);
    const errors = {};
    if (!startDate) {
      errors.datepicker = 'Date is required';
    }

    if (!count) {
      errors.count = 'Count is required';
    }
    if (remark?.length > 250 || remark?.length <= 0) {
      errors.remark = 'Remark cannot exceed 250 characters or Empty';
    }
    if (remark2?.length > 250 ) {
      errors.remark2 = 'Remark2 cannot exceed 250 characters';
    }
    if (remark3?.length > 250 ) {
      errors.remark3 = 'Remark3 cannot exceed 250 characters';
    }
    setLoading(false);
    return errors;
  };

  const updateftData = async () => {
    setLoading(true);
    try {
      const data = await requestApi(
        `/v1/ft-count/${id}`,
        {
          method: 'put',
          body: JSON.stringify({
            ftcountId: id,

            count: count,
            remark: remark,
            remark2: remark2,
            remark3: remark3,
          }),
        },
        navigate
      );
      if (data.statusCode === 200) {
        setLoading(false);
        successToast('Successfully updated ft count');
        navigate(`${directUrl}employee/ft-counts`);
      }
    } catch (error) {
      setLoading(false);
      errorToast(error.message);
    }
  };
  const handleIncrement = () => {
    setCount((prevCount) => Math.min(prevCount + 1, 9999999999)); // Limit to 10 digits
  };

  const handleDecrement = () => {
    setCount((prevCount) => Math.max(prevCount - 1, 0)); // Ensure it doesn't go below 0
  };
  const validateBanner = () => {
    const validate = formValidation();
    setFormErrors(validate);
    if (Object.keys(validate).length === 0) {
      updateftData();
    }
  };
  return (
    <main onClick={() => setShowCalender(!showCalender)}>
      <section className="lyt-section"></section>
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : null}

      <section className="lyt-section p-0">
        <Container fluid className="typ-container">
          <div className="bs-card">
            <Row>
              <div className="bs-tabs">
                <Nav tabs>
                  <NavItem>
                    <TabLink
                      className={classnames({
                        active: currentActiveTab === '1',
                      })}
                      onClick={() => {
                        toggle('1');
                      }}
                    >
                      Edit ft count
                    </TabLink>
                  </NavItem>
                  <div></div>
                </Nav>

                {/* <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                  <div
                    className="dropdown-bx report-date"
                    // className="calender-area"
                  >
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat="dd/MM/yyyy" // Specify the date format
                      placeholderText="Select Payment Date" // Placeholder text for the input
                      className="typ-date"
                    />
                  </div>
                </Col> */}
              </div>

              <TabContent activeTab={currentActiveTab} className="px-0">
                <TabPane tabId="1">
                  <Container fluid>
                    <FormGroup className="bs-form">
                      <Row>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            Count<span style={{ color: 'red' }}>*</span>
                          </Label>
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Button
                              style={{
                                backgroundColor: '#6EE167',
                                color: 'white',
                                border: 'none',
                                padding: '0.5rem 1rem',
                                fontSize: '1.2rem',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                margin: '0 5px',
                                transition: 'background-color 0.3s',
                              }}
                              onMouseEnter={(e) =>
                                (e.target.style.backgroundColor = '#0056b3')
                              }
                              onMouseLeave={(e) =>
                                (e.target.style.backgroundColor = '#6EE167')
                              }
                              onClick={handleDecrement}
                            >
                              -
                            </Button>
                            <Input
                              type="text"
                              className="cstm-input"
                              placeholder="Payments"
                              value={count}
                              readOnly // Make the input read-only
                              style={{ textAlign: 'center', margin: '0 5px' }}
                            />
                            <Button
                              style={{
                                backgroundColor: '#6EE167',
                                color: 'white',
                                border: 'none',
                                padding: '0.5rem 1rem',
                                fontSize: '1.2rem',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                margin: '0 5px',
                                transition: 'background-color 0.3s',
                              }}
                              onMouseEnter={(e) =>
                                (e.target.style.backgroundColor = '#0056b3')
                              }
                              onMouseLeave={(e) =>
                                (e.target.style.backgroundColor = '#6EE167')
                              }
                              onClick={handleIncrement}
                            >
                              +
                            </Button>
                          </div>
                          <p className="error-message">{formErrors.count}</p>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            Remark <span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Remark"
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                          />
                          <p className="error-message">{formErrors.remark}</p>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            Remark2 
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Remark"
                            value={remark2}
                            onChange={(e) => setRemark2(e.target.value)}
                          />
                          <p className="error-message">{formErrors.remark2}</p>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            Remark3 
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Remark"
                            value={remark3}
                            onChange={(e) => setRemark3(e.target.value)}
                          />
                          <p className="error-message">{formErrors.remark3}</p>
                        </Col>

                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <button
                            style={{ marginTop: '10px' }}
                            className="bs-btn "
                            type="button"
                            onClick={(e) => {
                              validateBanner(e);
                            }}
                          >
                            SUBMIT
                          </button>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Container>
                </TabPane>
              </TabContent>
            </Row>
          </div>
          <ToastContainer />
        </Container>
      </section>
    </main>
  );
}

export default EditFtCount;
