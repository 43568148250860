import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  Container,
  Input,
  Table,
  FormGroup,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Modal,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

//assets
import { directUrl, homePageUrl } from '../../../helpers/Constants';
import { requestApi } from '../../../request-api';
import DataTable from 'react-data-table-component';
import { API_URL } from '../../../config';
import axios from 'axios';
import { userStore } from '../../../stores';
import { dataToXLS, dataToXLS2 } from '../../../helpers/dataToXLS';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function DispositionAdmin() {
  const navigate = useNavigate();
  let [type, setType] = useState('0');

  let [istype, setIstype] = useState(false);
  let [selectedStatus, setSelectedStatus] = useState('0');
  let [selectedPrice, setSelectedPrice] = useState(0);
  const [dispositionData, setDispositionData] = useState([]);
  const [search, setSearch] = useState('');
  let [modal, setModal] = useState(false);

  let [downloadData, setDownloadData] = useState([]);
  const [filteredSearch, setFilteredSearch] = useState([]);
  const [loading, setLoading] = useState(false);
  // Function to determine status color

  const successToast = (message) =>
    toast.success(message, {
      position: 'bottom-right',
    });
  const customModal = () => {
    console.log('method called', modal);
    setModal(!modal);
  };
  const errorToast = (error) =>
    toast.error(error, {
      position: 'bottom-right',
    });

  const downloadDispositionData = (dispositionDataId) => {
    dataToXLS2(downloadData, 'MPS_DATA_' + new Date());
  };

  const routeChangeToCreateBranch = () => {
    navigate(`${directUrl}admin/add-disposition`);
  };
  const [columns, setColumns] = useState([
    {
      name: 'Sr.No.',
      selector: (row, index) => <td>{index + 1}</td>,
      sortable: true,
    },
    {
      name: 'Disposition Type',
      selector: (row) => <td style={{ fontWeight: 'bold' }}>{row.name}</td>,
      sortable: true,
    },
    {
      name: 'Details',
      selector: (row) => row.remark,

      sortable: true,
    },
    {
      name: 'Count',
      selector: (row) => row.count,
      sortable: true,
    },

    {
      name: 'Action',
      grow: 2,
      selector: (row) => (
        <td>
          <NavLink
            className="text-decoration-none"
            to={`${directUrl}admin/edit-disposition/${row.id}`}
          >
            <i className="icon icon-edit-new"></i>
          </NavLink>
        </td>
      ),
    },
  ]);

  const getData = (type) => {
    setLoading(true);
    requestApi(
      '/v1/disposition',
      {
        method: 'get',
      },
      navigate
    )
      .then((data) => {
        console.log('AyanPoonawala', data);
        setDispositionData(data.data);
        setFilteredSearch(data.data);

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setDispositionData([]);
        setFilteredSearch([]);
        console.log(error);
      });
  };
  const changeStatus = async (id, typeStatus) => {
    setLoading(true);
    await requestApi(
      `/v1/status/${id}`,
      {
        method: 'post',
        body: JSON.stringify({
          type: typeStatus,
        }),
      },
      navigate
    )
      .then((data) => {
        console.log('AyanPoonawala', data);

        setLoading(false);
        getData();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        getData();
      });
  };
  const deleteReport = async () => {
    customModal();
    setLoading(true);
    console.log('downloadData', downloadData);
    for (let i = 0; i < downloadData.length; i++) {
      let transId = downloadData[i].id;
      console.log(transId);

      try {
        const response = await requestApi(
          `/v1/disposition/${transId}`,
          {
            method: 'delete',
          },
          navigate
        );
        if (response) {
          setLoading(false);
          successToast(response.message);
           navigate(`${directUrl}admin/disposition`);
          getData();
          console.log('---<', response);
        }
      } catch (error) {
        setLoading(false);
        errorToast(error.message);
        console.log(error);
        // getData();
      }
    }
  };

  const mulSubmitReport = async () => {
    setLoading(true);
    console.log('downloadData', downloadData);
    for (let i = 0; i < downloadData.length; i++) {
      let transId = downloadData[i].id;
      console.log(transId);

      try {
        const response = await requestApi(
          `/v1/status/${transId}`,
          {
            method: 'post',
            body: JSON.stringify({
              type: selectedStatus,
            }),
          },
          navigate
        );
        if (response) {
          console.log('---<', response);
        }
      } catch (error) {
        setLoading(false);
        errorToast(error.message);
        console.log(error);
        // getData();
      }
    }
    getData();
    setLoading(false);
    successToast('Successfully status changed.');
  };
  useEffect(() => {
    if (search === 'empty') {
      return setFilteredSearch(dispositionData);
    }
    const result = dispositionData.filter((c) => {
      return (
        c.amount?.toString()?.toLowerCase().match(search.toLowerCase()) ||
        c.remark?.toString()?.toLowerCase().match(search.toLowerCase())
      );
    });
    setFilteredSearch(result);
  }, [search]);

  const dummy = (e) => {
    console.log('-->', e);
    if (e.allSelected == true) {
      let rows = [];
      console.log('e.allSelected == true', e.selectedRows);
      let sum = 0;
      e.selectedRows.forEach((row) => {
        sum += row.gross_amt;
      });

      const data = {
        gross_amt: sum,
      };
      setDownloadData([...e.selectedRows]);
    } else if (e.allSelected == false && e.selectedCount == 0) {
      setDownloadData([]);
    } else if (e.selectedCount > 0) {
      let sum = 0;
      e.selectedRows.forEach((row) => {
        sum += row.gross_amt;
      });

      const data = {
        gross_amt: sum,
        gst_amt: sum * 0.18?.toFixed(2),
        net_amt: sum - sum * 0.18?.toFixed(2),
      };
      setDownloadData([...e.selectedRows]);
    } else if (e.selectedCount == 0) {
      setDownloadData([]);
    }

    if (e.selectedCount == 0) {
      setIstype(false);
    } else {
      setIstype(true);
    }
  };
  // useEffect(() => {
  //   let sum = 0;
  //   filteredSearch.forEach((row) => {
  //     sum += row.gross_amt;
  //   });
  //   setGross(sum);
  //   sum = sum - sum * 0.18;
  //   setNet(sum);
  // }, [filteredSearch]);

  useEffect(() => {
    getData(type);
  }, []);
  console.log(type);
  return (
    <section className="lyt-section p-0">
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : null}
      <Container fluid className="typ-container">
        <div className="bs-card">
          <Row>
            {istype ? (
              <div
                style={{
                  display: 'flex',
                  // justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div className="bs-action-btn">
                  <div className="button-group">
                    {/* Button 1 */}
                    <NavLink
                      className="action-btn"
                      onClick={(e) => {
                        customModal();
                      }}
                    >
                      <span class="icon icon-delete"></span>
                    </NavLink>
                  </div>
                </div>
              </div>
            ) : (
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="bs-table">
                  <div className="main-header">
                    <form className="bs-form">
                      <div className="search-btn">
                        <Input
                          type="text"
                          placeholder="Search..."
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <i className="icon icon-search"></i>
                      </div>
                    </form>

                    <div className="left-button">
                      <button
                        className="bs-btn btn-submit typ-create"
                        onClick={routeChangeToCreateBranch}
                      >
                        <span class="icon icon-plus"></span> Add Disposition
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            )}
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                className="bs-table typ-datable overflow-on"
                style={{
                  borderRadius: '10px',
                  border: '1px solid #ddd',
                  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                  overflow: 'hidden',
                  position: 'relative',
                }}
              >
                <DataTable
                  columns={columns}
                  data={filteredSearch}
                  pagination
                  fixedHeader
                  fixedHeaderScrollHeight="450px"
                  selectableRows
                  onSelectedRowsChange={dummy}
                  selectableRowsHighlight
                  highlightOnHover
                  style={{
                    backgroundColor: '#ffffff',
                    borderCollapse: 'collapse',
                    overflow: 'hidden', // Hide scrollbars inside the table
                    fontFamily: '"Open Sans", sans-serif',
                  }}
                  paginationPerPage={50}
                  paginationRowsPerPageOptions={[50, 60, 80, 100]}
                />
              </div>

              <style>
                {`
      /* Hide scrollbars */
      .bs-table::-webkit-scrollbar {
        display: none; /* Chrome, Safari, and Opera */
      }

      .bs-table {
        -ms-overflow-style: none; /* Internet Explorer and Edge */
        scrollbar-width: none; /* Firefox */
      }

      /* Additional styles for the DataTable */
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }

      .rdt_TableHead {
        background-color: #f5f6f8;
        font-weight: 600; /* Semibold header text */
        color: #1c1c1c; /* Darker text color for better readability */
      }

      .rdt_TableRow {
        transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transitions */
      }

      .rdt_TableRow:hover {
        background-color: #f9f9f9; /* Light hover background color */
        transform: scale(1.01); /* Slight zoom effect on hover */
      }

      .rdt_Pagination {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        gap: 5px; /* Space between pagination items */
      }

      .rdt_PaginationPrev,
      .rdt_PaginationNext {
        margin: 0;
        padding: 10px 15px; /* Increased padding for buttons */
        background-color: #007bff;
        color: #ffffff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for buttons */
        transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
      }

      .rdt_PaginationPrev:hover,
      .rdt_PaginationNext:hover {
        background-color: #0056b3; /* Darker blue on hover */
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
      }

      .rdt_PaginationPage {
        margin: 0;
        padding: 10px 15px; /* Increased padding for page numbers */
        font-weight: 500; /* Medium font weight */
        cursor: pointer;
        transition: color 0.3s ease; /* Smooth color transition */
      }

      .rdt_PaginationPage:hover {
        color: #0056b3; /* Change color on hover */
      }

      .rdt_PaginationPageActive {
        font-weight: 700; /* Bold for active page */
        color: #007bff; /* Active page color */
      }
    `}
              </style>
            </Col>
          </Row>
          <Modal
            isOpen={modal}
            toggle={customModal}
            size={'lg'}
            centered={true}
            className="bs-modal"
          >
            <ModalHeader toggle={customModal}></ModalHeader>
            <ModalBody toggle={customModal}>
              <p className="title">Do you really need to delete Disposition?</p>
            </ModalBody>
            <ModalFooter>
              <button
                onClick={() => {
                  deleteReport();
                }}
                color="danger"
                className="bs-btn typ-delete min-width margin-right"
              >
                Delete
              </button>{' '}
              <button
                onClick={customModal}
                className="bs-btn typ-outline min-width"
              >
                Cancel
              </button>
            </ModalFooter>
          </Modal>
        </div>
      </Container>
      <ToastContainer />
    </section>
  );
}

export default DispositionAdmin;
