import React, { useEffect, useRef, useState } from 'react';
import Avatar from './Avatar';
import uploadFile from '../helpers/uploadFile';
import Divider from './Divider';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { setUser } from '../../redux/userSlice';

const EditUserDetails = ({ onClose, user }) => {
  const [data, setData] = useState({
    name: user?.name || '',
    profile_pic: user?.profile_pic || '',
  });
  const uploadPhotoRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      ...user,
    }));
  }, [user]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleOpenUploadPhoto = (e) => {
    e.preventDefault();
    e.stopPropagation();

    uploadPhotoRef.current.click();
  };

  const handleUploadPhoto = async (e) => {
    const file = e.target.files[0];
    const uploadPhoto = await uploadFile(file);

    setData((prev) => ({
      ...prev,
      profile_pic: uploadPhoto?.url,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const URL = `${process.env.REACT_APP_BACKEND_URL}/api/update-user`;
      const response = await axios.post(URL, data, { withCredentials: true });

      toast.success(response?.data?.message);

      if (response.data.success) {
        dispatch(setUser(response.data.data));
        onClose();
      }
    } catch (error) {
      console.error(error);
      toast.error('Failed to update user details.');
    }
  };

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.4)', // Adjust opacity for the overlay
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10,
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          padding: '24px',
          borderRadius: '8px',
          width: '100%',
          maxWidth: '500px', // Increased max-width for better appearance on larger screens
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
        }}
      >
        <h2
          style={{
            fontWeight: '600',
            marginBottom: '8px',
          }}
        >
          Profile Details
        </h2>
        <p
          style={{
            fontSize: '14px',
            color: '#6b7280', // Gray text color
          }}
        >
          Edit user details
        </p>

        <form
          style={{
            display: 'grid',
            gap: '16px',
            marginTop: '16px',
          }}
          // onSubmit={handleSubmit}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              name="name"
              id="name"
              disabled
              value={data.name}
              onChange={handleOnChange}
              style={{
                width: '100%',
                padding: '8px',
                border: '1px solid #d1d5db', // Light border color
                borderRadius: '4px',
                boxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.1)', // Subtle inner shadow
              }}
            />
          </div>

          {/* <div>
            <div>Photo:</div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '16px',
                margin: '8px 0',
              }}
            >
              <Avatar
                width={60} // Slightly larger for better visibility
                height={60}
                imageUrl={data?.profile_pic}
                name={data?.name}
              />
              <label htmlFor="profile_pic">
                <button
                  type="button"
                  onClick={handleOpenUploadPhoto}
                  style={{
                    fontWeight: '600',
                    color: '#007bff', // Primary button color
                    backgroundColor: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                >
                  Change Photo
                </button>
                <input
                  type="file"
                  id="profile_pic"
                  className="hidden"
                  onChange={handleUploadPhoto}
                  ref={uploadPhotoRef}
                />
              </label>
            </div>
          </div> */}

          <Divider />

          <div
            style={{
              display: 'flex',
              gap: '12px',
              justifyContent: 'flex-end',
            }}
          >
            <button
              type="button"
              onClick={onClose}
              style={{
                border: '1px solid #007bff', // Primary border color
                color: '#007bff',
                padding: '8px 16px',
                borderRadius: '4px',
                backgroundColor: 'transparent',
                cursor: 'pointer',
                fontWeight: '600',
                transition: 'background-color 0.3s, color 0.3s',
              }}
            >
              Cancel
            </button>
            {/* <button
              type="submit"
              style={{
                border: '1px solid #007bff', // Primary border color
                backgroundColor: '#007bff',
                color: 'white',
                padding: '8px 16px',
                borderRadius: '4px',
                cursor: 'pointer',
                fontWeight: '600',
                transition: 'background-color 0.3s, color 0.3s',
                ':hover': {
                  backgroundColor: '#0056b3', // Darker shade for hover
                },
              }}
            >
              Save
            </button> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default React.memo(EditUserDetails);
