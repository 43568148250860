import React, { FC, useState, useEffect, useMemo } from 'react';
import { jwtDecode } from 'jwt-decode';

import { UserStore } from '../../../stores';
import { Login, Code } from './screens';
import { AuthStore } from './store';
import { useNavigate } from 'react-router-dom';

import { directUrl, homePageUrl } from '../../../helpers/Constants';
import { requestApi } from '../../../request-api';
// const coupleImg = require('../../assets/images/couple.png');

export const AuthScreen = ({ userStore }) => {
  const store = useMemo(() => new AuthStore(), []);

  const [screen, setScreen] = useState('email');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();
useEffect(() => {
  let token = localStorage.getItem('x-access-token');

  if (token) {
    let user = jwtDecode(token);
    // user = JSON.parse(user);
    console.log('user', user.role_id, user);
    if (user.role_id === 'admin') {
      navigate(`${directUrl}admin/Home`);
    }
    if (user.role_id === 'user') {
      navigate(`${directUrl}employee/home`);
    }
  } 
});
  useEffect(() => {
    const user = localStorage.getItem('user');
    setError('');
  }, [screen]);

  const onSubmitEmailAuth = (email, password, e) => {
    e.preventDefault();
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regular expression for email validation
    if (email.length === 0) {
      setError('Please enter an email address');
    } else if (!emailPattern.test(email)) {
      setError('Please enter a valid email address');
    } else if (password.length < 8 || password.length > 24) {
      setError('Password must be between 8 and 24 characters');
    } else {
      // Proceed with the submission if the email is valid
      setEmail(email);
      setPassword(password);
      onSubmitDetails(email, password);
    }
  };

  const onSubmitDetails = async (email, password) => {
    setLoading(true);
    return (
      store
        .confirmDetails(email, password)
        .then(({ token }) => {
          // if not admin, show forbidden error
          return { token };
        })
        .then(({ token }) => {
          //  alert('hi')
          userStore.setToken(token);
          const decodedToken = jwtDecode(token);
          console.log(decodedToken.role_id, typeof decodedToken.role_id);
          if (decodedToken.role_id === 'admin') {
            navigate(`${directUrl}admin/home`);
          }
          if (decodedToken.role_id === 'user') {
            navigate(`${directUrl}employee/home`);
          }
        })

        // parse error and show meaningful messagee
        .catch((error) => {
          console.log(error, 'Hi this is error');
          if (error.message.includes('"Invalid Credentials"')) {
            setError('Invalid code');
          } else if (error.message.includes('forbidden')) {
            setError('Access Forbidden');
          } else {
            // console.error('failed to confirm code', error)

            setError(error.message);
          }
        })
        .finally(() => setLoading(false))
    );
  };

  return (
    <main>
      <section className="login-bg">
        <Login onSubmit={onSubmitEmailAuth} loading={loading} error={error} />
      </section>
    </main>
  );
};
