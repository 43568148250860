import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  FormGroup,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  Row,
  Col,
  Container,
  Input,
  Label,
  NavLink as TabLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

//assets
import { directUrl, homePageUrl } from '../../../helpers/Constants';
import { requestApi } from '../../../request-api';
import DataTable from 'react-data-table-component';
import { API_URL } from '../../../config';
import axios from 'axios';
import { userStore } from '../../../stores';
import { dataToXLS, dataToXLS2 } from '../../../helpers/dataToXLS';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

const CustomDateColumn = ({ date }) => {
  // Format the date using moment.js or any other date formatting library
  const formattedDate = moment(date).format('MMM Do YYYY, h:mm:ss a');

  return (
    <td
      style={{
        textAlign: 'center',
        backgroundColor: '#cce5ff',
        borderRadius: '10px',
        padding: '5px',
      }}
    >
      {formattedDate}
    </td>
  );
};

function LeadDataDetailsAdmin() {
  const navigate = useNavigate();
  const { id } = useParams();

  let [istype, setIstype] = useState(false);
  let [selectedStatus, setSelectedStatus] = useState('0');
  let [selectedPrice, setSelectedPrice] = useState(0);
  const [fileData, setFileData] = useState([]);
  const [search, setSearch] = useState('');
  let [modal, setModal] = useState(false);
  let [selectData, setSelectedData] = useState('');
  let [assignUser, setAssignUser] = useState('');
  let [comment, setComment] = useState('');
  let [selectedDisposition, setSelectedDisposition] = useState('');
  const [dispType, setDispType] = useState('not processed');
  const [endDate, setEndDate] = useState(null);
  let [downloadData, setDownloadData] = useState([]);
  const [filteredSearch, setFilteredSearch] = useState([]);
  let [fetchPartner, setFetchPartner] = useState([]);
  let [fetchDisposition, setFetchDisposition] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  // Function to determine status color
  const pageSize = 100; // Number of items per page

  const successToast = (message) =>
    toast.success(message, {
      position: 'bottom-right',
    });
  const customModal = () => {
    console.log('method called', modal);
    setModal(!modal);
  };
  const errorToast = (error) =>
    toast.error(error, {
      position: 'bottom-right',
    });

  const downloadDispositionData = (dispositionDataId) => {
    dataToXLS(downloadData, 'Lead_overall_DATA_' + new Date());
  };

  const routeChangeToCreateBranch = () => {
    navigate(`${directUrl}admin/add-data`);
  };
  const [columns, setColumns] = useState([
    {
      name: 'Sr.No.',
      selector: (row, index) => (
        <td
        // style={{
        //   textAlign: 'center',
        //   backgroundColor: '#cce5ff',
        //   borderRadius: '10px',
        //   padding: '5px',
        // }}
        >
          {row.id}
        </td>
      ),
      sortable: true,
    },
    {
      name: 'Capital',
      selector: (row) => (
        <td
        // style={{
        //   fontWeight: 'bold',
        //   textAlign: 'center',
        //   backgroundColor: '#d4edda',
        //   borderRadius: '10px',
        //   padding: '5px',
        // }}
        >
          {row.capital}
        </td>
      ),
      sortable: true,
    },
    {
      name: 'Segment',
      selector: (row) => (
        <td
        // style={{
        //   textAlign: 'center',
        //   backgroundColor: '#fff3cd',
        //   borderRadius: '10px',
        //   padding: '5px',
        // }}
        >
          {row.segment}
        </td>
      ),
      sortable: true,
    },
    {
      name: 'Name',
      selector: (row) => (
        <td
          style={{
            fontWeight: 'bold',
            textAlign: 'center',
            backgroundColor: '#d4edda',
            borderRadius: '10px',
            padding: '5px',
          }}
        >
          {row.name}
        </td>
      ),
      sortable: true,
    },
    {
      name: 'Phone',
      selector: (row) => (
        <td
          style={{
            textAlign: 'center',
            backgroundColor: '#f8d7da',
            borderRadius: '10px',
            padding: '5px',
          }}
        >
          {row.phone}
        </td>
      ),
    },
    {
      name: 'Email',
      selector: (row) => (
        <td
          style={{
            textAlign: 'center',
            backgroundColor: '#cce5ff',
            borderRadius: '10px',
            padding: '5px',
          }}
        >
          {row.email}
        </td>
      ),
    },
    {
      name: 'Type',
      selector: (row) => (
        <td
        // style={{
        //   textAlign: 'center',
        //   backgroundColor: '#fff3cd',
        //   borderRadius: '10px',
        //   padding: '5px',
        // }}
        >
          {row.type}
        </td>
      ),
      sortable: true,
    },
    {
      name: 'Date',
      selector: (row) => <CustomDateColumn date={row.create} />, // Use the CustomDateColumn component here
      sortable: true,
    },
    {
      name: 'User Name',
      selector: (row) => (
        <td
          style={{
            textAlign: 'center',
            backgroundColor: '#d4edda',
            borderRadius: '10px',
            padding: '5px',
          }}
        >
          {row.username}
        </td>
      ),
      sortable: true,
    },
    {
      name: 'Disposition',
      selector: (row) => (
        <td
          style={{
            backgroundColor: row.disp_type ? '#E6E6FA' : 'inherit',
            textAlign: 'center',
            padding: '2px',
            borderRadius: '10px',
          }}
        >
          {row.disp_type}
        </td>
      ),
      sortable: true,
    },
    {
      name: 'Comments',
      selector: (row) => (
        <td
          style={{
            backgroundColor: row.remark ? '#ADD8E6' : 'inherit',
            textAlign: 'center',
            padding: '2px',
            borderRadius: '10px',
          }}
        >
          {row.remark}
        </td>
      ),
      sortable: true,
    },
    {
      name: 'Action',
      grow: 2,
      selector: (row) => (
        <td style={{ textAlign: 'center' }}>
          <NavLink
            className="text-decoration-none"
            onClick={(e) => {
              console.log(row);
              setSelectedData(row.id);
              setAssignUser(row?.user_id);
              setSelectedDisposition(row?.disp_type);
              setComment(row?.remark);
              customModal();
            }}
          >
            <i className="icon icon-edit-new"></i>
          </NavLink>
        </td>
      ),
    },
  ]);


  const getData = (dispType, offset) => {
    setLoading(true);
    requestApi(
      `/v1/leaddata/get-file-details-admin/${id}?page=${offset}&limit=${pageSize}`,
      {
        method: 'post',
        body: JSON.stringify({
          dispType,
        }),
      },
      navigate
    )
      .then((data) => {
        console.log('AyanPoonawala', data);
        setFileData(data.data);
        setFilteredSearch(data.data);
        setFilteredSearch(data.data);
        console.log(data.total,"zaaqzaq");
        setTotalPages(Math.ceil(data.total / pageSize));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setFileData([]);
        setFilteredSearch([]);
        console.log(error);
      });
  };
  const editNewLead = async () => {
    setLoading(true);
    requestApi(
      `/v1/leaddata/update-lead-details-admin/${selectData}`,
      {
        method: 'put',
        body: JSON.stringify({
          user_id: assignUser,
          disp_type: selectedDisposition,
          remark: comment,
        }),
      },
      navigate
    )
      .then((data) => {
        console.log(data);
        if (data.statusCode === 200) {
          setLoading(false);
          successToast('Successfully Update Lead details');

          getData(dispType, 0);
        }
      })
      .catch((error) => {
        setLoading(false);
        errorToast(error.message);
        console.log(error);
        getData(dispType, 0);
      })
      .finally(() => {
        customModal();
      });
  };

  const deleteReport = async () => {
    customModal();
    setLoading(true);
    console.log('downloadData', downloadData);
    for (let i = 0; i < downloadData.length; i++) {
      let transId = downloadData[i].id;
      console.log(transId);

      try {
        const response = await requestApi(
          `/v1/leadData/delete-file-details-admin/${transId}`,
          {
            method: 'delete',
          },
          navigate
        );
        if (response) {
          setLoading(false);
          successToast(response.message);
          navigate(`${directUrl}admin/lead-data-file`);
          getData();
          console.log('---<', response);
        }
      } catch (error) {
        setLoading(false);
        errorToast(error.message);
        console.log(error);
        // getData();
      }
    }
  };
  const getEmployee = async () => {
    setLoading(true);
    requestApi(
      '/v1/get-user-by-branch   ',
      {
        method: 'get',
      },
      navigate
    )
      .then((data) => {
        console.log(data);
        if (data.statusCode === 200) {
          
          // successToast('Successfully created branch');
          setFetchPartner(data.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        // errorToast(error.message);
        console.log(error);
      });
  };
  const getDisposition = async () => {
    setLoading(true);
    requestApi(
      '/v1/disposition/',
      {
        method: 'get',
      },
      navigate
    )
      .then((data) => {
        console.log(data);
        if (data.statusCode === 200) {
         
          console.log(data.data);
          // successToast('Successfully created branch');
          setFetchDisposition(data.data);
           setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        // errorToast(error.message);
        console.log(error);
      });
  };
  const getSelectedData = async (id) => {
    setLoading(true);
    requestApi(
      `/v1/leaddata/get-lead-details-admin/${id}`,
      {
        method: 'get',
      },
      navigate
    )
      .then((data) => {
        console.log(data);
        if (data.statusCode === 200) {
          setLoading(false);
          // successToast('Successfully created branch');
          setAssignUser(data.data?.name);
          setSelectedDisposition(data.data?.disp_type);
          setComment(data.data?.remark);

          customModal();
        }
      })
      .catch((error) => {
        setLoading(false);
        // errorToast(error.message);
        console.log(error);
      });
  };

  useEffect(() => {
    if (search === 'empty') {
      return setFilteredSearch(fileData);
    }
    const result = fileData.filter((c) => {
      return (
        c.phone?.toString()?.toLowerCase().match(search.toLowerCase()) ||
        c.name?.toString()?.toLowerCase().match(search.toLowerCase()) ||
        c.disp_type?.toString()?.toLowerCase().match(search.toLowerCase()) ||
        c.email?.toString()?.toLowerCase().match(search.toLowerCase())
      );
    });
    setFilteredSearch(result);
  }, [search]);

  const dummy = (e) => {
    console.log('-->', e);
    if (e.allSelected == true) {
      let rows = [];
      console.log('e.allSelected == true', e.selectedRows);
      let sum = 0;
      e.selectedRows.forEach((row) => {
        sum += row.gross_amt;
      });

      const data = {
        gross_amt: sum,
      };
      setDownloadData([...e.selectedRows]);
    } else if (e.allSelected == false && e.selectedCount == 0) {
      setDownloadData([]);
    } else if (e.selectedCount > 0) {
      let sum = 0;
      e.selectedRows.forEach((row) => {
        sum += row.gross_amt;
      });

      const data = {
        gross_amt: sum,
        gst_amt: sum * 0.18?.toFixed(2),
        net_amt: sum - sum * 0.18?.toFixed(2),
      };
      setDownloadData([...e.selectedRows]);
    } else if (e.selectedCount == 0) {
      setDownloadData([]);
    }

    if (e.selectedCount == 0) {
      setIstype(false);
    } else {
      setIstype(true);
    }
  };
  // useEffect(() => {
  //   let sum = 0;
  //   filteredSearch.forEach((row) => {
  //     sum += row.gross_amt;
  //   });
  //   setGross(sum);
  //   sum = sum - sum * 0.18;
  //   setNet(sum);
  // }, [filteredSearch]);
  console.log(assignUser, selectedDisposition);
  useEffect(() => {
    getData(dispType, 0);
    getEmployee();
    getDisposition();
  }, []);
  const handlePageChange = (page, offsets) => {
    console.log(offsets);
    const offset = (page - 1) * 100; // Calculate offset based on page number
    getData(dispType, offset);
  };
  return (
    <section className="lyt-section p-0">
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : null}
      <Container fluid className="typ-container">
        <div className="bs-card">
          <Row>
            {istype ? (
              <div
                style={{
                  display: 'flex',
                  // justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div className="bs-action-btn">
                  <div className="button-group">
                    {/* Button 1 */}
                    <NavLink
                      className="action-btn"
                      onClick={(e) => {
                        downloadDispositionData();
                      }}
                    >
                      <span class="icon icon-download"></span>
                    </NavLink>
                  </div>
                </div>
              </div>
            ) : (
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="bs-table">
                  <div className="main-header">
                    <form className="bs-form">
                      <div className="search-btn">
                        <Input
                          type="text"
                          placeholder="Search..."
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <i className="icon icon-search"></i>
                      </div>
                      <div className="dropdown-bx">
                        <label className="form-label">Status : </label>
                        {/* <Input type="text" name="text" id="exampleEmail" className='cstm-input' placeholder="Enter Branch ID" /> */}
                        <Input
                          type="select"
                          name="select"
                          id="exampleSelect"
                          value={dispType}
                          onChange={(e) => {
                            setDispType(e.target.value);
                            getData(e.target.value.toString(), 0);
                            console.log(e.target.value);
                          }}
                        >
                          <option key={0} value={''}>
                            Select All
                          </option>
                          {fetchDisposition.map((comp) => {
                            return (
                              <option key={comp.value} value={comp.value}>
                                {comp.name}
                              </option>
                            );
                          })}
                        </Input>
                      </div>
                    </form>

                    <div className="left-button">
                      <button
                        className="bs-btn btn-submit typ-create"
                        onClick={routeChangeToCreateBranch}
                      >
                        <span class="icon icon-plus"></span> Add Disposition
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            )}
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                className="bs-table typ-datable overflow-on"
                style={{
                  borderRadius: '10px',
                  border: '1px solid #ddd',
                  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                  overflow: 'hidden',
                  position: 'relative',
                }}
              >
                <DataTable
                  columns={columns}
                  data={filteredSearch}
                  pagination
                  fixedHeader
                  fixedHeaderScrollHeight="450px"
                  selectableRows
                  onSelectedRowsChange={dummy}
                  selectableRowsHighlight
                  highlightOnHover
                  style={{
                    backgroundColor: '#ffffff',
                    borderCollapse: 'collapse',
                    overflow: 'hidden',
                    fontFamily: '"Open Sans", sans-serif',
                  }}
                  paginationPerPage={50}
                  paginationRowsPerPageOptions={[50, 60, 80, 100]}
                />
              </div>

              <style>
                {`
      /* Hide scrollbars */
      .bs-table::-webkit-scrollbar {
        display: none; /* Chrome, Safari, and Opera */
      }

      .bs-table {
        -ms-overflow-style: none; /* Internet Explorer and Edge */
        scrollbar-width: none; /* Firefox */
      }
      
      /* Additional styles for the DataTable */
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }

      .rdt_TableHead {
        background-color: #f5f6f8;
        font-weight: 600; /* Semibold header text */
        color: #1c1c1c; /* Darker text color for better readability */
      }

      .rdt_TableRow {
        transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transitions */
      }

      .rdt_TableRow:hover {
        background-color: #f9f9f9; /* Light hover background color */
        transform: scale(1.01); /* Slight zoom effect on hover */
      }

      .rdt_Pagination {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        gap: 5px; /* Space between pagination items */
      }

      .rdt_PaginationPrev,
      .rdt_PaginationNext {
        margin: 0;
        padding: 10px 15px; /* Increased padding for buttons */
        background-color: #007bff;
        color: #ffffff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for buttons */
        transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
      }

      .rdt_PaginationPrev:hover,
      .rdt_PaginationNext:hover {
        background-color: #0056b3; /* Darker blue on hover */
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
      }

      .rdt_PaginationPage {
        margin: 0;
        padding: 10px 15px; /* Increased padding for page numbers */
        font-weight: 500; /* Medium font weight */
        cursor: pointer;
        transition: color 0.3s ease; /* Smooth color transition */
      }

      .rdt_PaginationPage:hover {
        color: #0056b3; /* Change color on hover */
      }

      .rdt_PaginationPageActive {
        font-weight: 700; /* Bold for active page */
        color: #007bff; /* Active page color */
      }
    `}
              </style>
            </Col>
          </Row>
          <Modal
            isOpen={modal}
            toggle={customModal}
            size={'lg'}
            centered={true}
            className="bs-modal"
          >
            <ModalHeader toggle={customModal}></ModalHeader>
            <ModalBody toggle={customModal}>
              <Container fluid className="typ-container">
                <div className="bs-card">
                  <Row>
                    <Container fluid>
                      <FormGroup className="bs-form">
                        <Row>
                          <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                            <Label
                              for="examplePassword"
                              className="mr-sm-2 cstm-label"
                            >
                              Assigned Employee
                              <span style={{ color: 'red' }}>*</span>
                            </Label>
                            <Input
                              className="typ-select mob-margin cstm-input"
                              type="select"
                              name="name"
                              id="exampleSelect"
                              value={assignUser}
                              de
                              onChange={(e) => {
                                setAssignUser(e.target.value);
                              }}
                            >
                              <option key={0} value={''}>
                                Select Employee
                              </option>
                              {fetchPartner.map((comp) => {
                                return (
                                  <option key={comp.id} value={comp.id}>
                                    {comp.name}
                                  </option>
                                );
                              })}
                            </Input>
                            <p className="error-message">
                              {/* {formErrors.branchName} */}
                            </p>
                          </Col>
                          <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                            <Label
                              for="examplePassword"
                              className="mr-sm-2 cstm-label"
                            >
                              Disposition
                              <span style={{ color: 'red' }}>*</span>
                            </Label>
                            <Input
                              className="typ-select mob-margin cstm-input"
                              type="select"
                              name="name"
                              id="exampleSelect"
                              value={selectedDisposition}
                              onChange={(e) => {
                                setSelectedDisposition(e.target.value);
                              }}
                            >
                              <option key={0} value={''}>
                                Select Disposition
                              </option>
                              {fetchDisposition.map((comp) => {
                                return (
                                  <option key={comp.id} value={comp.name}>
                                    {comp.name}
                                  </option>
                                );
                              })}
                            </Input>
                            <p className="error-message">
                              {/* {formErrors.branchName} */}
                            </p>
                          </Col>
                          <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                            <Label
                              for="examplePassword"
                              className="mr-sm-2 cstm-label"
                            >
                              Comment
                            </Label>
                            <Input
                              type="text"
                              className="cstm-input"
                              placeholder="remark"
                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                    </Container>
                  </Row>
                </div>
                <ToastContainer />
              </Container>
            </ModalBody>
            <ModalFooter>
              <button
                onClick={() => {
                  editNewLead();
                }}
                color="danger"
                className="bs-btn typ-delete min-width margin-right"
              >
                Edit
              </button>{' '}
              <button
                onClick={customModal}
                className="bs-btn typ-outline min-width"
              >
                Cancel
              </button>
            </ModalFooter>
          </Modal>
        </div>
      </Container>
      <ToastContainer />
    </section>
  );
}

export default LeadDataDetailsAdmin;
