// import { observable, action } from 'mobx'

import { PRODUCTION } from '../../../config';
import { requestApi } from '../../../request-api';

export class AuthStore {
  // firebase auth service
  auth;

  // last submited phone
  email;
  password;

  // save login request to be able to confirm it later using SMS code
  loginRequest;

  // initialize firebase app and create auth service
  constructor() {}

  /** Confirms if auth code from SMS message is valid. */
  confirmDetails = async (email, password) => {
    try {
      console.debug('confirming code');
      this.email = email;
      this.password = password;
      const userCredential = await requestApi('/v1/login', {
        method: 'post',
        body: JSON.stringify({
          email,
          password,
        }),
      });
      console.log('userCredential', userCredential);
      const { token } = userCredential;
      return { token };
    } catch (error) {
      throw error;
    }
  };
}
