import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Label, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
//assets

import logo from '../../../../assets/image/logo.png';
import { directUrl } from '../../../../helpers/Constants';
import { NavLink } from 'react-router-dom';

export const Login = ({ onSubmit, loading, error }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  return (
    <section className="lyt-section typ-login">
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col xs={12} sm={12} md={12} lg={6} xl={5}>
            <div className="bs-card typ-login">
              <div className="img-area">
                <picture>
                  <source media="(max-width:992px)" srcSet={logo} />
                  <img
                    src={logo}
                    style={{ maxWidth: '25%' }}
                    alt="JK Trading"
                  />
                </picture>
              </div>
              <form className="bs-form" onSubmit={(e) => onSubmit(email, e)}>
                <Label for="mail">Login To Continue</Label>
                <div className="input-area">
                  <Input
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    type="email"
                    id="mail"
                    placeholder="Enter Your Email Address"
                  />
                  <i className="icon icon-user-round"></i>
                </div>
                {/* <div className='mod-divider typ-or'></div> */}
                {/* {error && (
                  <p
                    style={{
                      position: 'absolute',
                      fontSize: '12px',
                      color: '#d73038',
                      margin: '0 0px 15px',
                      fontFamily: 'roboto',
                      fontWeight: 500,
                    }}
                  >
                    {error}
                  </p>
                )} */}
                <div className="input-area">
                  <Input
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    type="password"
                    id="password"
                    placeholder="Enter Your Password"
                  />
                  <FontAwesomeIcon className="icon " icon={faLock} />
                </div>
                {/* <div className='mod-divider typ-or'></div> */}
                {error && (
                  <p
                    style={{
                      position: 'absolute',
                      fontSize: '12px',
                      color: '#d73038',
                      margin: '0 0px 15px',
                      fontFamily: 'roboto',
                      fontWeight: 500,
                    }}
                  >
                    {error}
                  </p>
                )}

                <button
                  onClick={(e) => onSubmit(email, password, e)}
                  className="bs-btn typ-login"
                  style={{ marginTop: 15 }}
                  type="button"
                >
                  Sign In
                </button>
                <p
                  style={{
                    position: 'absolute',
                    fontSize: '12px',
                    color: 'rgb(74,161,255)',
                    margin: '0 0px 15px',
                    fontFamily: 'roboto',
                    fontWeight: 500,
                  }}
                >
                  <NavLink
                    className="nav-link first"
                    exact="true"
                    to={`/resetpassword`}
                  >
                    <i className="icon"></i>Admin forget password?
                  </NavLink>
                </p>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
