import { API_URL } from './config';
import { directUrl } from './helpers/Constants';
import { userStore } from './stores';
import { useNavigate } from 'react-router-dom';

export async function requestApi(url, options, navigate) {
  // const token = localStorage.getItem('x-access-token')
  const res = await fetch(`${API_URL}${url}`, {
    ...options,
    headers: {
      ...(options.headers || { 'Content-Type': 'application/json' }),

      Authorization: userStore.token || '',
    },
  });
  console.log('options', options);

  // parse response body
  const data = await res.json();
  console.log('--', data);
  if (data.message === 'Invalid Token') {
    userStore.signOut();
    navigate(`${directUrl}sign-in`);
  }
  // if (
  //   !res.ok &&
  //   (res.status === 401 || res.status === 403 || res.status === 400)
  // ) {
  //   // if request unaithorized, sign user out
  //   console.debug('unauthorized request');

  //   userStore.signOut();
  // }

  // if error, throw it
  if (!res.ok) {
    throw data;
  }

  // if ok, return token
  return data;
}
