import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Row, Col, Container, Input, Table } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

//assets
import { directUrl, homePageUrl } from '../../../helpers/Constants';
import { requestApi } from '../../../request-api';
import DataTable from 'react-data-table-component';
import { API_URL } from '../../../config';
import axios from 'axios';
import { userStore } from '../../../stores';
import { dataToXLS } from '../../../helpers/dataToXLS';

function AdminTrends() {
  const navigate = useNavigate();
  let [checked, setChecked] = useState(false);
  let [isChecked, setIsChecked] = useState(false);
  const [trend, setTrend] = useState([]);
  const [gross, setGross] = useState(0);
  const [net, setNet] = useState(0);
  const [search, setSearch] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  let [modal, setModal] = useState(false);

  let [downloadData, setDownloadData] = useState([]);
  const [filteredSearch, setFilteredSearch] = useState([]);

  const [loading, setLoading] = useState(false);

  const customModal = () => {
    console.log('method called', modal);
    setModal(!modal);
  };
  const downloadtrend = (trendId) => {
    dataToXLS(downloadData, 'Employee_Data' + new Date());
  };

  const routeChangeToCreateBranch = () => {
    navigate(`${directUrl}employee/add-trend`);
  };
  const [columns, setColumns] = useState([
    {
      name: 'Trendz',
      selector: (row, index) => (
        <td style={{ justifyContent: 'center' }}>
          {row.positionChange === -1 ? (
            <div color="limegreen">🔽</div>
          ) : row.positionChange == 1 ? (
            <div>🔼</div>
          ) : (
            '🟰'
          )}
        </td>
      ),
    },
    {
      name: 'Name',
      selector: (row) => row.name,
    },
    {
      name: 'Gross Amount',
      selector: (row) => {
        const grossAmount = row.total_gross?.toFixed(2);
        return (
          <td
            // style={{
            //   color:
            //     parseFloat(grossAmount) < 80000
            //       ? 'red'
            //       : parseFloat(grossAmount) >= 80000 &&
            //         parseFloat(grossAmount) < 150000
            //       ? 'orange'
            //       : parseFloat(grossAmount) >= 150000
            //       ? 'green'
            //       : 'inherit',
            // }}
          >
            {grossAmount}
          </td>
        );
      },
    },
    {
      name: 'GST',
      selector: (row) => row.total_gst?.toFixed(2),
    },
    {
      name: 'Net Amount',
      selector: (row) => row.total_net?.toFixed(2),
    },
  ]);
  // Function to get row style based on total gross
  const getGrossColor = (value) => {
    const grossValue = parseFloat(value);
    if (grossValue < 50000) {
      return 'lightcoral';
    } else if (grossValue > 100000) {
      return 'lightgreen';
    }
    return 'inherit';
  };
  const getData = () => {
    setLoading(true);
    requestApi(
      '/v1/getAdminUserTrend',
      {
        method: 'get',
      },
      navigate
    )
      .then((data) => {
        console.log('AyanPoonawala', data);
        setTrend(data.data);
        setFilteredSearch(data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const dummy = (e) => {
    console.log('-->', e);
    if (e.allSelected == true) {
      let rows = [];
      console.log('e.allSelected == true', e.selectedRows);

      setDownloadData(e.selectedRows);
    } else if (e.allSelected == false && e.selectedCount == 0) {
      setDownloadData([]);
    } else if (e.selectedCount > 0) {
      setDownloadData(e.selectedRows);
    } else if (e.selectedCount == 0) {
      setDownloadData([]);
    }

    if (e.selectedCount == 0) {
      setIsChecked(false);
    } else {
      setIsChecked(true);
    }
  };
  useEffect(() => {
    let sum = 0;
    filteredSearch.forEach((row) => {
      sum += row.total_gross;
    });
    setGross(sum);
    sum = sum - sum * 0.18;
    setNet(sum);
  }, [filteredSearch]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <section className="lyt-section p-0">
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : null}
      <Container fluid className="typ-container">
        <div className="bs-card">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="bs-table">
              <div className="main-header">
                <form className="bs-form">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      gap: '10px', // Add gap between items for better spacing
                    }}
                  >
                    <div
                      className="dropdown-bx report-date"
                      style={{ flex: '1 1 auto', color: 'green' }}
                    >
                      Total Gross: {gross}
                    </div>
                    <div
                      className="dropdown-bx report-date"
                      style={{ flex: '1 1 auto', color: 'green' }}
                    >
                      Total GST: {(gross * 0.18)?.toFixed(2)}
                    </div>
                    <div
                      className="dropdown-bx report-date"
                      style={{ flex: '1 1 auto', color: 'green' }}
                    >
                      Total Net: {parseFloat(net)?.toFixed(2)}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Col>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                className="bs-table typ-datable overflow-on"
                style={{ borderRadius: '10px', border: '1px solid #ddd' }}
              >
                <DataTable
                  columns={columns}
                  data={filteredSearch}
                  pagination
                  fixedHeader
                  fixedHeaderScrollHeight="450px"
                  // selectableRows
                  // onSelectedRowsChange={dummy}
                  // selectableRowsHighlight
                  highlightOnHover
                  style={{ backgroundColor: '#fff' }} // Apply background color to table body
                  paginationPerPage={50} // Display 30 rows per page
                  paginationRowsPerPageOptions={[50, 60, 80, 100]} // Set default row size to 30
                  className="custom-table" // Apply custom class to the DataTable component
                  noHeader
                  conditionalRowStyles={[
                    {
                      when: (row) =>
                        parseFloat(row.total_gross?.toFixed(2)) <= 50000,
                      style: {
                        backgroundColor: '#FE0000', // Red background color for rows with gross amount less than 80000
                      },
                    },
                    {
                      when: (row) =>
                        parseFloat(row.total_gross?.toFixed(2)) < 80000 &&
                        parseFloat(row.total_gross?.toFixed(2)) > 50000,
                      style: {
                        backgroundColor: '#ffcccc', // Red background color for rows with gross amount less than 80000
                      },
                    },
                    {
                      when: (row) =>
                        parseFloat(row.total_gross?.toFixed(2)) >= 80000 &&
                        parseFloat(row.total_gross?.toFixed(2)) < 150000,
                      style: {
                        backgroundColor: '#FFD580',
                      },
                    },
                    {
                      when: (row) =>
                        parseFloat(row.total_gross?.toFixed(2)) >= 150000,
                      style: {
                        backgroundColor: '#ccffcc',
                      },
                    },
                  ]}
                />
              </div>
              <style>
                {`
      .rdt_TableHead {
        background-color: #f2f2f2;
      }

      .rdt_TableRow:hover {
        background-color: #f9f9f9;
      }

      .rdt_Pagination {
        display: flex;
        justify-content: center;
        margin-top: 10px;
      }

      .rdt_PaginationPrev,
      .rdt_PaginationNext {
        margin: 0 5px;
        padding: 5px 10px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
      }

      .rdt_PaginationPrev:hover,
      .rdt_PaginationNext:hover {
        background-color: #0056b3;
      }

      .rdt_PaginationPage {
        margin: 0 5px;
      }

      .rdt_PaginationPageActive {
        font-weight: bold;
        color: #007bff;
      }
    `}
              </style>
            </Col>
           
          </Row>
        </div>
      </Container>
    </section>
  );
}

export default AdminTrends;
