import React, { useEffect, useState, useContext, useRef } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
} from 'reactstrap';

//constant
import { directUrl, homePageUrl } from '../../../helpers/Constants';
import { useDispatch, useSelector } from 'react-redux';

//assets
import logo from '../../../assets/image/logo_bck.png';
import { userStore } from '../../../stores';
import './Style.css';
import { MyLocation } from '@mui/icons-material';
import { requestApi } from '../../../request-api';
import { GlobalContext } from '../../../contexts/GlobalContext';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { API_URL } from '../../../config';
import { jwtDecode } from 'jwt-decode';
import io from 'socket.io-client';

import { setSocketConnection } from '../../../redux/userSlice';
let notifications = [];
function UserHeader(props) {
  const { notification, setNotification, roomId, setRoomId } =
    useContext(GlobalContext);

  const location = useLocation();
  let myLocation = location.pathname;
  let navigate = useNavigate();
  const dispatch = useDispatch();
  let { hideHeader } = props;
  const [user, setUser] = useState({});
  const [latestNotifications, setLatestNotifications] = useState([]);
  const patchSeen = async (id) => {
    requestApi(
      `/v1/notifications/${id}/mark-seen`,
      {
        method: 'get',
      },
      navigate
    )
      .then((data) => {
        console.log(data);
        if (data.statusCode === 200) {
          console.log('aaaaaaaaaa', data.data);
          notifications = data.data || [];
          console.log(notifications, 'here');
          // Get the latest 5 notifications
          setLatestNotifications(notifications.slice(-5));
        }
      })
      .catch((error) => {
        if (error?.statusCode === 404) {
        }
        console.log(error);
      });
  };
  const getNoti = async () => {
    requestApi(
      '/v1/notifications',
      {
        method: 'get',
      },
      navigate
    )
      .then((data) => {
        console.log(data);
        if (data.statusCode === 200) {
          console.log('aaaaaaaaaa', data.data);
          notifications = data.data;
          console.log(notifications, 'here');
          // Get the latest 5 notifications
          setLatestNotifications(notifications.slice(-5));
        }
      })
      .catch((error) => {
        if (error?.statusCode === 404) {
        }
        console.log(error);
      });
  };
  useEffect(() => {
    getNoti();
  }, []);
  // Fetch user data from token
  useEffect(() => {
    const token = localStorage.getItem('x-access-token') || '';
    if (token) {
      const decodedUser = jwtDecode(token);
      setUser(decodedUser);
    }
  }, [notifications?.length]);
  useEffect(() => {
    const socketConnection = io(API_URL, {
      auth: { token: localStorage.getItem('x-access-token') },
    });

    socketConnection.on('notification', (data) => {
      console.log(data);
      notifications.push(data);
      console.log(notifications, 'here');
      // Get the latest 5 notifications
      setLatestNotifications(notifications.slice(-5));
    });

    dispatch(setSocketConnection(socketConnection));

    return () => {
      socketConnection.disconnect();
    };
  }, [dispatch]);

  // Handle notification click and remove from the list
  const handleNotificationClick = (data) => {
    console.log(data);
    // notifications = notifications.filter(
    //   (notification) => notification.id !== data.id
    // );
    patchSeen(data.id);
    navigate(`${directUrl}chat/${data.senderId}`);
  };

  let [isOpen, setIsOpen] = useState(false);

  //Mobile menu
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const logout = () => {
    localStorage.clear();
    navigate(`${directUrl}sign-in`);
  };

  useEffect(() => {
    userData();
  }, []);

  const userData = () => {
    const token = localStorage.getItem('x-access-token') || '';

    if (token) {
      const user = jwtDecode(token);
      console.log('=========>', user);
      setUser(user);
    }
  };

  return (
    <>
      {!hideHeader && (
        <header className="bs-header">
          <Navbar expand="xl">
            <div className="navbar-outer">
              <NavbarBrand href={``}>
                <img className="nav-logo" src={logo} alt="JK Logo" />
              </NavbarBrand>
              <NavbarToggler onClick={toggle} />
              <Collapse isOpen={isOpen} navbar>
                <Nav className="ml-auto navbar-left" navbar>
                  <NavItem>
                    <NavLink
                      className="nav-link first"
                      exact="true"
                      to={`employee/home`}
                    >
                      <i className="icon icon-home"></i>HOME
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={
                        myLocation.includes('employee/customer') ||
                        myLocation.includes('employee/add-customer')
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      exact="true"
                      to={`employee/customer`}
                    >
                      <i className="icon icon-user"></i>
                      Customer
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={
                        myLocation.includes('employee/trend')
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      to={`employee/trend`}
                    >
                      <i className="icon icon-client"></i>Trend
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={
                        myLocation.includes('employee/lead')
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      to={`employee/lead`}
                    >
                      <i className="icon icon-client"></i>LEAD
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={
                        myLocation.includes('employee/SCI')
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      to={`employee/SCI`}
                    >
                      <i className="icon icon-client"></i>SCI
                    </NavLink>
                  </NavItem>
                  <Nav className="ml-auto navbar-right" navbar>
                    <UncontrolledDropdown className="user-drop" nav inNavbar>
                      <DropdownToggle nav caret>
                        <i className="icon icon-branch"></i>Updates
                      </DropdownToggle>
                      <DropdownMenu end>
                        <DropdownItem
                          onClick={() => {
                            navigate(`${directUrl}employee/holdings`);
                          }}
                        >
                          Quantity Holdings Update
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            navigate(`${directUrl}employee/payment-updates`);
                          }}
                        >
                          Payment Updates
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            navigate(`${directUrl}employee/ft-counts`);
                          }}
                        >
                          Ft Count
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Nav>
                  <NavItem>
                    <NavLink
                      className={
                        myLocation.includes('employee/dashboard')
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      to={`employee/dashboard`}
                    >
                      <i className="icon icon-user"></i>dashboard
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={
                        myLocation.includes('chat')
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      to={`chat`}
                    >
                      <i className="icon icon-user"></i>Chat
                    </NavLink>
                  </NavItem>
                </Nav>

                <Nav className="ml-auto navbar-right" navbar>
                  <UncontrolledDropdown className="user-drop" nav inNavbar>
                    <DropdownToggle
                      className="notification-box"
                      nav
                      caret
                      style={{
                        position: 'relative',
                        padding: '0',
                        border: 'none',
                        color: '#ffffff', // White color for the bell icon
                        display: 'flex',
                        alignItems: 'center', // Vertical centering
                        justifyContent: 'center', // Horizontal centering
                        width: '40px', // Set fixed width to prevent collapsing
                        height: '40px', // Set fixed height for proper vertical centering
                      }}
                      title="Notifications" // Tooltip for bell icon
                    >
                      <i
                        className="icon icon-bell"
                        style={{ fontSize: '24px', color: '#ffffff' }}
                      ></i>
                      {latestNotifications.length > 0 && (
                        <span
                          style={{
                            position: 'absolute',
                            top: '-8px',
                            right: '-8px',
                            backgroundColor: '#e74c3c', // Red color for notification count
                            color: '#ffffff',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            borderRadius: '50%',
                            padding: '2px 8px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                          }}
                        >
                          {latestNotifications.length}
                        </span>
                      )}
                    </DropdownToggle>

                    <DropdownMenu
                      end
                      style={{
                        backgroundColor: '#ffffff',
                        color: '#ffffff',
                        border: '1px solid #74788d',
                      }}
                    >
                      <DropdownItem
                        header
                        style={{
                          fontWeight: 'bold',
                          color: '#0586CD',
                          fontSize: '16px',
                        }}
                      >
                        Notifications
                      </DropdownItem>
                      {latestNotifications.length === 0 ? (
                        <DropdownItem style={{ color: '#74788d' }}>
                          No new notifications
                        </DropdownItem>
                      ) : (
                        latestNotifications.map((notification) => (
                          <DropdownItem
                            key={notification.id}
                            onClick={() =>
                              handleNotificationClick(notification)
                            }
                            style={{
                              color: '#ffffff',
                              fontSize: '14px',
                              padding: '10px',
                              borderBottom: '1px solid #74788d',
                              transition: 'background-color 0.3s',
                            }}
                            onMouseEnter={(e) =>
                              (e.currentTarget.style.backgroundColor =
                                '#0586CD')
                            }
                            onMouseLeave={(e) =>
                              (e.currentTarget.style.backgroundColor =
                                'transparent')
                            }
                          >
                            <div style={{ fontWeight: 'bold' }}>
                              {notification.title}
                            </div>
                            <div style={{ fontSize: '12px', color: '#b0b0b0' }}>
                              {notification.message}
                            </div>
                          </DropdownItem>
                        ))
                      )}
                    </DropdownMenu>
                  </UncontrolledDropdown>

                  <UncontrolledDropdown className="user-drop" nav inNavbar>
                    <DropdownToggle nav caret>
                      <i className="icon icon-user-round"></i>
                    </DropdownToggle>
                    <DropdownMenu end>
                      <DropdownItem>Hi, {user.name}</DropdownItem>
                      {/* <DropdownItem onClick={directToSetting}>
                        Edit Profile
                      </DropdownItem> */}
                      <DropdownItem onClick={logout}>Log Out</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
              </Collapse>
            </div>
          </Navbar>
        </header>
      )}
    </>
  );
}

export default UserHeader;
