import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Col, Container, Row, Input, Label, Table } from 'reactstrap';
import {
  PieChart,
  Pie,
  Sector,
  ResponsiveContainer,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

import { Navigate, useNavigate } from 'react-router-dom';
//assets
import company from '../../../assets/image/icons/company.svg';
import activeReport from '../../../assets/image/icons/active-report.svg';
import branches from '../../../assets/image/icons/branches.svg';
import tick from '../../../assets/image/icons/tick.svg';
import { directUrl, homePageUrl } from '../../../helpers/Constants';
import { requestApi } from '../../../request-api';
import moment from 'moment/moment';
import DataTable from 'react-data-table-component';

function EmployeeDashboard() {
  const [user, setUser] = useState({});
  const [reportData, setReportData] = useState([]);
  const [complianceFilter, setComplianceFilter] = useState([]);
  const [companyComplianceRoute, setCompanyComplianceRoute] = useState([]);
  const [totalPayments, settotalPayments] = useState([]);
  const [totalBranch, setTotalBranch] = useState([]);
  const [totalClose, setTotalClose] = useState([]);
  const [totalEmployeesCount, setTotalEmployeesCount] = useState([]);
  const [totalCompiledWithException, setTotalCompiledWithException] = useState(
    []
  );
  const [totalGross, setTotalGross] = useState(0);
  const [totalGST, setTotalGST] = useState(0);
  const [totalNet, setTotalNet] = useState(0);
  const [companyFilter, setCompanyFilter] = useState([]);
  const [upComingDue, setUpComingDue] = useState([]);
  const [dueDateCompliance, setDueDateCompliance] = useState([]);
  const [search, setSearch] = useState('');
  let navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  const [dueStatisticCompany, setDueStatisticCompany] = useState([]);
  const [dueStatisticCompanyFilter, setDueStatisticCompanyFilter] = useState(
    []
  );
  const [dueStatistic, setDueStatistic] = useState([]);
  const [dueSearch, setDueSearch] = useState('');
  const [overAllStatics, setOverAllStatics] = useState([]);
  function formatNumber(num) {
    num = parseFloat(num)?.toFixed(2);
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1) + 'B';
    }
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M';
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'K';
    }
    return num;
  }
  const data = [
    { name: 'Gross', value: totalGross, fill: 'green' },
    { name: 'GST', value: totalGST, fill: 'blue' },
    { name: 'Net', value: totalNet, fill: 'purple' },
  ];
  const data2 = [
    { name: 'Gross Amount', value: totalGross },
    { name: 'Net Amount', value: totalNet },
  ];
  const COLORS = ['#038EDC', '#564AB1'];
  const colorBar = [
    '#C0392B',
    '#AF7AC5',
    '#5499C7',
    '#48C9B0',
    '#6666FF',
    '#CCCC99',
    '#CCCCFF',
    '#FFCCFF',
    '#FFCC33',
    '#FF9933',
    'purple',
  ];
  const barData = overAllStatics;

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  let [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const getUserData = () => {
    requestApi(
      '/v1/get-user-dashboard',
      {
        method: 'get',
      },
      navigate
    )
      .then((data) => {
        console.log('User data set -->', data.data);
        setTotalBranch(data.data.branch_count);
        setTotalGross(data.data.total_gross);
        setTotalNet(data.data.total_net);
        setTotalGST(data.data.total_gst);
        settotalPayments(data.data.payments_count);
        setTotalEmployeesCount(data.data.employee_count);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (search == 'empty') {
      return setComplianceFilter(reportData);
    }
    const result = reportData.filter((comp) => {
      return (
        comp.report.legislation[0].company_name
          .toLowerCase()
          .match(search.toLowerCase()) ||
        comp.report.report_id.toLowerCase().match(search.toLowerCase())
      );
    });
    setComplianceFilter(result);
  }, [search]);
  useEffect(() => {
    if (dueSearch === 'empty') {
      return setDueStatisticCompanyFilter(dueStatistic);
    }
    const result = dueStatistic.filter((comp) => {
      return comp.company_name.toLowerCase().match(dueSearch.toLowerCase());
    });
    setDueStatisticCompanyFilter(result);
  }, [dueSearch]);
  useEffect(() => {
    getUserData();
  }, []);

  return (
    <main>
      <section className="lyt-section">
        {loading ? (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        ) : null}
        <Container fluid className="typ-container">
          {/* <h1 className="bs-heading">Hello {user.name}</h1> */}
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
              <div className="bs-card">
                <h2 className="heading">Montly Amount Received</h2>
                <div className="overview-div">
                  <div className="text-center">
                    <p className="count">{formatNumber(totalGross)}</p>
                    <p className="bs-status-txt bold">Gross Amount</p>
                  </div>
                  <div className="text-center">
                    <p className="count">{formatNumber(totalGST)}</p>
                    <p className="bs-status-txt typ-red bold">GST(18%)</p>
                  </div>
                  <div className="text-center">
                    <p className="count">{formatNumber(totalNet)}</p>
                    <p className="bs-status-txt typ-blue bold">Net Amount</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
              <div className="bs-card">
                <h2 className="heading mb-0">OVERVIEW</h2>
                <div className="overview-div typ-round">
                  <div className="inner-view">
                    <div className="img-area">
                      <img src={company} alt="Total Employees" />
                    </div>
                    <div>
                      <p className="count">{totalEmployeesCount}</p>
                      <p className="sub-head">Total Employees</p>
                    </div>
                  </div>
                  <div className="inner-view">
                    <div className="img-area typ-blue">
                      <img src={branches} alt="Total Branches" />
                    </div>
                    <div>
                      <p className="count">{totalBranch}</p>
                      <p className="sub-head">Total Branches</p>
                    </div>
                  </div>
                  <div className="inner-view">
                    <div className="img-area typ-orange">
                      <img src={activeReport} alt="Active Reports" />
                    </div>
                    <div>
                      <p className="count">{totalPayments}</p>
                      <p className="sub-head">Total Payments</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
              <div className="bs-card mt-0">
                <div className="d-flex justify-content-between align-items-center mob-flex-colum">
                  <h2 className="heading typ-overall">OVERALL STATISTICS</h2>
                  <form className="bs-form">
                    {/* <div className="dropdown-bx">
                      <label className="form-label">Sort By : </label>
                      <Input type="select" name="select" id="exampleSelect">
                        <option>Monthly</option>
                        <option>Yearly</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Input>
                    </div> */}
                  </form>
                </div>
                <div className="chart-area">
                  <ResponsiveContainer
                    width="50%"
                    height="100%"
                    className="main-pie"
                  >
                    <PieChart width={'100%'} height={600}>
                      <Pie
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        data={data}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        dataKey="value"
                        onMouseEnter={onPieEnter}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                  <div className="legends">
                    <div>
                      <p className="bs-status-txt bold">Gross Amount</p>
                    </div>

                    <div>
                      <p className="bs-status-txt typ-blue  bold">GST</p>
                    </div>
                    <div>
                      <p className="bs-status-txt typ-purple  bold">
                        Net Amount
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
              <div className="bs-card mt-0">
                <h2 className="heading">Amount OVERVIEW</h2>
                <div className="d-flex align-items-center mob-flex-colum">
                  <div className="chart-area">
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart width={200} height={200}>
                        <Pie
                          data={data2}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={80}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {data.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                  <div className="legends">
                    <div>
                      <p className="bs-status-txt typ-blue bold">
                        Gross Amount
                      </p>
                    </div>
                    <div>
                      <p className="bs-status-txt typ-purple bold">
                        Net Amount
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            {/* <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className='bs-table'>
                <div className='main-header'>
                  <form className='bs-form'>
                    <div className='search-btn'>
                      <Input type='text' placeholder='Search...' />
                      <i className='icon icon-search'></i>
                    </div>
                    <div className='dropdown-bx'>
                      <label className='form-label'>Company Name :  </label>
                      <Input type="select" name="select" id="exampleSelect">
                        <option>Select</option>
                        <option>Yearly</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Input>
                    </div>
                    <div className='dropdown-bx'>
                      <label className='form-label'>Sort By : </label>
                      <Input type="select" name="select" id="exampleSelect">
                        <option>Select</option>
                        <option>Yearly</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Input>
                    </div>
                  </form>
                </div>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Sr.No.</th>
                      <th>Clinetele</th>
                      <th>Report_ID</th>
                      <th>Total Complainces</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>HDFC</td>
                      <td><NavLink to={``}>HDFC_worli_compl.officer_Dn0_Date</NavLink></td>
                      <td>200</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>ICICI</td>
                      <td><NavLink to={``}>ICICI_worli_compl.officer_Dn0_Date</NavLink></td>
                      <td>180</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Samsung</td>
                      <td><NavLink to={``}>Samsung_worli_compl.officer_Dn0_Date</NavLink></td>
                      <td>400</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Samsung</td>
                      <td><NavLink to={``}>Samsung_worli_compl.officer_Dn0_Date</NavLink></td>
                      <td>400</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Wipro</td>
                      <td><NavLink to={``}>WIpro_worli_compl.officer_Dn0_Date</NavLink></td>
                      <td>100</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>TCS</td>
                      <td><NavLink to={`login`}>TCS_worli_compl.officer_Dn0_Date</NavLink></td>
                      <td>210</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>   */}
          </Row>
        </Container>
      </section>
    </main>
  );
}

export default EmployeeDashboard;
