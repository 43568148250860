import React, { useEffect, useState } from 'react';
import {
  Button,
  FormGroup,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  Row,
  Col,
  Container,
  Input,
  Label,
  NavLink as TabLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
import { requestApi } from '../../../request-api';
import { useNavigate, Link } from 'react-router-dom';
import { directUrl, homePageUrl } from '../../../helpers/Constants';

//assets
import upload from '../../../assets/image/icons/upload.svg';
import { async } from 'q';
import { API_URL } from '../../../config';
import { userStore } from '../../../stores';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';

function CreateData() {
  let navigate = useNavigate();

  let [currentActiveTab, setCurrentActiveTab] = useState('1');
  let [calendarValue, setCalenderValue] = useState(new Date());
  let [showCalender, setShowCalender] = useState(false);
  let [selectedDate, setSelectedDate] = useState('');
 
  const [fileData, setFileData] = useState({});
  const [fileData2, setFileData2] = useState({});
  let [filetxt, setFiletxt] = useState('Choose File');
  let [filetxt2, setFiletxt2] = useState('Choose File');
  let [browseremove, setBrowseremove] = useState('Browse');
  let [browseremove2, setBrowseremove2] = useState('Browse');
  let [formErrors, setFormErrors] = useState({});
  let [isSubmit, setIsSubmit] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [startDate2, setStartDate2] = useState(new Date());
  let [loading, setLoading] = useState(false);
  let [modal, setModal] = useState(false);
  let [modal2, setModal2] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [excelData2, setExcelData2] = useState([]);

  const successToast = (message) =>
    toast.success(message, {
      position: 'bottom-right',
    });

  const errorToast = (error) =>
    toast.error(error, {
      position: 'bottom-right',
    });

  const onFileChange = (event) => {
    setFileData(event.target.files[0]);
  };
  const onFileChange2 = (event) => {
    setFileData2(event.target.files[0]);
  };

  const toggle = (tab) => {
    if (currentActiveTab !== tab) {
      setCurrentActiveTab(tab);
    }
  };

  const customModal = () => {
    console.log('method called', modal);
    setModal(!modal);
  };
  const customModal2 = () => {
    console.log('method2 called', modal2);
    setModal2(!modal2);
  };

  const handleFileUpload = (e) => {
    setLoading(true);
    const file = e.target.files[0];
    const reader = new FileReader();
    console.log(file?.name, 'file');

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0]; // Assuming only one sheet
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, {
        header: 1,
        defval: '',
      });

      // Convert array of arrays to array of objects
      const convertedData = jsonData.map((row) => {
        const obj = {};
        row.forEach((value, index) => {
          obj[jsonData[0][index]] = value;
        });
        obj.filename = file?.name + '_bulk_' + Date.now();
        obj.type = 'bulk';
        return obj;
      });
      console.log(convertedData);
      setExcelData(convertedData.slice(1)); // Remove the first row as it's used for keys
      setLoading(false);
    };

    reader.readAsArrayBuffer(file);
  };
  const handleFileUpload2 = (e) => {
    setLoading(true);
    const file = e.target.files[0];
    const reader = new FileReader();
    console.log(file?.name, 'file');
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0]; // Assuming only one sheet
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, {
        header: 1,
        defval: '',
      });

      // Convert array of arrays to array of objects
      const convertedData = jsonData.map((row) => {
        const obj = {};
        row.forEach((value, index) => {
          obj[jsonData[0][index]] = value;
        });
        obj.filename = file?.name + '_fresh_' + Date.now();
        obj.type = 'fresh';
        return obj;
      });
      console.log(convertedData);
      setExcelData2(convertedData.slice(1)); // Remove the first row as it's used for keys
      setLoading(false);
    };

    reader.readAsArrayBuffer(file);
  };
  const submitForm = async (isSubmit) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', fileData);
    formData.append('filename', fileData.name);
    formData.append('date', startDate);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: userStore.token || '',
      },
    };
    customModal();
    return axios
      .post(`${API_URL}/v1/leaddata/add-bulkdata`, formData, config)
      .then((data) => {
        setLoading(false);
        if (data.data.statusCode === 200) {
          successToast('Successfully created bulk Data');
          navigate(`${directUrl}admin/lead-data-file`);
        }
      })
      .catch((error) => {
        setLoading(false);
        errorToast(error.message);
      });
  };

  const submitForm2 = async (isSubmit) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', fileData2);
    formData.append('filename', fileData2.name);
    formData.append('date', startDate2);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: userStore.token || '',
      },
    };
    customModal2();
    return axios
      .post(`${API_URL}/v1/leaddata/add-freshdata`, formData, config)
      .then((data) => {
        setLoading(false);
        if (data.data.statusCode === 200) {
          successToast('Successfully created fresh Data');
          navigate(`${directUrl}admin/lead-data-file`);
        }
      })
      .catch((error) => {
        setLoading(false);
        errorToast(error.message);
      });
  };
  useEffect(() => {
    setSelectedDate(
      `${calendarValue.getDate()}/${
        calendarValue.getMonth() + 1
      }/${calendarValue.getFullYear()}`
    );
  }, [calendarValue]);

  const formValidation = (values) => {
    setLoading(true);
    console.log(fileData);
    const errors = {};
    if (currentActiveTab === '1') {
      if (!fileData?.name) {
        errors.file = 'File is Empty';
      } else {
        customModal();
      }
      setLoading(false);
    }
    if (currentActiveTab === '2') {
      if (!fileData2?.name) {
        errors.file = 'File is required';
      } else {
        customModal2();
      }
      setLoading(false);
    }

    return errors;
  };

  const file_select2 = (e) => {
    onFileChange2(e);
    // handleFileUpload2(e);
    if (e.target.files[0] == null) {
      setFiletxt2('Choose File');
      setBrowseremove2('Browse');
    } else {
      const filepath = e.target.files[0].name;
      setFiletxt2(filepath);
      setBrowseremove2('Change File');
    }
  };
  const file_select = (e) => {
    onFileChange(e);

    if (e.target.files[0] == null) {
      setFiletxt('Choose File');
      setBrowseremove('Browse');
    } else {
      const filepath = e.target.files[0].name;
      setFiletxt(filepath);
      setBrowseremove('Change File');
    }
    // handleFileUpload(e);
  };

  const validateBanner = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    const validate = await formValidation();
    setFormErrors(validate);
    console.log('validate', validate);
    if (currentActiveTab === '2') {
      errorToast(validate.file);
    }
    if (currentActiveTab === '1') {
      errorToast(validate.file);
    }
  };

  const submitBanner = async (e) => {
    if (Object.keys(formErrors).length == 0) {
      if (currentActiveTab === '1') {
        submitForm(e);
      } else {
        submitForm2(e);
      }
    } else {
      errorToast(formErrors?.file);
    }
  };
  return (
    <main onClick={() => setShowCalender(!showCalender)}>
      <section className="lyt-section"></section>
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : null}

      <section className="lyt-section p-0">
        <Container fluid className="typ-container">
          <div className="bs-card">
            <Row>
              <div className="bs-tabs">
                <Nav tabs>
                  <NavItem>
                    <TabLink
                      className={classnames({
                        active: currentActiveTab === '1',
                      })}
                      onClick={() => {
                        toggle('1');
                      }}
                    >
                      Bulk Upload File
                    </TabLink>
                  </NavItem>
                  <NavItem>
                    <TabLink
                      className={classnames({
                        active: currentActiveTab === '2',
                      })}
                      onClick={() => {
                        toggle('2');
                      }}
                    >
                      Fresh Upload File
                    </TabLink>
                  </NavItem>
                </Nav>
              </div>
              <TabContent activeTab={currentActiveTab} className="px-0">
                <TabPane tabId="1">
                  <Container>
                    <FormGroup className="bs-form">
                      <Row>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            Date
                            <span style={{ color: 'red' }}>*</span>
                          </Label>
                          {/* <div class="searchbox-wrap">
                                                <Input type="text" name="date" id="examplePassword" className='cstm-input' placeholder="Enter Date of Joining" />
                                                <button className='bs-btn typ-date'><span class="icon-calendar"></span></button>
                                            </div> */}
                          {/* {showCalender && <div className='bs-calender'>
                                                <Calendar onChange={setCalenderValue} value={calendarValue} />
                                            </div>} */}
                          <div className="calender-area">
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              placeholderText="Enter Date "
                            />
                          </div>
                          <p className="error-message">
                            {formErrors.datepicker}
                          </p>
                        </Col>
                      </Row>
                    </FormGroup>
                    <Row>
                      <div className="selector">
                        <img className="upload_img" src={upload} />
                        <FormGroup>
                          <div className="selector-text">
                            <h3 className="dynamic-message">
                              {' '}
                              <span className="browse-files-text">
                                {filetxt}
                              </span>{' '}
                              <span
                                className="bs-btn typ-small btn-submit"
                                type="button"
                              >
                                {browseremove}
                              </span>
                              <input
                                type="file"
                                id="fileinput"
                                className="default-file-input"
                                onChange={file_select}
                                accept=".xlsx, .xls"
                              />{' '}
                            </h3>
                            <label className="text-label">
                              {' '}
                              Supported format: Excel{' '}
                            </label>
                          </div>
                          <button
                            className="bs-btn btn-submit typ-center"
                            onClick={(e) => validateBanner(e)}
                          >
                            SUBMIT
                          </button>
                        </FormGroup>
                      </div>
                    </Row>
                  </Container>
                </TabPane>
                <TabPane tabId="2">
                  <Container>
                    <FormGroup className="bs-form">
                      <Row>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            Date
                            <span style={{ color: 'red' }}>*</span>
                          </Label>
                          {/* <div class="searchbox-wrap">
                                                <Input type="text" name="date" id="examplePassword" className='cstm-input' placeholder="Enter Date of Joining" />
                                                <button className='bs-btn typ-date'><span class="icon-calendar"></span></button>
                                            </div> */}
                          {/* {showCalender && <div className='bs-calender'>
                                                <Calendar onChange={setCalenderValue} value={calendarValue} />
                                            </div>} */}
                          <div className="calender-area">
                            <DatePicker
                              selected={startDate2}
                              onChange={(date) => setStartDate2(date)}
                              placeholderText="Enter Date"
                            />
                          </div>
                          <p className="error-message">
                            {formErrors.datepicker}
                          </p>
                        </Col>
                      </Row>
                    </FormGroup>
                    <Row>
                      <div className="selector">
                        <img className="upload_img" src={upload} />
                        <FormGroup>
                          <div className="selector-text">
                            <h3 className="dynamic-message">
                              {' '}
                              <span className="browse-files-text">
                                {filetxt2}
                              </span>{' '}
                              <span
                                className="bs-btn typ-small btn-submit"
                                type="button"
                              >
                                {browseremove2}
                              </span>
                              <input
                                type="file"
                                id="fileinput"
                                className="default-file-input"
                                onChange={file_select2}
                              />{' '}
                            </h3>
                            <label className="text-label">
                              {' '}
                              Supported format: Excel{' '}
                            </label>
                          </div>
                          <button
                            className="bs-btn btn-submit typ-center"
                            onClick={(e) => validateBanner(e)}
                          >
                            SUBMIT
                          </button>
                        </FormGroup>
                      </div>
                    </Row>
                  </Container>
                </TabPane>
              </TabContent>
            </Row>
          </div>
          <ToastContainer />
        </Container>
      </section>

      <Modal
        isOpen={modal}
        toggle={customModal}
        size={'lg'}
        centered={true}
        className="bs-modal"
      >
        <ModalHeader toggle={customModal}></ModalHeader>
        <ModalBody toggle={customModal}>
          <p className="title">Do you realy want to submit bulk data?</p>
        </ModalBody>
        <ModalFooter>
          <button
            onClick={submitBanner}
            className="bs-btn typ-replace min-width margin-right"
          >
            Submit
          </button>{' '}
          <button
            onClick={customModal}
            className="bs-btn typ-outline min-width"
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={modal2}
        toggle={customModal2}
        size={'lg'}
        centered={true}
        className="bs-modal"
      >
        <ModalHeader toggle={customModal2}></ModalHeader>
        <ModalBody toggle={customModal2}>
          <p className="title">Do you realy want to submit?</p>
        </ModalBody>
        <ModalFooter>
          <button
            onClick={submitBanner}
            className="bs-btn typ-replace min-width margin-right"
          >
            Submit
          </button>{' '}
          <button
            onClick={customModal2}
            className="bs-btn typ-outline min-width"
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </main>
  );
}

export default CreateData;
