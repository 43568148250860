import React, { useEffect, useState } from 'react';
import {
  Button,
  FormGroup,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  Row,
  Col,
  Container,
  Input,
  Label,
  NavLink as TabLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
import { requestApi } from '../../../request-api';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { directUrl, homePageUrl } from '../../../helpers/Constants';

//assets
import upload from '../../../assets/image/icons/upload.svg';
import { async } from 'q';
import { API_URL } from '../../../config';
import { userStore } from '../../../stores';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Triangle } from 'react-loader-spinner';
import { width } from '@mui/system';
import FileSaver from 'file-saver';
import xls from '../../../assets/bulkBranchUplod.xlsx';

function UpdateCustomer() {
  let navigate = useNavigate();
  const { id } = useParams();
  let [currentActiveTab, setCurrentActiveTab] = useState('1');
  let [calendarValue, setCalenderValue] = useState(new Date());
  let [showCalender, setShowCalender] = useState(false);
  let [selectedDate, setSelectedDate] = useState('');
  let [branchName, setBranchName] = useState('');
  let [customerName, setCustomerName] = useState('');
  let [empId, setEmpId] = useState('');
  let [phoneNumber, setPhoneNumber] = useState('');
  let [userRole, setUserRole] = useState('');
  let [emailId, setEmailId] = useState('');
  let [address, setAddress] = useState('');
  let [confirmaddress, setConfirmaddress] = useState('');
  let [company, setCompany] = useState([]);
  let [compliancer, setCompliancer] = useState(['admin', 'employee']);
  let [formErrors, setFormErrors] = useState({});
  let [isSubmit, setIsSubmit] = useState(false);

  let [loading, setLoading] = useState(false);
  let [modal, setModal] = useState(false);

  const successToast = (message) =>
    toast.success(message, {
      position: 'bottom-right',
    });

  const errorToast = (error) =>
    toast.error(error, {
      position: 'bottom-right',
    });

  const toggle = (tab) => {
    if (currentActiveTab !== tab) {
      setCurrentActiveTab(tab);
    }
  };
  useEffect(() => {
    setLoading(true);
    getCustomer();
    setLoading(false);
  }, []);
  const getCustomer = async () => {
    setLoading(true);
    requestApi(`/v1/get-customer-id/${id}`, {
      method: 'get',
    })
      .then((data) => {
        setCustomerName(data.data.name);
        setEmailId(data.data.email);
        setAddress(data.data.address);
        setPhoneNumber(data.data.phone);
        setLoading(false);
      })
      .catch((error) => {
        errorToast(error);
        setLoading(false);
      });
  };

  const customModal = () => {
    console.log('method called', modal);
    setModal(!modal);
  };

  useEffect(() => {
    setSelectedDate(
      `${calendarValue.getDate()}/${
        calendarValue.getMonth() + 1
      }/${calendarValue.getFullYear()}`
    );
  }, [calendarValue]);

  const formValidation = (values) => {
    setLoading(true);
    const errors = {};
    const mobRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (currentActiveTab === '1') {
      if (customerName == '') {
        errors.customerName = 'Customer Name is required';
      }
      if (phoneNumber == '') {
        errors.phoneNumber = 'Phone Number is required';
      } else if (!mobRegex.test(phoneNumber)) {
        errors.phoneNumber = 'Invalid Number';
      }
      if (emailId == '') {
        errors.email = 'Email Address is required';
      } else if (!emailRegex.test(emailId)) {
        errors.email = 'Invalid Email Address';
      }
      if (address == '') {
        errors.address = 'Office Address is required';
      }
      // if (!values.datepicker) {
      //   errors.datepicker = "Date is required";
      // }
      // if (userRole == '') {
      //   errors.selectofficer = "Select Officer is required";
      // } else if (userRole == 'Select Complaince Officer') {
      //   errors.selectofficer = "Select Officer is required";
      // }
      setLoading(false);
    }

    return errors;
  };

  const updateCustomer = async (isSubmit) => {
    setLoading(true);
    console.debug('sending code', userRole);
    requestApi(
      `/v1/update-customer-details/${id}`,
      {
        method: 'put',
        body: JSON.stringify({
          name: customerName,
          email: emailId,
          phone: phoneNumber,
          address: address,
        }),
      },
      navigate
    )
      .then((data) => {
        console.log(data);
        if (data.statusCode === 200) {
          setLoading(false);
          successToast('Successfully Edit Customer');
          navigate(`${directUrl}employee/customer`);
        }
        if (data.statusCode === 203) {
          setLoading(false);
          errorToast('Customer Already Exist');
          customModal();
        }
      })
      .catch((error) => {
        setLoading(false);
        errorToast(error.message);
        console.log(error);
      });
  };

  const validateBanner = async (e, isSubmit) => {
    e.preventDefault();
    const validate = formValidation();
    setFormErrors(validate);
    console.log('validate', validate);
    if (Object.keys(validate).length == 0) {
      if (currentActiveTab === '1') {
        updateCustomer(isSubmit);
      }
    }
  };
  return (
    <main onClick={() => setShowCalender(!showCalender)}>
      <section className="lyt-section"></section>
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : null}

      <section className="lyt-section p-0">
        <Container fluid className="typ-container">
          <div className="bs-card">
            <Row>
              <div className="bs-tabs">
                <Nav tabs>
                  <NavItem>
                    <TabLink
                      className={classnames({
                        active: currentActiveTab === '1',
                      })}
                      onClick={() => {
                        toggle('1');
                      }}
                    >
                      Edit Customer
                    </TabLink>
                  </NavItem>
                </Nav>
              </div>
              <TabContent activeTab={currentActiveTab} className="px-0">
                <TabPane tabId="1">
                  <Container fluid>
                    <FormGroup className="bs-form">
                      <Row>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="exampleaddress"
                            className="mr-sm-2 cstm-label"
                          >
                            Customer Name
                            <span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            name="text"
                            id="exampleaddress"
                            className="cstm-input"
                            placeholder="Enter Customer Name"
                            value={customerName}
                            onChange={(e) => {
                              setCustomerName(e.target.value);
                            }}
                          />
                          <p className="error-message">
                            {formErrors.customerName}
                          </p>
                        </Col>

                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="exampleEmail"
                            className="mr-sm-2 cstm-label"
                          >
                            Phone Number<span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            name="text"
                            id="exampleEmail"
                            className="cstm-input"
                            placeholder="Enter Phone Number"
                            value={phoneNumber}
                            onChange={(e) => {
                              setPhoneNumber(e.target.value);
                            }}
                          />
                          <p className="error-message">
                            {formErrors.phoneNumber}
                          </p>
                        </Col>

                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="exampleEmail"
                            className="mr-sm-2 cstm-label"
                          >
                            Email ID<span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="email"
                            name="email"
                            className="cstm-input"
                            id="exampleEmail"
                            placeholder="Enter Email ID"
                            value={emailId}
                            onChange={(e) => {
                              setEmailId(e.target.value);
                            }}
                          />
                          <p className="error-message">{formErrors.email}</p>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="exampleaddress"
                            className="mr-sm-2 cstm-label"
                          >
                            Address<span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="address"
                            name="address"
                            className="cstm-input"
                            id="exampleaddress"
                            placeholder="Enter Customer Address"
                            value={address}
                            onChange={(e) => {
                              setAddress(e.target.value);
                            }}
                          />
                          <p className="error-message">{formErrors.address}</p>
                        </Col>

                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <button
                            className="bs-btn "
                            type="button"
                            onClick={(e) => {
                              validateBanner(e, false);
                            }}
                          >
                            SUBMIT
                          </button>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Container>
                </TabPane>
              </TabContent>
            </Row>
          </div>
          <ToastContainer />
        </Container>
      </section>

      <Modal
        isOpen={modal}
        toggle={customModal}
        size={'lg'}
        centered={true}
        className="bs-modal"
      >
        <ModalHeader toggle={customModal}></ModalHeader>
        <ModalBody toggle={customModal}>
          <p className="title">
            A Customer is already aligned with another Employee,Do you want to
            still continue?
          </p>
        </ModalBody>
        <ModalFooter>
          <button
            onClick={(e) => {
              setIsSubmit(true);
              validateBanner(e, true);
            }}
            className="bs-btn typ-replace min-width margin-right"
          >
            Confirm
          </button>{' '}
          <button
            onClick={() => {
              setIsSubmit(false);
              customModal();
            }}
            className="bs-btn typ-outline min-width"
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </main>
  );
}

export default UpdateCustomer;
