import React, { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { directUrl } from '../../../helpers/Constants';
import { jwtDecode } from 'jwt-decode';

function Home() {
  let navigate = useNavigate();
  useEffect(() => {
    let token = localStorage.getItem('x-access-token');
    
    if (token) {
      let user = jwtDecode(token);
      // user = JSON.parse(user);
      console.log('user', user.role_id, user);
      if (user.role_id === 'admin') {
        navigate(`${directUrl}admin/Home`);
      }
      if (user.role_id === 'user') {
        navigate(`${directUrl}employee/home`);
      }
    } else {
      navigate(`${directUrl}sign-in`);
    }
  });
}

export default Home;
