export const homePageUrl = "/"
// export const homePageUrl = "/projects/glyphertech";
// export const homePageUrl = "/clients/glyphertech";

// http://localhost:3000/auditor/company/getCompany

// http://localhost:3000/auditor/compliancer/register
// export const directUrl = 'https://crm.jktradingoption.com/'
export const directUrl = '/'

export function formatDate(timestamp) {
  const date = new Date(timestamp);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZone: 'Asia/Kolkata',
  };
  return date.toLocaleString('en-IN', options);
}

export function formatDateWithoutTime(timestamp) {
  const date = new Date(timestamp);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'Asia/Kolkata',
  };
  return date.toLocaleString('en-IN', options);
}
export function convertNumber(number) {
  const strNumber = number.toString();
  const firstTwoDigits = strNumber.slice(0, 2);
  const lastTwoDigits = strNumber.slice(-2);
  const maskedPart = '******';

  return `${firstTwoDigits}${maskedPart}${lastTwoDigits}`;
}