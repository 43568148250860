import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Col, Container, Row, Input, Label, Pagination } from 'reactstrap';
import {
  PieChart,
  Pie,
  Sector,
  ResponsiveContainer,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  Line,
  AreaChart,
  Area,
} from 'recharts';

import { Navigate, useNavigate } from 'react-router-dom';
//assets
import company from '../../../assets/image/icons/company.svg';
import activeReport from '../../../assets/image/icons/active-report.svg';
import customer1 from '../../../assets/image/icons/tick.svg';
import branches from '../../../assets/image/icons/branches.svg';
import tick from '../../../assets/image/icons/tick.svg';
import {
  directUrl,
  formatDateWithoutTime,
  homePageUrl,
} from '../../../helpers/Constants';
import { requestApi } from '../../../request-api';
import moment from 'moment/moment';
import DataTable from 'react-data-table-component';
import './ExcelSheet.css'; // Import CSS file for styling
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { dataToXLS } from '../../../helpers/dataToXLS';
function Dashboard() {
  const [user, setUser] = useState({});
  let [selectedDate, setSelectedDate] = useState(null);
  let [selectedDate2, setSelectedDate2] = useState(new Date());
  let [selectedDate3, setSelectedDate3] = useState(new Date());
  let [selectedDate4, setSelectedDate4] = useState(new Date());
  const [reportData, setReportData] = useState([]);
  const [yearlyDetails, setYearlyDetails] = useState({});
  const [complianceFilter, setComplianceFilter] = useState([]);
  const [companyComplianceRoute, setCompanyComplianceRoute] = useState([]);
  const [totalPayments, settotalPayments] = useState([]);
  const [totalBranch, setTotalBranch] = useState([]);
  const [totalClose, setTotalClose] = useState([]);
  const [totalEmployeesCount, setTotalEmployeesCount] = useState([]);
  const [totalCompiledWithException, setTotalCompiledWithException] = useState(
    []
  );

  const [totalGross, setTotalGross] = useState(0);
  const [totalGST, setTotalGST] = useState(0);
  const [totalNet, setTotalNet] = useState(0);
  const [totalCust, setTotalCust] = useState(0);
  const [total50, setTotal50] = useState(0);
  const [totalAccount, setTotalAccount] = useState(0);
  const [custTrends, setCustTrendz] = useState([]);
  const [versionLead, setVersionLead] = useState([]);
  const [yearlySales, setYearlySales] = useState([]);
  const [ftData, setFtData] = useState([]);
  const [payUpdateData, setPayUpdateData] = useState([]);
  const [holdingData, setHoldingData] = useState([]);
  const [mpsData, setMpsData] = useState([
    { A: '', B: '', C: '', D: '' },
    { A: '', B: 'Sales', C: 0, D: '' },
    { A: '', B: '18%GST', C: 0, D: '' },
    { A: '', B: 'SEBI', C: 0, D: '' },
    { A: '', B: 'Salary+INC', C: 0, D: '' },

    { A: '', B: 'Expenses', C: 0, D: '' },
    { A: '', B: 'Inhand Amount', C: 0, D: '' },
    { A: '', B: '', C: '', D: '' },
    { A: '', B: 'Junaid', C: 0, D: '' },
    { A: '', B: 'Extra', C: 0, D: '' },
    { A: '', B: 'Mehdi', C: 0, D: '' },
    { A: '', B: 'Hashim', C: 0, D: '' },
    { A: '', B: 'Arbaz', C: 0, D: '' },
  ]);
  const [versionData, setVersionData] = useState([]);
  const [upComingDue, setUpComingDue] = useState([]);
  const [dueDateCompliance, setDueDateCompliance] = useState([]);
  const [search, setSearch] = useState('');
  const [search2, setSearch2] = useState('');
  let navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  const [formattedVersion, setFormattedVersion] = useState([]);
  const [dueStatisticCompanyFilter, setDueStatisticCompanyFilter] = useState(
    []
  );
  const [dueStatistic, setDueStatistic] = useState([]);
  const [dueSearch, setDueSearch] = useState('');
  const [overAllStatics, setOverAllStatics] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [filteredSearch, setFilteredSearch] = useState([]);
  const [filteredSearch2, setFilteredSearch2] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  // Inside your component
  const pageSize = 10; // Number of items per page

  // Define state variables for pagination
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the start and end indices based on the current page and page size
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, ftData.length);

  // Function to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  function getPreviousMonthDate() {
    // Get today's date
    let today = new Date();

    // Set the date to the first day of the current month
    today.setDate(1);

    // Decrement the month by 1 to get the previous month
    today.setMonth(today.getMonth());

    // Return the date of the last day of the previous month
    return new Date(today.getFullYear(), today.getMonth() + 1, 0);
  }
  function formatNumber(num) {
    num = parseFloat(num)?.toFixed(2);
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1) + 'B';
    }
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M';
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'K';
    }
    return parseInt(num);
  }

  const columns = [
    {
      name: 'User Name',
      selector: (row, index) => (
        <td style={{ fontWeight: 'bold', backgroundColor: '#f8f9fa' }}>
          {row.user_name}
        </td>
      ),
      sortable: true,
    },

    {
      name: 'Count',
      selector: (row) => (
        <td style={{ fontWeight: 'bold', backgroundColor: '#f8f9fa' }}>
          {row.count}
        </td>
      ),
      sortable: true,
    },
    {
      name: 'Remark',
      selector: (row) => row.remark || 'No Remark',
      sortable: true,
    },
    {
      name: 'Remark2',
      selector: (row) => row.remark2 || '-',
      sortable: true,
    },
    {
      name: 'Remark3',
      selector: (row) => row.remark3 || '-',
      sortable: true,
    },
    {
      name: 'Date',
      selector: (row) => formatDateWithoutTime(row.date),
      sortable: true,
    },
  ];
  const columns2 = [
    {
      name: 'User Name',
      selector: (row, index) => (
        <td style={{ fontWeight: 'bold', backgroundColor: '#f8f9fa' }}>
          {row.username}
        </td>
      ),
      sortable: true,
    },

    {
      name: 'Payment',
      selector: (row) => (
        <td style={{ fontWeight: 'bold', backgroundColor: '#f8f9fa' }}>
          {row.payment}
        </td>
      ),
      sortable: true,
    },
    {
      name: 'Remark',
      selector: (row) => row.remark || 'No Remark',
      sortable: true,
    },
    {
      name: 'Remark2',
      selector: (row) => row.remark2 || '-',
      sortable: true,
    },
    {
      name: 'Remark3',
      selector: (row) => row.remark3 || '-',
      sortable: true,
    },
    {
      name: 'Date',
      selector: (row) => formatDateWithoutTime(row.date),
      sortable: true,
    },
  ];
  const columns3 = [
    {
      name: 'User Name',
      selector: (row, index) => (
        <td style={{ fontWeight: 'bold', backgroundColor: '#f8f9fa' }}>
          {row.username}
        </td>
      ),
      sortable: true,
    },
    {
      name: 'Date',
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: 'Level',
      selector: (row) => row.level,
    },
    {
      name: 'QTY',
      selector: (row) => (
        <td style={{ fontWeight: 'bold', backgroundColor: '#f8f9fa' }}>
          {row.qty}
        </td>
      ),
      sortable: true,
    },
    {
      name: 'Lots',
      selector: (row) => (
        <td style={{ fontWeight: 'bold', backgroundColor: '#f8f9fa' }}>
          {row.lots}
        </td>
      ),
      sortable: true,
    },
    {
      name: 'Buying Price',
      selector: (row) => (
        <td style={{ fontWeight: 'bold', backgroundColor: '#f8f9fa' }}>
          {row.buy_price}
        </td>
      ),
      sortable: true,
    },
    {
      name: 'Remark',
      selector: (row) => row.remark || '-',
      sortable: true,
    },
    {
      name: 'Remark2',
      selector: (row) => row.remark2 || '-',
      sortable: true,
    },
    {
      name: 'Remark3',
      selector: (row) => row.remark3 || '-',
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => (
        <div
          style={{
            backgroundColor: row.active == '1' ? 'green' : 'red',
            color: 'white',
            padding: '5px 10px',
            borderRadius: '10px',
          }}
        >
          <select
            // onChange={(e) => handleStatusChange(row.id, e.target.value)}
            defaultValue={row.active}
            disabled
            style={{
              backgroundColor: 'transparent',
              color: 'white',
              border: 'none',
              outline: 'none',
              cursor: 'pointer',
              width: '100%',
              textAlign: 'center',
            }}
          >
            <option value="0">Deactive</option>
            <option value="1">Active</option>
          </select>
        </div>
      ),
    },
  ];

  const data = [
    { name: 'Gross', value: totalGross, fill: 'green' },
    { name: 'GST', value: totalGST, fill: 'blue' },
    { name: 'Net', value: totalNet, fill: 'purple' },
  ];
  const data2 = [
    { name: 'Gross Amount', value: totalGross },
    { name: 'Net Amount', value: totalNet },
  ];
  const COLORS = [
    '#ff6384',
    '#36a2eb',
    '#ffce56',
    '#ff9f40',
    '#4bc0c0',
    '#9966ff',
    '#ffcd56',
    '#c9cbcf',
    '#33B8FF',
    '#FF8C8C',
    '#8CFF8C',
    '#8C33FF',
    '#8C33FF',
    '#33FF8C',
    '#FFB833',
    '#FF33B8',
    '#33FFB8',
    '#B833FF',
    '#33B8FF',
    '#B8FF33',
    '#B833FF',
    '#338CFF',
    '#8CFF33',
    '#FF338C',
    '#338CFF',
    '#33B8FF',
    '#FF8C8C',
    '#8CFF8C',
    '#8C33FF',
    '#FF8C33',
    '#33FF8C',
    '#8C33FF',
    '#FF5733',
    '#33FF57',
  ];

  const colorBar = [
    '#C0392B',
    '#AF7AC5',
    '#5499C7',
    '#48C9B0',
    '#6666FF',
    '#CCCC99',
    '#CCCCFF',
    '#FFCCFF',
    '#FFCC33',
    '#FF9933',
    'purple',
  ];
  const getGross = async (date) => {
    setLoading(true);
    requestApi(
      '/v1/mps/getMPS',
      {
        method: 'post',
        body: JSON.stringify({
          month: date,
        }),
      },
      navigate
    )
      .then((data) => {
        console.log(data);
        if (data.statusCode === 200) {
          setLoading(false);
          console.log(data.data[0]);

          // successToast('Successfully created branch');
          const mpsDataValue = data.data[0];
          const partners = JSON.parse(mpsDataValue.partners);
          setMpsData([
            { A: '', B: '', C: '', D: '' },
            {
              A: '',
              B: 'Sales',
              C: mpsDataValue?.gross_amt?.toFixed(2),
              D: '',
            },
            { A: '', B: '18%GST', C: mpsDataValue?.gst_amt?.toFixed(2), D: '' },
            { A: '', B: 'SEBI', C: mpsDataValue?.sebi?.toFixed(2), D: '' },
            {
              A: '',
              B: 'Salary+INC',
              C: mpsDataValue?.salary?.toFixed(2),
              D: '',
            },

            {
              A: '',
              B: 'Expenses',
              C: mpsDataValue?.expenses?.toFixed(2),
              D: '',
            },
            {
              A: '',
              B: 'Inhand Amount',
              C: mpsDataValue?.inhand?.toFixed(2),
              D: '',
            },
            { A: '', B: '', C: '', D: '' },
            {
              A: '',
              B: 'Junaid',
              C:
                JSON.parse(mpsDataValue.partners)[0].amount +
                `(${JSON.parse(mpsDataValue.partners)[0].percent}%)`,
              D: '',
            },
            {
              A: '',
              B: 'Extra',
              C:
                JSON.parse(mpsDataValue.partners)[1].amount +
                `(${JSON.parse(mpsDataValue.partners)[1].percent}%)`,
              D: '',
            },
            {
              A: '',
              B: 'Mehdi',
              C:
                JSON.parse(mpsDataValue.partners)[2].amount +
                `(${JSON.parse(mpsDataValue.partners)[2].percent}%)`,
              D: '',
            },
            {
              A: '',
              B: 'Hashim',
              C:
                JSON.parse(mpsDataValue.partners)[3].amount +
                `(${JSON.parse(mpsDataValue.partners)[3].percent}%)`,
              D: '',
            },
            {
              A: '',
              B: 'Arbaz',
              C:
                JSON.parse(mpsDataValue.partners)[4].amount +
                `(${JSON.parse(mpsDataValue.partners)[4].percent}%)`,
              D: '',
            },
          ]);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error?.statusCode === 404) {
          setMpsData([
            { A: '', B: '', C: '', D: '' },
            { A: '', B: 'Sales', C: 0, D: '' },
            { A: '', B: '18%GST', C: 0, D: '' },
            { A: '', B: 'SEBI', C: 0, D: '' },
            { A: '', B: 'Salary+INC', C: 0, D: '' },
            { A: '', B: 'Expenses', C: 0, D: '' },
            { A: '', B: 'Inhand Amount', C: 0, D: '' },

            { A: '', B: '', C: '', D: '' },
            { A: '', B: 'Junaid', C: 0, D: '' },
            { A: '', B: 'Extra', C: 0, D: '' },
            { A: '', B: 'Mehdi', C: 0, D: '' },
            { A: '', B: 'Hashim', C: 0, D: '' },
            { A: '', B: 'Arbaz', C: 0, D: '' },
          ]);
        }
        console.log(error);
      });
  };
  const getFt = async (date) => {
    setLoading(true);
    requestApi(
      '/v1/get-ft-count-admin',
      {
        method: 'post',
        body: JSON.stringify({
          specificDate: date,
        }),
      },
      navigate
    )
      .then((data) => {
        console.log(data);
        if (data.statusCode === 200) {
          setLoading(false);
          console.log(data.data[0]);

          // successToast('Successfully created branch');
          const mpsDataValue = data.data;

          setFtData(mpsDataValue);
          setFilteredSearch(mpsDataValue);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error?.statusCode === 404) {
          setFtData([]);
          setFilteredSearch([]);
        }
        console.log(error);
      });
  };
  const getPayUpdate = async (date) => {
    setLoading(true);
    requestApi(
      '/v1/get-payment-update-admin',
      {
        method: 'post',
        body: JSON.stringify({
          specificDate: date,
        }),
      },
      navigate
    )
      .then((data) => {
        console.log(data);
        if (data.statusCode === 200) {
          setLoading(false);
          console.log(data.data[0]);

          // successToast('Successfully created branch');
          const mpsDataValue = data.data;

          setPayUpdateData(mpsDataValue);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error?.statusCode === 404) {
          setPayUpdateData([]);
        }
        console.log(error);
      });
  };
  const getHolding = async (date) => {
    setLoading(true);
    requestApi(
      '/v1/get-holding-admin',
      {
        method: 'post',
        body: JSON.stringify({
          specificDate: date,
        }),
      },
      navigate
    )
      .then((data) => {
        console.log(data);
        if (data.statusCode === 200) {
          setLoading(false);
          console.log(data.data[0]);

          // successToast('Successfully created branch');
          const mpsDataValue = data.data;

          setHoldingData(mpsDataValue);
          setFilteredSearch2(mpsDataValue);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error?.statusCode === 404) {
          setHoldingData([]);
          setFilteredSearch2([]);
        }
        console.log(error);
      });
  };

  const dummy = (e) => {
    if (e.allSelected === true) {
      setDownloadData([...e.selectedRows]);
    } else if (e.allSelected === false && e.selectedCount === 0) {
      setDownloadData([]);
    } else if (e.selectedCount > 0) {
      setDownloadData([...e.selectedRows]);
    } else if (e.selectedCount === 0) {
      setDownloadData([]);
    }

    if (e.selectedCount === 0) {
      setIsChecked(false);
    } else {
      setIsChecked(true);
    }
  };

  const barData = overAllStatics;
  const handleDateChange = (date) => {
    console.log(new Date(date));
    setSelectedDate(new Date(date));
    getGross(new Date(date));
  };
  const handleDateChange2 = (date) => {
    console.log(new Date(date));
    setSelectedDate2(new Date(date));
  };
  const handleDateChange3 = (date) => {
    console.log(new Date(date));
    setSelectedDate3(new Date(date));
  };
  const handleDateChange4 = (date) => {
    console.log(new Date(date));
    setSelectedDate4(new Date(date));
  };
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  const downloadtransaction = (name, downloadData) => {
    dataToXLS(downloadData, name + new Date());
  };
  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  let [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const getUserData = () => {
    requestApi('/v1/get-admin-dashboard', {
      method: 'get',
    })
      .then((data) => {
        console.log('User data set -->', data.data);
        setTotalBranch(data.data.branch_count);
        setTotalGross(data.data.total_gross);
        setTotalNet(data.data.total_net);
        setTotalGST(data.data.total_gst);
        settotalPayments(data.data.payments_count);
        setTotalEmployeesCount(data.data.employee_count);
        setYearlySales(data.data.overallsales);
        setYearlyDetails(data.data.yearlyDetails);
        setTotalCust(data.data.customer_count);
        setCustTrendz(data.data.customerTrends);
        setTotalAccount(data.data.is_cust_handling_count);
        setTotal50(data.data.is50_count);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getVersion = async () => {
    setLoading(true);
    requestApi(
      '/v1/leaddata/get-version ',
      {
        method: 'get',
      },
      navigate
    )
      .then((data) => {
        console.log(data);
        if (data.statusCode === 200) {
          // successToast('Successfully created branch');
          setVersionLead(data.data);
          console.log(data.data, 'd');
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        // errorToast(error.message);
        console.log(error);
      });
  };

  const getVersionData = async (id) => {
    setLoading(true);
    requestApi(
      '/v1/leaddata/get-version-data ',
      {
        method: 'post',
        body: JSON.stringify({
          formattedVersion: id,
        }),
      },
      navigate
    )
      .then((data) => {
        console.log(data);
        if (data.statusCode === 200) {
          // successToast('Successfully created branch');
          setVersionData(data.data);
          console.log(data.data, 'd');
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        // errorToast(error.message);
        console.log(error);
      });
  };

  useEffect(() => {
    if (search == 'empty') {
      return setComplianceFilter(reportData);
    }
    const result = reportData.filter((comp) => {
      return (
        comp.report.legislation[0].company_name
          .toLowerCase()
          .match(search.toLowerCase()) ||
        comp.report.report_id.toLowerCase().match(search.toLowerCase())
      );
    });
    setComplianceFilter(result);
  }, [search]);
  useEffect(() => {
    if (dueSearch === 'empty') {
      return setDueStatisticCompanyFilter(dueStatistic);
    }
    const result = dueStatistic.filter((comp) => {
      return comp.company_name.toLowerCase().match(dueSearch.toLowerCase());
    });
    setDueStatisticCompanyFilter(result);
  }, [dueSearch]);
  useEffect(() => {
    getUserData();
    getVersion();
    getVersionData('');
  }, []);

  useEffect(() => {
    if (search2 === 'empty') {
      return setFilteredSearch2(holdingData);
    }
    const result = holdingData.filter((c) => {
      return (
        c.level?.toLowerCase().match(search2.toLowerCase()) ||
        c.username?.toString()?.toLowerCase().match(search2.toLowerCase()) ||
        c.remark?.toLowerCase().match(search2.toLowerCase())
      );
    });
    setFilteredSearch2(result);
  }, [search2]);

  useEffect(() => {
    const date = selectedDate ?? getPreviousMonthDate();
    getGross(date);
  }, [selectedDate]);
  useEffect(() => {
    const date = selectedDate2 ?? getPreviousMonthDate();
    getFt(date);
  }, [selectedDate2]);
  useEffect(() => {
    const date = selectedDate3 ?? getPreviousMonthDate();
    getPayUpdate(date);
  }, [selectedDate3]);
  useEffect(() => {
    const date = selectedDate4 ?? getPreviousMonthDate();
    getHolding(date);
  }, [selectedDate4]);

  // Function to calculate the sum total
  const calculateSumTotal = () => {
    return ftData.reduce((total, item) => total + parseInt(item.count), 0);
  };
  const calculateSumTotal2 = () => {
    return payUpdateData.reduce(
      (total, item) => total + parseInt(item.payment),
      0
    );
  };
  const calculateSumTotal3 = () => {
    return holdingData.reduce((total, item) => total + parseInt(item.qty), 0);
  };
  const calculateActiveSumTotal = () => {
    let total = 0;
    holdingData.map((item) => {
      if (item.active == '1') {
        total += parseInt(item.qty);
      }
    });
    return total;
  };
  const calculateActiveLotsTotal3 = () => {
    let total = 0;
    holdingData.map((item) => {
      if (item.active == '1') {
        total += parseInt(item.lots);
      }
    });
    return total;
  };
  const calculateSumTotal4 = () => {
    return holdingData.reduce((total, item) => total + parseInt(item.lots), 0);
  };
  return (
    <main>
      <section className="lyt-section">
        {loading ? (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        ) : null}
        <Container fluid className="typ-container">
          {/* <h1 className="bs-heading">Hello {user.name}</h1> */}

          <Row>
            <Container fluid className="typ-container">
              <div className="bs-card">
                <h3 className="heading">Quantity Holdings Update</h3>

                <Row>
                  {isChecked ? (
                    <Col xs={12}>
                      <div className="bs-action-btn">
                        {/* <div className="button-group">
                          <a
                            className="action-btn"
                            // onClick={downloadtransaction}
                          >
                            <span className="icon icon-download"></span>
                          </a>
                        </div> */}
                      </div>
                    </Col>
                  ) : (
                    <Col xs={12}>
                      <div className="bs-table">
                        <div className="main-header">
                          <form className="bs-form">
                            <div className="search-btn">
                              <Input
                                type="text"
                                placeholder="Search..."
                                onChange={(e) => setSearch2(e.target.value)}
                              />
                              <i className="icon icon-search"></i>
                            </div>
                            <div className="dropdown-bx report-date">
                              <label className="form-label">Start Date: </label>
                              <DatePicker
                                selected={selectedDate4}
                                onChange={handleDateChange4}
                                placeholderText="Month"
                                className="typ-date"
                                style={{
                                  width: '100%',
                                  padding: '10px',
                                  border: '1px solid #ccc',
                                  borderRadius: '5px',
                                  fontSize: '16px',
                                }}
                              />
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                gap: '10px', // Add gap between items for better spacing
                              }}
                            >
                              <div
                                className="dropdown-bx report-date"
                                style={{ flex: '1 1 auto', color: 'blue' }}
                              >
                                Total QTY: {calculateSumTotal3()}
                              </div>
                            </div>

                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                gap: '10px', // Add gap between items for better spacing
                              }}
                            >
                              <div
                                className="dropdown-bx report-date"
                                style={{ flex: '1 1 auto', color: 'blue' }}
                              >
                                Total Lots: {calculateSumTotal4()}
                              </div>
                              <div className="left-button">
                                <button
                                  className="bs-btn btn-submit typ-create"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setDownloadData(filteredSearch2);
                                    downloadtransaction(
                                      'Quantity Holdings Update',
                                      filteredSearch2
                                    );
                                  }}
                                >
                                  <span class="icon icon-download"></span>
                                  Download
                                </button>
                              </div>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                gap: '10px', // Add gap between items for better spacing
                              }}
                            >
                              <div
                                className="dropdown-bx report-date"
                                style={{ flex: '1 1 auto', color: 'green' }}
                              >
                                Active QTY: {calculateActiveSumTotal()}
                              </div>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                gap: '10px', // Add gap between items for better spacing
                              }}
                            >
                              <div
                                className="dropdown-bx report-date"
                                style={{ flex: '1 1 auto', color: 'green' }}
                              >
                                Active LOTS: {calculateActiveLotsTotal3()}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </Col>
                  )}
                  <Col xs={12}>
                    <div
                      className="bs-table typ-datable overflow-on"
                      style={{ borderRadius: '10px', border: '1px solid #ddd' }}
                    >
                      <DataTable
                        columns={columns3}
                        data={filteredSearch2}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight="450px"
                        // selectableRows
                        // onSelectedRowsChange={dummy}
                        // selectableRowsHighlight
                        highlightOnHover
                        style={{ backgroundColor: '#fff' }} // Apply background color to table body
                        paginationPerPage={50} // Display 50 rows per page
                        paginationRowsPerPageOptions={[50, 60, 80, 100]} // Set default row size to 50
                      />
                    </div>
                    <style>
                      {`
      .rdt_TableHead {
        background-color: #f2f2f2;
      }

      .rdt_TableRow:hover {
        background-color: #f9f9f9;
      }

      .rdt_Pagination {
        display: flex;
        justify-content: center;
        margin-top: 10px;
      }

      .rdt_PaginationPrev,
      .rdt_PaginationNext {
        margin: 0 5px;
        padding: 5px 10px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
      }

      .rdt_PaginationPrev:hover,
      .rdt_PaginationNext:hover {
        background-color: #0056b3;
      }

      .rdt_PaginationPage {
        margin: 0 5px;
      }

      .rdt_PaginationPageActive {
        font-weight: bold;
        color: #007bff;
      }
    `}
                    </style>
                  </Col>
                </Row>
              </div>
            </Container>
            <Container fluid className="typ-container">
              <div className="bs-card">
                <h3 className="heading">FT Count</h3>
                <Row>
                  {isChecked ? (
                    <Col xs={12}>
                      <div className="bs-action-btn">
                        {/* <div className="button-group">
                          <a
                            className="action-btn"
                            // onClick={downloadtransaction}
                          >
                            <span className="icon icon-download"></span>
                          </a>
                        </div> */}
                      </div>
                    </Col>
                  ) : (
                    <Col xs={12}>
                      <div className="bs-table">
                        <div className="main-header">
                          <form className="bs-form">
                            {/* <div className="search-btn">
                              <Input
                                type="text"
                                placeholder="Search..."
                                onChange={(e) => setSearch(e.target.value)}
                              />
                              <i className="icon icon-search"></i>
                            </div> */}
                            <div className="dropdown-bx report-date">
                              <label className="form-label">Start Date: </label>
                              <DatePicker
                                selected={selectedDate2}
                                onChange={handleDateChange2}
                                placeholderText="Month"
                                className="typ-date"
                                style={{
                                  width: '100%',
                                  padding: '10px',
                                  border: '1px solid #ccc',
                                  borderRadius: '5px',
                                  fontSize: '16px',
                                }}
                              />
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                gap: '10px', // Add gap between items for better spacing
                              }}
                            >
                              <div
                                className="dropdown-bx report-date"
                                style={{ flex: '1 1 auto', color: 'green' }}
                              >
                                Total count: {calculateSumTotal()}
                              </div>
                              <div className="left-button">
                                <button
                                  className="bs-btn btn-submit typ-create"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setDownloadData(filteredSearch);
                                    downloadtransaction(
                                      'FT Count',
                                      filteredSearch
                                    );
                                  }}
                                >
                                  <span class="icon icon-download"></span>
                                  Download
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </Col>
                  )}
                  <Col xs={12}>
                    <div
                      className="bs-table typ-datable overflow-on"
                      style={{ borderRadius: '10px', border: '1px solid #ddd' }}
                    >
                      <DataTable
                        columns={columns}
                        data={filteredSearch}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight="450px"
                        // selectableRows
                        // onSelectedRowsChange={dummy}
                        // selectableRowsHighlight
                        highlightOnHover
                        style={{ backgroundColor: '#fff' }} // Apply background color to table body
                        paginationPerPage={50} // Display 50 rows per page
                        paginationRowsPerPageOptions={[50, 60, 80, 100]} // Set default row size to 50
                      />
                    </div>
                    <style>
                      {`
      .rdt_TableHead {
        background-color: #f2f2f2;
      }

      .rdt_TableRow:hover {
        background-color: #f9f9f9;
      }

      .rdt_Pagination {
        display: flex;
        justify-content: center;
        margin-top: 10px;
      }

      .rdt_PaginationPrev,
      .rdt_PaginationNext {
        margin: 0 5px;
        padding: 5px 10px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
      }

      .rdt_PaginationPrev:hover,
      .rdt_PaginationNext:hover {
        background-color: #0056b3;
      }

      .rdt_PaginationPage {
        margin: 0 5px;
      }

      .rdt_PaginationPageActive {
        font-weight: bold;
        color: #007bff;
      }
    `}
                    </style>
                  </Col>
                </Row>
              </div>
            </Container>

            <Container fluid className="typ-container">
              <div className="bs-card">
                <h3 className="heading">Payment Updates</h3>

                <Row>
                  {isChecked ? (
                    <Col xs={12}>
                      <div className="bs-action-btn">
                        {/* <div className="button-group">
                          <a
                            className="action-btn"
                            // onClick={downloadtransaction}
                          >
                            <span className="icon icon-download"></span>
                          </a>
                        </div> */}
                      </div>
                    </Col>
                  ) : (
                    <Col xs={12}>
                      <div className="bs-table">
                        <div className="main-header">
                          <form className="bs-form">
                            {/* <div className="search-btn">
                              <Input
                                type="text"
                                placeholder="Search..."
                                onChange={(e) => setSearch(e.target.value)}
                              />
                              <i className="icon icon-search"></i>
                            </div> */}
                            <div className="dropdown-bx report-date">
                              <label className="form-label">Start Date: </label>
                              <DatePicker
                                selected={selectedDate3}
                                onChange={handleDateChange3}
                                placeholderText="Month"
                                className="typ-date"
                                style={{
                                  width: '100%',
                                  padding: '10px',
                                  border: '1px solid #ccc',
                                  borderRadius: '5px',
                                  fontSize: '16px',
                                }}
                              />
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                gap: '10px', // Add gap between items for better spacing
                              }}
                            >
                              <div
                                className="dropdown-bx report-date"
                                style={{ flex: '1 1 auto', color: 'green' }}
                              >
                                Total Payment: {calculateSumTotal2()}
                              </div>
                              <div className="left-button">
                                <button
                                  className="bs-btn btn-submit typ-create"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setDownloadData(payUpdateData);
                                    downloadtransaction(
                                      'Payment Update',
                                      payUpdateData
                                    );
                                  }}
                                >
                                  <span class="icon icon-download"></span>
                                  Download
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </Col>
                  )}
                  <Col xs={12}>
                    <div
                      className="bs-table typ-datable overflow-on"
                      style={{ borderRadius: '10px', border: '1px solid #ddd' }}
                    >
                      <DataTable
                        columns={columns2}
                        data={payUpdateData}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight="450px"
                        // selectableRows
                        // onSelectedRowsChange={dummy}
                        // selectableRowsHighlight
                        highlightOnHover
                        style={{ backgroundColor: '#fff' }} // Apply background color to table body
                        paginationPerPage={50} // Display 50 rows per page
                        paginationRowsPerPageOptions={[50, 60, 80, 100]} // Set default row size to 50
                      />
                    </div>
                    <style>
                      {`
      .rdt_TableHead {
        background-color: #f2f2f2;
      }

      .rdt_TableRow:hover {
        background-color: #f9f9f9;
      }

      .rdt_Pagination {
        display: flex;
        justify-content: center;
        margin-top: 10px;
      }

      .rdt_PaginationPrev,
      .rdt_PaginationNext {
        margin: 0 5px;
        padding: 5px 10px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
      }

      .rdt_PaginationPrev:hover,
      .rdt_PaginationNext:hover {
        background-color: #0056b3;
      }

      .rdt_PaginationPage {
        margin: 0 5px;
      }

      .rdt_PaginationPageActive {
        font-weight: bold;
        color: #007bff;
      }
    `}
                    </style>
                  </Col>
                </Row>
              </div>
            </Container>

            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
              <div className="bs-card">
                <h2 className="heading">MONTLY DETAILS</h2>
                <div className="overview-div">
                  <div className="text-center">
                    <p className="count">{formatNumber(totalGross)}</p>
                    <p className="bs-status-txt bold">Gross Amount</p>
                  </div>
                  <div className="text-center">
                    <p className="count">{formatNumber(totalGST)}</p>
                    <p className="bs-status-txt typ-red bold">GST(18%)</p>
                  </div>
                  <div className="text-center">
                    <p className="count">{formatNumber(totalNet)}</p>
                    <p className="bs-status-txt typ-blue bold">Net Amount</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
              <div className="bs-card">
                <h2 className="heading ">OVERVIEW</h2>
                <div className="overview-div typ-round">
                  <div className="inner-view">
                    <div className="img-area">
                      <img src={company} alt="Total Employees" />
                    </div>
                    <div>
                      <p className="count">{totalEmployeesCount}</p>
                      <p className="sub-head">Total Employees</p>
                    </div>
                  </div>
                  <div className="inner-view">
                    <div className="img-area typ-blue">
                      <img src={branches} alt="Total Branches" />
                    </div>
                    <div>
                      <p className="count">{totalBranch}</p>
                      <p className="sub-head">Total Branches</p>
                    </div>
                  </div>
                  <div className="inner-view">
                    <div className="img-area typ-orange">
                      <img src={customer1} alt="Active Reports" />
                    </div>

                    <div>
                      <p className="count">{totalCust}</p>
                      <p className="sub-head">Total Customers</p>
                    </div>
                  </div>
                  <div className="inner-view">
                    <div className="img-area typ-orange">
                      <img src={activeReport} alt="Active Reports" />
                    </div>

                    <div>
                      <p className="count">{totalPayments}</p>
                      <p className="sub-head">Total Payments</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div className="bs-card">
                <h2 className="heading">YEARLY DETAILS</h2>
                <div className="overview-div">
                  <div className="text-center">
                    <p className="count">
                      {formatNumber(yearlyDetails?.transactions_count || 0)}
                    </p>
                    <p className="bs-status-txt bold">Total Transactions</p>
                  </div>
                  <div className="text-center">
                    <p className="count">{formatNumber(total50)}</p>
                    <p className="bs-status-txt typ-blue bold">
                      50 Transactions
                    </p>
                  </div>
                  <div className="text-center">
                    <p className="count">{formatNumber(totalAccount)}</p>
                    <p className="bs-status-txt typ-blue bold">
                      Account Handling
                    </p>
                  </div>
                  <div className="text-center">
                    <p className="count">
                      {formatNumber(yearlyDetails.total_gross || 0)}
                    </p>
                    <p className="bs-status-txt typ-red bold">Total Amount</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <div className="bs-card mt-0">
              <h2 className="heading">CUSTOMER COUNT</h2>
              <div className="align-items-center mob-flex-colum">
                <div className="chart-area">
                  <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                      width={500}
                      height={400}
                      data={custTrends}
                      margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month_name" />
                      <YAxis />
                      <Tooltip />
                      <Area
                        type="monotone"
                        dataKey="customer_count"
                        stroke="#6EE167"
                        fill="#6EE167"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <div className="bs-card mt-0">
              <h2 className="heading">YEARLY SALES</h2>
              <div className="align-items-center mob-flex-colum">
                <div className="chart-area">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                      width={730}
                      height={250}
                      data={yearlySales}
                      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="total_gross"
                        stroke="#6EE167"
                      />
                      {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </Col>

          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div className="bs-card1">
                <div className="main-header">
                  <form className="bs-form">
                    <div className="dropdown-bx report-date">
                      <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        dateFormat="MM/yyyy" // Format to display only month and year
                        showMonthYearPicker // Show only month and year picker
                        placeholderText="Month"
                        className="typ-date"
                        style={{
                          width: '100%', // Make the date picker input full-width
                          padding: '10px', // Add padding for better spacing
                          border: '1px solid #ccc', // Add a border
                          borderRadius: '5px', // Add border radius for rounded corners
                          fontSize: '16px', // Increase font size for better readability
                        }}
                      />
                    </div>
                  </form>
                </div>
                <h2 className="heading">YEARLY DETAILS</h2>
                <div className="excel-sheet">
                  {/* Create rows */}
                  {mpsData.map((rowData, rowIndex) => (
                    <div className="excel-row" key={rowIndex}>
                      {Object.values(rowData).map((cellData, colIndex) => (
                        <div className="excel-cell" key={colIndex}>
                          {cellData}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
          <Row justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div
                style={{
                  backgroundColor: '#ffffff',
                  padding: '20px',
                  borderRadius: '8px',
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '20px',
                  }}
                >
                  <h2
                    style={{
                      fontSize: '24px',
                      marginBottom: '0',
                      color: '#333',
                    }}
                  >
                    OVERALL Lead Data Disposition
                  </h2>
                  <form>
                    <div style={{ display: 'inline-block' }}>
                      <label
                        style={{
                          fontWeight: 'bold',
                          marginRight: '10px',
                          color: '#666',
                        }}
                      >
                        Version:
                      </label>
                      <Input
                        type="select"
                        name="select"
                        id="exampleSelect"
                        onChange={(e) => getVersionData(e.target.value)}
                        style={{
                          padding: '5px 10px',
                          borderRadius: '4px',
                          border: '1px solid #ccc',
                        }}
                      >
                        <option value={''}>Select Version</option>
                        {versionLead.map((comp, index) => (
                          <option key={index} value={comp.version}>
                            {comp.version}
                          </option>
                        ))}
                      </Input>
                    </div>
                  </form>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    gap: '20px',
                  }}
                >
                  {Object.entries(versionData).map(([version, dispTypes]) => (
                    <div
                      key={version}
                      style={{
                        width: 'calc(50% - 20px)', // Increased width to make pie chart larger
                        padding: '10px',
                        backgroundColor: '#fafafa',
                        borderRadius: '8px',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                        transition: 'transform 0.3s ease',
                      }}
                    >
                      <h3
                        style={{
                          fontSize: '20px',
                          marginBottom: '10px',
                          color: '#444',
                        }}
                      >
                        {version}
                      </h3>
                      <div
                        style={{
                          position: 'relative',
                          overflow: 'hidden',
                          borderRadius: '8px',
                          backgroundColor: '#fff',
                          padding: '10px',
                        }}
                      >
                        <ResponsiveContainer width="100%" height={400}>
                          {' '}
                          {/* Increased height to make pie chart larger */}
                          <PieChart>
                            <Pie
                              data={dispTypes}
                              cx="50%"
                              cy="50%"
                              labelLine={false}
                              label={renderCustomizedLabel}
                              outerRadius={120} // Increased outer radius to make pie chart larger
                              fill="#8884d8"
                              dataKey="value"
                            >
                              {dispTypes.map((entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                />
                              ))}
                            </Pie>
                            <Tooltip
                              formatter={(value, name, props) => [
                                value,
                                props.payload.name,
                              ]}
                            />
                          </PieChart>
                        </ResponsiveContainer>
                        <div
                          style={{
                            marginTop: '10px',
                            textAlign: 'left',
                            fontSize: '14px',
                            color: '#333',
                          }}
                        >
                          {[...dispTypes]
                            .sort((a, b) => b.value - a.value)
                            .map((entry, index) => (
                              <div key={index} style={{ marginBottom: '5px' }}>
                                <span
                                  style={{
                                    display: 'inline-block',
                                    width: '12px',
                                    height: '12px',
                                    backgroundColor:
                                      COLORS[
                                        dispTypes.findIndex(
                                          (dt) => dt.name === entry.name
                                        ) % COLORS.length
                                      ],
                                    marginRight: '8px',
                                    borderRadius: '2px',
                                  }}
                                />
                                {entry.name}: {entry.value}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
}

export default Dashboard;
