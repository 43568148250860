import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  logout,
  setOnlineUser,
  setSocketConnection,
  setUser,
} from '../../../redux/userSlice';
import Sidebar from '../../components/Sidebar';
import logo from '../../../assets/image/logo_bck.png';
import io from 'socket.io-client';
import { requestApi } from '../../../request-api';
import { API_URL } from '../../../config';

const ChatHome = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const basePath = location.pathname === '/chat';

  const fetchUserDetails = async () => {
    try {
      const response = await requestApi(
        '/user-details',
        {
          method: 'POST',
          body: JSON.stringify({
            token: localStorage.getItem('x-access-token'),
          }),
        },
        navigate
      );

      dispatch(setUser(response.data));
      if (response.data.logout) {
        dispatch(logout());
        navigate('/sign-in');
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
      navigate('/sign-in');
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, [dispatch, navigate]);

  useEffect(() => {
    const socketConnection = io(API_URL, {
      auth: { token: localStorage.getItem('x-access-token') },
    });

    socketConnection.on('onlineUser', (data) => {
      dispatch(setOnlineUser(data));
    });

    dispatch(setSocketConnection(socketConnection));

    return () => {
      socketConnection.disconnect();
    };
  }, [dispatch]);

  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        backgroundColor: '#f8f9fa',
        overflow: 'hidden',
      }}
    >
      {/* Sidebar */}
      <aside
        style={{
          flexBasis: '33%',
          maxWidth: '33%',
          backgroundColor: '#ffffff',
          borderRight: '1px solid #e9ecef',
          padding: '20px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          overflowY: 'auto',
          borderTopLeftRadius: '8px',
          borderBottomLeftRadius: '8px',
        }}
      >
        <Sidebar />
      </aside>

      {/* Main Content */}
      <main
        style={{
          flexBasis: '67%',
          maxWidth: '67%',
          padding: '24px',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#ffffff',
          boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.05)',
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
        }}
      >
        {basePath ? (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <img
              src={logo}
              alt="Chat App Logo"
              style={{
                width: '160px',
                borderRadius: '8px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                marginBottom: '20px',
              }}
            />
            <p
              style={{ color: '#495057', fontSize: '20px', fontWeight: '500' }}
            >
              Select a user to start messaging
            </p>
          </div>
        ) : (
          <Outlet />
        )}
      </main>
    </div>
  );
};

export default ChatHome;
