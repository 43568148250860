import React, { useEffect, useState } from 'react';
import {
  Button,
  FormGroup,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  Row,
  Col,
  Container,
  Input,
  Label,
  NavLink as TabLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { requestApi } from '../../../request-api';
import { useNavigate } from 'react-router-dom';
import { directUrl } from '../../../helpers/Constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CreateSCI() {
  let navigate = useNavigate();

  let [currentActiveTab, setCurrentActiveTab] = useState('1');
  let [calendarValue, setCalenderValue] = useState(new Date());
  let [showCalender, setShowCalender] = useState(false);
  let [selectedDate, setSelectedDate] = useState(null);
  let [transactionId, setTransactionId] = useState('');
  let [customer, setCustomer] = useState('');
  let [fetchGross, setFetchGross] = useState(0);
  let [expenses, setExpenses] = useState(0);
  let [sebi, setSebi] = useState(0);
  let [salary, setSalary] = useState(0);
  let [inHand, setInHand] = useState(0);
  let [fetchPayment, setFetchPayment] = useState([]);
  let [paymentType, setPaymentType] = useState('');
  let [fetchPartner, setFetchPartner] = useState([]);
  let [userRole, setUserRole] = useState('');
  let [emailId, setEmailId] = useState('');
  let [address, setAddress] = useState('');
  let [contactPerson, setContactPerson] = useState('');
  let [expen, setExpen] = useState(0);
  let [gross, setGross] = useState(0);
  let [remark, setRemark] = useState('');
  let [net, setNet] = useState(0);
  let [formErrors, setFormErrors] = useState({});
  let [loading, setLoading] = useState(false);
  let [modal, setModal] = useState(false);
  let [is50, setIs50] = useState(false);
  let [isCust, setIsCust] = useState(false);
  let [junaid, setJunaid] = useState({ name: 'Junaid', percent: 0, amount: 0 });
  let [extra, setExtra] = useState({ name: 'Extra', percent: 0, amount: 0 });
  let [mehdi, setMehdi] = useState({ name: 'Mehdi', percent: 0, amount: 0 });
  let [hashim, sethHashim] = useState({
    name: 'Hashim',
    percent: 0,
    amount: 0,
  });
  let [remainPercent, setRemainPercent] = useState(100);

  let [arbaz, setArbaz] = useState({ name: 'Arbaz', percent: 0, amount: 0 });
  let [upi, setUpi] = useState();

  const successToast = (message) =>
    toast.success(message, {
      position: 'bottom-right',
    });

  const errorToast = (error) =>
    toast.error(error, {
      position: 'bottom-right',
    });

  const toggle = (tab) => {
    if (currentActiveTab !== tab) {
      setCurrentActiveTab(tab);
    }
  };

  const customModal = () => {
    setModal(!modal);
  };

  const formValidation = () => {
    setLoading(true);
    const errors = {};
    const mobRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (expenses === 0) {
      errorToast('Kindly Enter Amount');
      errors.error = 'Kindly Enter Amount';
    }
    const gross = parseFloat(fetchGross);

    setLoading(false);
    return errors;
  };

  const createSCI = async () => {
    setLoading(true);
    requestApi(
      '/v1/sci',
      {
        method: 'post',
        body: JSON.stringify({
          amount: expenses,
          remark: remark,
          status: '0',

          date: selectedDate,
        }),
      },
      navigate
    )
      .then((data) => {
        console.log(data);
        if (data.statusCode === 201) {
          setLoading(false);
          successToast('Successfully created SCI');
          navigate(`${directUrl}employee/sci`);
        }
      })
      .catch((error) => {
        setLoading(false);
        errorToast(error.message);
        console.log(error);
      });
  };

  const validateBanner = async () => {
    const validate = formValidation();
    setFormErrors(validate);
    if (Object.keys(validate).length === 0) {
      createSCI();
    }
  };

  const handleDateChange = (date) => {
    console.log(new Date(date));
    setSelectedDate(new Date(date));
  };
  // useEffect(() => {
  //   setSelectedDate(
  //     `${calendarValue.getDate()}/${
  //       calendarValue.getMonth() + 1
  //     }/${calendarValue.getFullYear()}`
  //   );
  // }, [calendarValue]);
  return (
    <main onClick={() => setShowCalender(!showCalender)}>
      <section className="lyt-section"></section>
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : null}

      <section className="lyt-section p-0">
        <Container fluid className="typ-container">
          <div className="bs-card">
            <Row>
              <div className="bs-tabs">
                <Nav tabs>
                  <NavItem>
                    <TabLink
                      className={classnames({
                        active: currentActiveTab === '1',
                      })}
                      onClick={() => {
                        toggle('1');
                      }}
                    >
                      Add SCI
                    </TabLink>
                  </NavItem>
                  <div></div>
                </Nav>
                <div className="main-header">
                  <form className="bs-form">
                    <div className="dropdown-bx report-date">
                      <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        dateFormat="dd/MM/yyyy" // Format to display only month and year
                        placeholderText="Date"
                        className="typ-date"
                        style={{
                          width: '100%', // Make the date picker input full-width
                          padding: '10px', // Add padding for better spacing
                          border: '1px solid #ccc', // Add a border
                          borderRadius: '5px', // Add border radius for rounded corners
                          fontSize: '16px', // Increase font size for better readability
                        }}
                      />
                    </div>
                  </form>
                </div>
                {/* <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                  <div
                    className="dropdown-bx report-date"
                    // className="calender-area"
                  >
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat="dd/MM/yyyy" // Specify the date format
                      placeholderText="Select Payment Date" // Placeholder text for the input
                      className="typ-date"
                    />
                  </div>
                </Col> */}
              </div>

              <TabContent activeTab={currentActiveTab} className="px-0">
                <TabPane tabId="1">
                  <Container fluid>
                    <FormGroup className="bs-form">
                      <Row>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            Remark
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="remark"
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                          />
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="examplePassword"
                            className="mr-sm-2 cstm-label"
                          >
                            Amount <span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            className="cstm-input"
                            placeholder="Gross"
                            value={expenses}
                            pattern="[0-9]*" // Only allow numeric characters
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value) || 0
                              )
                                .toString()
                                .slice(0, 20); // Limit the input to 10 characters
                            }}
                            onChange={(e) => setExpenses(e.target.value)}
                          />
                        </Col>

                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <p className="error-message">{formErrors.error}</p>
                          <button
                            style={{ marginTop: '10px' }}
                            className="bs-btn "
                            type="button"
                            onClick={(e) => {
                              validateBanner(e);
                            }}
                          >
                            SUBMIT
                          </button>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Container>
                </TabPane>
              </TabContent>
            </Row>
          </div>
          <ToastContainer />
        </Container>
      </section>
    </main>
  );
}

export default CreateSCI;
