import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Avatar from './Avatar';
import { HiDotsVertical } from 'react-icons/hi';
import { FaAngleLeft, FaPlus } from 'react-icons/fa6';
import { FaImage, FaVideo } from 'react-icons/fa6';
import uploadFile from '../helpers/uploadFile';
import { IoClose } from 'react-icons/io5';
import { IoMdSend } from 'react-icons/io';

import Loading from './Loading';
import backgroundImage from '../../assets/image/wallapaper.jpeg';
import moment from 'moment';
import getFile from '../helpers/getFile';

const MessagePage = () => {
  const params = useParams();
  const socketConnection = useSelector(
    (state) => state?.user?.socketConnection
  );
  const user = useSelector((state) => state?.user);
  const [dataUser, setDataUser] = useState({
    name: '',
    email: '',
    profile_pic: '',
    online: false,
    role:'',
    id: '',
  });
  const [openImageVideoUpload, setOpenImageVideoUpload] = useState(false);
  const [message, setMessage] = useState({
    text: '',
    imageUrl: '',
    videoUrl: '',
  });
  const [loading, setLoading] = useState(false);
  const [allMessage, setAllMessage] = useState([]);
  const currentMessage = useRef(null);

  useEffect(() => {
    if (currentMessage.current) {
      currentMessage.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [allMessage]);

  const handleUploadImageVideoOpen = () => {
    setOpenImageVideoUpload((prev) => !prev);
  };

  const handleUploadImage = async (e) => {
    const file = e.target.files[0];
    setLoading(true);
    const uploadPhoto = await uploadFile(file);
    setLoading(false);
    setOpenImageVideoUpload(false);
    const img = await getFile(uploadPhoto.url);
    setMessage((prev) => ({
      ...prev,
      imageUrl: img,
    }));
  };

  const handleClearUploadImage = () => {
    setMessage((prev) => ({
      ...prev,
      imageUrl: '',
    }));
  };

  const handleUploadVideo = async (e) => {
    const file = e.target.files[0];
    setLoading(true);
    const uploadPhoto = await uploadFile(file);
    console.log(uploadPhoto);
    setLoading(false);
    setOpenImageVideoUpload(false);
    const img = await getFile(uploadPhoto.url);
    setMessage((prev) => ({
      ...prev,
      videoUrl: img,
    }));
  };

  const handleClearUploadVideo = () => {
    setMessage((prev) => ({
      ...prev,
      videoUrl: '',
    }));
  };

  useEffect(() => {
    if (socketConnection) {
      socketConnection.emit('message-page', params.userId);
      socketConnection.emit('seen', params.userId);
      socketConnection.on('message-user', (data) => {
        console.log("000000000000000000",data)
        setDataUser(data);
      });
      socketConnection.on('message', (data) => {
        console.log('message data', data);
        setAllMessage(data);
      });
    }
  }, [socketConnection, params?.userId, user]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    console.log(value);
    setMessage((prev) => ({
      ...prev,
      text: value,
    }));
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (message.text || message.imageUrl || message.videoUrl) {
      console.log(message);
      if (socketConnection) {
        console.log('-----------', user, params, message);
        socketConnection.emit('new message', {
          sender: user?.id,
          receiver: params.userId,
          text: message.text,
          imageUrl: message.imageUrl,
          videoUrl: message.videoUrl,
          msgByUserId: user?.id,
        });
        setMessage({
          text: '',
          imageUrl: '',
          videoUrl: '',
        });
      }
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <header
        style={{
          position: 'sticky',
          top: 0,
          height: '4rem',
          backgroundColor: '#fff',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 1rem',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.15)',
          zIndex: 10,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <Link to={'/chat'} style={{ display: 'none' }}>
            <FaAngleLeft size={25} />
          </Link>
          <Avatar
            width={40}
            height={40}
            imageUrl={dataUser?.profile_pic}
            name={dataUser?.name}
            userId={dataUser?.id}
          />
          <div>
            <h3
              style={{
                fontSize: '1rem',
                fontWeight: '600',
                margin: 0,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {dataUser?.name}
            </h3>
            <p
              style={{
                fontSize: '0.875rem',
                margin: 0,
                color: dataUser.online ? '#4caf50' : '#9e9e9e',
              }}
            >
              {dataUser.online ? 'online' : 'offline'}
            </p>
          </div>
        </div>
        <button
          style={{
            cursor: 'pointer',
            color: '#9e9e9e',
            transition: 'color 0.3s',
          }}
          className="hover:text-primary"
        >
          <HiDotsVertical />
        </button>
      </header>

      <section
        style={{
          flex: 1,
          overflowY: 'auto',
          padding: '1rem',
          backgroundColor: 'rgba(209, 213, 219, 0.5)',
          borderTop: '1px solid #e5e7eb',
          boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div
          style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
          ref={currentMessage}
        >
          {allMessage.map((msg, index) => (
            <div
              key={index}
              style={{
                padding: '0.5rem',
                borderRadius: '0.5rem',
                maxWidth: '60%',
                marginLeft: user.id === msg?.msgByUserId ? 'auto' : '0',
                backgroundColor:
                  user.id === msg?.msgByUserId ? '#e0f2f1' : '#fff',
                position: 'relative',
                boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
              }}
            >
              {msg?.imageUrl && (
                <img
                  src={msg?.imageUrl}
                  alt="message"
                  style={{
                    width: '100%',
                    borderRadius: '0.5rem',
                    marginBottom: '0.5rem',
                    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)',
                  }}
                />
              )}
              {msg?.videoUrl && (
                <video
                  src={msg.videoUrl}
                  style={{
                    width: '100%',
                    borderRadius: '0.5rem',
                    marginBottom: '0.5rem',
                    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)',
                  }}
                  controls
                />
              )}
              <p style={{ margin: '0.5rem 0' }}>{msg.text}</p>
              <p
                style={{
                  fontSize: '0.75rem',
                  textAlign: 'right',
                  color: '#9e9e9e',
                }}
              >
                {moment(msg.createdAt).format('hh:mm')}
              </p>
            </div>
          ))}
        </div>

        {message.imageUrl && (
          <div
            style={{
              position: 'sticky',
              bottom: 0,
              backgroundColor: 'rgba(51, 51, 51, 0.5)',
              padding: '0.5rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '0.5rem',
              boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)',
            }}
          >
            <button
              onClick={handleClearUploadImage}
              style={{
                position: 'absolute',
                top: '0.25rem',
                right: '0.25rem',
                backgroundColor: '#e57373',
                borderRadius: '50%',
                color: '#fff',
                cursor: 'pointer',
                border: 'none',
                padding: '0.25rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
              }}
            >
              <IoClose />
            </button>
            <img
              src={message.imageUrl}
              alt="Preview"
              style={{
                width: 'auto',
                maxHeight: '200px',
                borderRadius: '0.5rem',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
              }}
            />
          </div>
        )}

        {message.videoUrl && (
          <div
            style={{
              position: 'sticky',
              bottom: 0,
              backgroundColor: 'rgba(51, 51, 51, 0.5)',
              padding: '0.5rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '0.5rem',
              boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)',
            }}
          >
            <button
              onClick={handleClearUploadVideo}
              style={{
                position: 'absolute',
                top: '0.25rem',
                right: '0.25rem',
                backgroundColor: '#e57373',
                borderRadius: '50%',
                color: '#fff',
                cursor: 'pointer',
                border: 'none',
                padding: '0.25rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
              }}
            >
              <IoClose />
            </button>
            <video
              src={message.videoUrl}
              controls
              style={{
                width: 'auto',
                maxHeight: '200px',
                borderRadius: '0.5rem',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
              }}
            />
          </div>
        )}
      </section>

      <footer
        style={{
          position: 'sticky',
          bottom: 0,
          width: '100%',
          backgroundColor: '#fff',
          padding: '0.5rem 1rem',
          display: 'flex',
          alignItems: 'center',
          borderTop: '1px solid #e5e7eb',
          boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <button
          onClick={handleUploadImageVideoOpen}
          style={{
            backgroundColor: '#f5f5f5',
            border: 'none',
            borderRadius: '0.5rem',
            padding: '0.5rem',
            cursor: 'pointer',
            marginRight: '1rem',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          }}
        >
          <FaPlus size={20} color="#757575" />
        </button>

        {openImageVideoUpload && (
          <div
            style={{
              position: 'absolute',
              bottom: '3rem',
              left: '1rem',
              backgroundColor: '#fff',
              padding: '0.5rem 1rem',
              borderRadius: '0.5rem',
              boxShadow: '0 2px 10px rgba(0, 0, 0, 0.15)',
              zIndex: 10,
              display: 'flex',
              gap: '1rem',
            }}
          >
            <label
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                cursor: 'pointer',
                color: '#007134',
              }}
            >
              <FaImage size={24} />
              <input
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleUploadImage}
              />
              <span style={{ fontSize: '0.75rem' }}>Image</span>
            </label>
            {/* <label
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                cursor: 'pointer',
                color: '#ce1112',
              }}
            >
              <FaVideo size={24} />
              <input
                type="file"
                accept="video/*"
                style={{ display: 'none' }}
                onChange={handleUploadVideo}
              />
              <span style={{ fontSize: '0.75rem' }}>Video</span>
            </label> */}
          </div>
        )}

        <form
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
          }}
          onSubmit={handleSendMessage}
        >
          <input
            type="text"
            placeholder="Type your message"
            name="text"
            value={message.text}
            onChange={handleOnChange}
            style={{
              flex: 1,
              padding: '0.5rem 1rem',
              border: '1px solid #e5e7eb',
              borderRadius: '0.5rem',
              outline: 'none',
              fontSize: '1rem',
              boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)',
            }}
          />
          <button
            type="submit"
            style={{
              backgroundColor: '#4caf50',
              color: '#fff',
              border: 'none',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
            }}
          >
            <IoMdSend size={24} />
          </button>
        </form>
      </footer>

      {loading && <Loading />}
    </div>
  );
};

export default MessagePage;
