import React from 'react';
import { PiUserCircle } from 'react-icons/pi';
import { useSelector } from 'react-redux';

const Avatar = ({ userId, name, imageUrl, width, height }) => {
  const onlineUser = useSelector((state) => state?.user?.onlineUser);

  let avatarName = '';
  if (name) {
    const splitName = name.split(' ');
    avatarName =
      splitName.length > 1
        ? splitName[0][0] + splitName[1][0]
        : splitName[0][0];
  }

  const bgColor = [
    'bg-slate-200',
    'bg-teal-200',
    'bg-red-200',
    'bg-green-200',
    'bg-yellow-200',
    'bg-gray-200',
    'bg-cyan-200',
    'bg-sky-200',
    'bg-blue-200',
  ];

  const randomNumber = Math.floor(Math.random() * bgColor.length);
  const isOnline = onlineUser.includes(userId);

  return (
    <div
      style={{
        width: `${width}px`,
        height: `${height}px`,
        position: 'relative',
        borderRadius: '50%',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: bgColor[randomNumber],
        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
        transition: 'transform 0.3s ease-in-out',
      }}
      className="avatar-container"
    >
      {imageUrl ? (
        <img
          src={imageUrl}
          width={width}
          height={height}
          alt={name}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '50%',
          }}
        />
      ) : name ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: `${width / 2}px`,
            color: '#333',
            fontWeight: 'bold',
            backgroundColor: bgColor[randomNumber],
          }}
        >
          {avatarName}
        </div>
      ) : (
        <PiUserCircle size={width} style={{ color: '#999' }} />
      )}
      {isOnline && (
        <div
          style={{
            position: 'absolute',
            bottom: '4px',
            right: '4px',
            width: '12px',
            height: '12px',
            backgroundColor: 'green',
            borderRadius: '50%',
            border: '2px solid white',
            boxShadow: '0 0 2px rgba(0,0,0,0.2)',
          }}
        />
      )}
    </div>
  );
};

export default Avatar;
