import * as XLSX from 'xlsx';

export const dataToXLS = (workSheetData, reportId) => {
  // let filePath = reportId
  try {
    const worksheetName = 'Sheet1';
    const headerStyle = {
      font: { bold: true, color: { rgb: 'FFFFFF' } }, // White font color for better visibility
      fill: { fgColor: { rgb: '2E74B5' } }, // Blue background color
    };
    console.log('workSheetData', workSheetData);
    const workSheet = XLSX.utils.json_to_sheet(workSheetData, {
      headerStyles: headerStyle,
    });
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, worksheetName);

    workSheet['!cols'] = [{ wch: 10 }];
    XLSX.writeFile(workBook, reportId + '.xlsx');
  } catch (error) {
    console.log(error);
  }
};

export const dataToXLS2 = (workSheetData, reportId) => {
  // let filePath = reportId
  try {
    console.log(workSheetData);
    const workBook = XLSX.utils.book_new();
    for (let i = 0; i < workSheetData.length - 1; i++) {
      const worksheetName = workSheetData[i].date;
      const cellStyle = {
        fill: {
          rgb: 'FF0000',
        },
      };
      const headerStyle = {
        font: { bold: true, color: { rgb: 'FFFFFF' } }, // White font color for better visibility
        fill: { fgColor: { rgb: '2E74B5' } }, // Blue background color
      };

      const workSheet = XLSX.utils.json_to_sheet(
        [
          { Field: 'Sales', Data: workSheetData[i].gross_amt },
          { Field: '18%GST', Data: workSheetData[i].gst_amt },
          { Field: 'SEBI', Data: workSheetData[i].sebi },
          { Field: 'Expenses', Data: workSheetData[i].expenses },
          { Field: 'Salary+INC', Data: workSheetData[i].salary },
          { Field: 'Inhand Amount', Data: workSheetData[i].inhand },
          { Field: '', Data: '' },
          {
            Field: 'Junaid',
            Data:
              JSON.parse(workSheetData[i].partners)[0].amount +
              `(${JSON.parse(workSheetData[i].partners)[0].percent}%)`,
          },
          {
            Field: 'Extra',
            Data:
              JSON.parse(workSheetData[i].partners)[1].amount +
              `(${JSON.parse(workSheetData[i].partners)[1].percent}%)`,
          },

          {
            Field: 'Mehdi',
            Data:
              JSON.parse(workSheetData[i].partners)[2].amount +
              `(${JSON.parse(workSheetData[i].partners)[2].percent}%)`,
          },
          {
            Field: 'Hashim',
            Data:
              JSON.parse(workSheetData[i].partners)[3].amount +
              `(${JSON.parse(workSheetData[i].partners)[3].percent}%)`,
          },
          {
            Field: 'Arbaz',
            Data:
              JSON.parse(workSheetData[i].partners)[4].amount +
              `(${JSON.parse(workSheetData[i].partners)[4].percent}%)`,
          },
        ],
        {
          header: ['', '', 'Field', 'Data'],
        }
      );

      XLSX.utils.book_append_sheet(workBook, workSheet, worksheetName);
    }

    XLSX.writeFile(workBook, reportId + '.xlsx',{ cellStyles:true });
  } catch (error) {
    console.log(error);
  }
};
