import axios from 'axios';
import { API_URL } from '../../config';
import { userStore } from '../../stores';

const url = `${API_URL}/v1/leaddata/upload`; // Replace with your server's upload endpoint
const getUrl = `${API_URL}/v1/leaddata/uploads/`; // Replace with your server's upload endpoint

const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: userStore.token || '',
      },
    };
    const responseData = await axios.post(url, formData, config);

    console.log(responseData.data);
    return responseData.data;
  } catch (error) {
    console.error('Error uploading file:', error);
    // throw error;
  }
};

const getFile = async (filename) => {
  try {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: userStore.token || '',
      },
    };
    const responseData = await axios.get(`${getUrl}${filename}`);

    console.log(responseData.data);
    return responseData.data;
  } catch (error) {
    console.error('Error uploading file:', error);
    // throw error;
  }
};

export default uploadFile;
