import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Label, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
//assets

import logo from '../../../../assets/image/logo.png';
import { directUrl } from '../../../../helpers/Constants';
import { requestApi } from '../../../../request-api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const EmailReset = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  const successToast = (message) =>
    toast.success(message, {
      position: 'bottom-right',
    });

  const errorToast = (error) =>
    toast.error(error, {
      position: 'bottom-right',
    });
  const onSubmitDetails = async (email) => {
    setLoading(true);
    requestApi(
      '/v1/resetpassword',
      {
        method: 'post',
        body: JSON.stringify({
          email,
        }),
      },
      navigate
    )
      .then((data) => {
        console.log(data);
        if (data.statusCode === 200) {
          setLoading(false);
          successToast(data.message);

          setTimeout(() => navigate(`${directUrl}sign-in`),3000);
          
        }
        
      })
      .catch((error) => {
        setLoading(false);
        errorToast(error.message);
        console.log(error);
        
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regular expression for email validation
    if (email.length === 0) {
      setError('Please enter an email address');
    } else if (!emailPattern.test(email)) {
      setError('Please enter a valid email address');
    } else {
      // Proceed with the submission if the email is valid
      setEmail(email);

      onSubmitDetails(email);
    }
  };
  // const [password, setPassword] = useState('');
  return (
    <section className="lyt-section typ-login">
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : null}
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col xs={12} sm={12} md={12} lg={6} xl={5}>
            <div className="bs-card typ-login">
              <div className="img-area">
                <picture>
                  <source media="(max-width:992px)" srcSet={logo} />
                  <img
                    src={logo}
                    style={{ maxWidth: '25%' }}
                    alt="JK Trading"
                  />
                </picture>
              </div>
              <form className="bs-form" onSubmit={(e) => onSubmit(email, e)}>
                <Label for="mail">Reset Password</Label>
                <div className="input-area">
                  <Input
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    type="email"
                    id="mail"
                    placeholder="Enter Your Email Address"
                  />
                  <i className="icon icon-user-round"></i>
                </div>
                {/* <div className='mod-divider typ-or'></div> */}
                {error && (
                  <p
                    style={{
                      position: 'absolute',
                      fontSize: '12px',
                      color: '#d73038',
                      margin: '0 0px 10px',
                      fontFamily: 'roboto',
                      fontWeight: 500,
                    }}
                  >
                    {error}
                  </p>
                )}

                <button
                  onClick={(e) => onSubmit(e)}
                  className="bs-btn typ-login"
                  style={{ marginTop: 15 }}
                  type="button"
                >
                  Reset Password
                </button>
              </form>
            </div>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </section>
  );
};
