import { jwtDecode } from 'jwt-decode';
import { NavLink, useNavigate } from 'react-router-dom';
import { directUrl } from '../helpers/Constants';
// import { observable, action, computed } from 'mobx'
import { requestApi } from '../request-api';

export class UserStore {
  token = undefined;
  user = {};
  // navigate = useNavigate

  getready() {
    return this.token !== undefined;
  }

  getdecodedToken() {
    return this.token ? jwtDecode(this.token) : undefined;
  }

  getuserId() {
    return this.getdecodedToken?.id;
  }

  constructor() {
    console.debug('getting token');
    this.token = localStorage.getItem('x-access-token') || '';
    // this.token = localStorage.getItem('@ic-dashboard/token') || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MDFhNWM0ZTQwMTE2NzI3OGRlOGRiMGYiLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE2NDY4MDQxNTQsImV4cCI6MTY0NzQwODk1NCwiaXNzIjoiaWMtYXBpIn0.ikHmzOxRgtZ2dIoeqwv8fKcMq5342qRAlSWzw03o8WQ'
    // this.token =
    //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MDBjN2MwNjQwMTE2NzI3OGRlOGRiMGMiLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE2MTE4NDYyMjAsImV4cCI6MTYxMjQ1MTAyMCwiaXNzIjoiaWMtYXBpIn0._ajZIpePUF_7LdABjtSGZm8CIxc0L9hE28V8VQkLsOg'
  }

  setToken = (token = '') => {
    console.debug('setting token');

    this.token = token;
    localStorage.setItem('x-access-token', token);
  };
  setUser = (user) => {
    this.user = user;
  };
  getUser = (url) =>
    requestApi(url, { method: 'GET' })
      .then((user) => {
        this.user = user;
      })
      .catch((error) => console.error('failed to get user', error));

  signOut = () => {
    console.debug('signing out');

    localStorage.clear();
    return true; // Sign-out successful
    // this.navigate(`${directUrl}sign-in`);
  };
}

export const userStore = new UserStore();
