import React, { useEffect, useState } from 'react';
import {
  Button,
  FormGroup,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  Row,
  Col,
  Container,
  Input,
  Label,
  NavLink as TabLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import classnames from 'classnames';
import { requestApi } from '../../../request-api';
import { useNavigate, Link } from 'react-router-dom';
import { directUrl, homePageUrl } from '../../../helpers/Constants';

//assets

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AddPayment() {
  let navigate = useNavigate();

  let [currentActiveTab, setCurrentActiveTab] = useState('1');
  let [calendarValue, setCalenderValue] = useState(new Date());
  let [showCalender, setShowCalender] = useState(false);
  let [selectedDate, setSelectedDate] = useState('');
  let [paymentName, setPaymentName] = useState('');
  let [upi, setUpi] = useState('');
  let [branchId, setBranchId] = useState('');
  let [contactDetails, setContactDetails] = useState('');
  let [userRole, setUserRole] = useState('');
  let [emailId, setEmailId] = useState('');
  let [contactName, setContactName] = useState('');
  let [contactPerson, setContactPerson] = useState('');
  let [company, setCompany] = useState([]);
  let [compliancer, setCompliancer] = useState(['admin', 'employee']);
  const [fileData, setFileData] = useState([]);
  let [formErrors, setFormErrors] = useState({});
  let [isSubmit, setIsSubmit] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  let [loading, setLoading] = useState(false);
  let [modal, setModal] = useState(false);

  const successToast = (message) =>
    toast.success(message, {
      position: 'bottom-right',
    });

  const errorToast = (error) =>
    toast.error(error, {
      position: 'bottom-right',
    });

  const toggle = (tab) => {
    if (currentActiveTab !== tab) {
      setCurrentActiveTab(tab);
    }
  };
  useEffect(() => {
    setLoading(true);
    // getCompany();
    setLoading(false);
  }, []);


  const customModal = () => {
    console.log('method called', modal);
    setModal(!modal);
  };

  useEffect(() => {
    
    setSelectedDate(
      `${calendarValue.getDate()}/${
        calendarValue.getMonth() + 1
      }/${calendarValue.getFullYear()}`
    );
  }, [calendarValue]);

  const formValidation = (values) => {
    setLoading(true);
    const errors = {};
    const mobRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;

    // Validate data fields
    if (!paymentName) {
      errors.paymentName = 'Name is required';
    }
    if (!upi) {
      errors.upi = 'UPI is required';
    }
    if (!contactName) {
      errors.contactName = 'Contact Person is required';
    }
    if (!contactDetails) {
      errors.contactDetails = 'Phone Number is required';
    } else if (!mobRegex.test(contactDetails)) {
      errors.contactDetails = 'Invalid Phone Number';
    }

    setLoading(false);

    return errors;
  };

  const createPayment = async () => {
    setLoading(true);
    requestApi(
      '/v1/payment',
      {
        method: 'post',
        body: JSON.stringify({
          name: paymentName,
          upi: upi,
          contactPerson: contactName,
          phone: contactDetails,
        }),
      },
      navigate
    )
      .then((data) => {
        console.log(data);
        if (data.statusCode === 201) {
          setLoading(false);
          successToast('Successfully created payment');
          navigate(`${directUrl}admin/payment`);
        }
        if (data.statusCode === 409) {
          setLoading(false);
          errorToast('Payment Already Exist');
        }
      })
      .catch((error) => {
        setLoading(false);
        errorToast(error.message);
        console.log(error);
      });
  };

  const validateBanner = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    const validate = formValidation();
    setFormErrors(validate);
    console.log('validate', validate);
    if (Object.keys(validate).length == 0) {
      if (currentActiveTab === '1') {
        createPayment(e);
      }
    }
  };
  return (
    <main onClick={() => setShowCalender(!showCalender)}>
      <section className="lyt-section"></section>
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : null}

      <section className="lyt-section p-0">
        <Container fluid className="typ-container">
          <div className="bs-card">
            <Row>
              <div className="bs-tabs">
                <Nav tabs>
                  <NavItem>
                    <TabLink
                      className={classnames({
                        active: currentActiveTab === '1',
                      })}
                      onClick={() => {
                        toggle('1');
                      }}
                    >
                      Add Payment
                    </TabLink>
                  </NavItem>
                </Nav>
              </div>
              <TabContent activeTab={currentActiveTab} className="px-0">
                <TabPane tabId="1">
                  <Container fluid>
                    <FormGroup className="bs-form">
                      <Row>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="examplecontactName"
                            className="mr-sm-2 cstm-label"
                          >
                            Payment Name
                            <span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            name="paymentName"
                            id="examplecontactName"
                            className="cstm-input"
                            placeholder="Enter Payment Name"
                            onChange={(e) => {
                              setPaymentName(e.target.value);
                            }}
                          />
                          <p className="error-message">
                            {formErrors.paymentName}
                          </p>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="exampleEmail"
                            className="mr-sm-2 cstm-label"
                          >
                            Contact Name
                            <span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            name="text"
                            id="exampleEmail"
                            className="cstm-input"
                            placeholder="Enter Contact Name"
                            onChange={(e) => {
                              setContactName(e.target.value);
                            }}
                          />
                          <p className="error-message">
                            {formErrors.contactName}
                          </p>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="exampleEmail"
                            className="mr-sm-2 cstm-label"
                          >
                            Contact Number
                            <span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            name="text"
                            id="exampleEmail"
                            className="cstm-input"
                            placeholder="Enter Contact Number"
                            onChange={(e) => {
                              setContactDetails(e.target.value);
                            }}
                          />
                          <p className="error-message">
                            {formErrors.contactDetails}
                          </p>
                        </Col>

                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <Label
                            for="exampleEmail"
                            className="mr-sm-2 cstm-label"
                          >
                            UPI <span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            name="text"
                            id="exampleEmail"
                            className="cstm-input"
                            placeholder="Enter UPI"
                            onChange={(e) => {
                              setUpi(e.target.value);
                            }}
                          />
                          <p className="error-message">{formErrors.upi}</p>
                        </Col>

                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                          <button
                            className="bs-btn "
                            type="button"
                            onClick={(e) => {
                              validateBanner(e);
                            }}
                          >
                            SUBMIT
                          </button>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Container>
                </TabPane>
              </TabContent>
            </Row>
          </div>
          <ToastContainer />
        </Container>
      </section>
    </main>
  );
}

export default AddPayment;
