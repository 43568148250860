import React, { useEffect, useState } from 'react';
import { IoChatbubbleEllipses } from 'react-icons/io5';
import { FaUserPlus, FaTrashAlt } from 'react-icons/fa';
import { NavLink, useNavigate } from 'react-router-dom';
import { BiLogOut } from 'react-icons/bi';
import Avatar from './Avatar';
import { useDispatch, useSelector } from 'react-redux';
import EditUserDetails from './EditUserDetails';
import SearchUser from './SearchUser';
import { logout } from '../../redux/userSlice';

const Sidebar = () => {
  const user = useSelector((state) => state?.user);
  const [editUserOpen, setEditUserOpen] = useState(false);
  const [allUser, setAllUser] = useState([]);
  const [openSearchUser, setOpenSearchUser] = useState(false);
  const socketConnection = useSelector(
    (state) => state?.user?.socketConnection
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (socketConnection) {
      socketConnection.emit('sidebar', user.id);
      console.log('zzzzzzzzzzzzzzzzzzzzzzzz', user);
      socketConnection.on('conversation', (data) => {
        const conversationUserData = data.map((conversationUser) => {
          if (conversationUser?.sender?.id === conversationUser?.receiver?.id) {
            return {
              ...conversationUser,
              userDetails: conversationUser?.sender,
            };
          } else if (conversationUser?.receiver?.id !== user?.id) {
            return {
              ...conversationUser,
              userDetails: conversationUser.receiver,
            };
          } else {
            return {
              ...conversationUser,
              userDetails: conversationUser.sender,
            };
          }
        });
        console.log(conversationUserData);
        setAllUser(conversationUserData);
       
       
      });
    }
  }, [socketConnection, user]);

  const handleLogout = () => {
    navigate('/admin/home');
  };

  const handleDeleteConversation = (conversationId) => {
    if (socketConnection) {
      socketConnection.emit('deleteConversation', {
        userId: user.id,
        conversationId,
      });
      setAllUser(allUser.filter((conv) => conv.id !== conversationId));
    }
  };

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 3fr',
        backgroundColor: '#f8f9fa',
        maxHeight: 'calc(100vh - 80px)',
        overflowY: 'auto',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
      }}
    >
      <aside
        style={{
          backgroundColor: '#ffffff',
          borderRight: '1px solid #e9ecef',
          padding: '16px',
          color: '#495057',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          height: '100%',
          borderTopLeftRadius: '16px',
          borderBottomLeftRadius: '16px',
        }}
      >
        <div>
          <NavLink
            to="/chat"
            style={({ isActive }) => ({
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '48px',
              height: '48px',
              borderRadius: '50%',
              backgroundColor: isActive ? '#e9ecef' : 'transparent',
              color: '#495057',
              cursor: 'pointer',
              textAlign: 'center',
              lineHeight: '48px',
              transition: 'background-color 0.3s ease',
              marginBottom: '16px',
            })}
            title="Chat"
          >
            <IoChatbubbleEllipses size={24} />
          </NavLink>

          <div
            title="Add Friend"
            onClick={() => setOpenSearchUser(true)}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '48px',
              height: '48px',
              borderRadius: '50%',
              backgroundColor: 'transparent',
              color: '#495057',
              cursor: 'pointer',
              textAlign: 'center',
              lineHeight: '48px',
              transition: 'background-color 0.3s ease',
              marginBottom: '16px',
            }}
          >
            <FaUserPlus size={24} />
          </div>
       
          <button
            style={{
              border: 'none',
              background: 'transparent',
              cursor: 'pointer',
              padding: '0',
              margin: '0',
              borderRadius: '50%',
              transition: 'background-color 0.3s ease',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            title={user?.name}
            onClick={() => setEditUserOpen(true)}
          >
            <Avatar
              width={48}
              height={48}
              name={user?.name}
              imageUrl={user?.profile_pic}
              userId={user?.id}
            />
          </button>
          <button
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '48px',
              height: '48px',
              borderRadius: '50%',
              backgroundColor: 'transparent',
              color: '#495057',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease',
              marginTop: '16px',
            }}
            title="Back to CRM"
            onClick={handleLogout}
          >
            <BiLogOut size={24} />
          </button>
        </div>
      </aside>

      <main
        style={{
          padding: '16px',
          backgroundColor: '#ffffff',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            height: '64px',
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid #e9ecef',
            marginBottom: '16px',
          }}
        >
          <h2
            style={{
              fontSize: '18px',
              fontWeight: '600',
              color: '#343a40',
            }}
          >
            Messages
          </h2>
        </div>

        <div
          style={{
            flex: 1,
            overflowY: 'auto',
            paddingTop: '8px',
            paddingBottom: '8px',
            maxHeight: 'calc(100vh - 80px)',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
          }}
        >
          {allUser.length === 0 && (
            <div style={{ marginTop: '48px', textAlign: 'center' }}>
              <p
                style={{
                  fontSize: '16px',
                  color: '#adb5bd',
                  marginTop: '8px',
                  fontFamily: 'Segoe UI, Arial, sans-serif',
                }}
              >
                Explore users to start a conversation with.
              </p>
            </div>
          )}

          {allUser.map((conv) => (
            <div key={conv?.id} style={{ position: 'relative' }}>
              <NavLink
                to={'/chat/' + conv?.userDetails?.id}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '12px',
                  padding: '12px',
                  borderRadius: '8px',
                  border: '2px solid transparent',
                  transition:
                    'border-color 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease',
                  textDecoration: 'none',
                  color: '#343a40',
                  backgroundColor: 'transparent',
                  cursor: 'pointer',
                  marginBottom: '8px',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  fontFamily: 'Segoe UI, Arial, sans-serif',
                  position: 'relative', // Ensure the unseenMsg is positioned correctly
                }}
                activeStyle={{
                  backgroundColor: '#e9ecef',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                  transform: 'scale(1.02)',
                  borderImage:
                    'linear-gradient(45deg, #ff6b6b, #f7b42c, #4e54c8, #20bf6b) 1',
                  borderImageSlice: 1,
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.boxShadow =
                    '0 4px 8px rgba(0, 0, 0, 0.15)';
                  e.currentTarget.style.transform = 'scale(1.02)';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.boxShadow =
                    '0 2px 4px rgba(0, 0, 0, 0.1)';
                  e.currentTarget.style.transform = 'scale(1)';
                }}
              >
                <Avatar
                  width={48}
                  height={48}
                  name={conv?.userDetails?.name}
                  imageUrl={conv?.userDetails?.profile_pic}
                  userId={conv?.userDetails?.id}
                />
                <div>
                  <h4
                    style={{
                      fontSize: '16px',
                      fontWeight: '600',
                      color: '#343a40',
                    }}
                  >
                    {conv?.userDetails?.name}
                  </h4>
                  <p
                    style={{
                      fontSize: '14px',
                      color: '#adb5bd',
                      fontWeight: 'bold',
                      margin: 0,
                    }}
                  >
                    {conv?.lastMsg?.text
                      ? conv.lastMsg.text.length > 10
                        ? `${conv.lastMsg.text.slice(0, 10)}...`
                        : conv.lastMsg.text
                      : 'Start a new conversation...'}{' '}
                  </p>
                </div>

                {Boolean(conv?.unseenMsg) && conv?.receiver?.id == user?.id && (
                  <p
                    className="text-xs w-6 h-6 flex justify-center items-center ml-auto p-1 bg-primary text-white font-semibold rounded-full"
                    style={{
                      position: 'absolute',
                      top: '50%',
                      right: '50px', // Adjust the position as needed
                      transform: 'translateY(-50%)',
                      backgroundColor: '#ff6b6b', // Customize your bg color
                      color: '#ffffff',
                      fontWeight: 'bold',
                      borderRadius: '50%',
                      padding: '6px',
                      minWidth: '24px',
                      textAlign: 'center',
                    }}
                  >
                    {conv?.unseenMsg > 9 ? '9+' : conv?.unseenMsg}
                  </p>
                )}
              </NavLink>
              {user.role == 'admin' && (
                <button
                  onClick={() => handleDeleteConversation(conv?.id)}
                  style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    color: '#adb5bd',
                    cursor: 'pointer',
                    position: 'absolute',
                    right: '8px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    transition: 'color 0.3s ease',
                    zIndex: 999,
                  }}
                  title="Delete Conversation"
                  onMouseEnter={(e) => {
                    e.currentTarget.style.color = 'red';
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.color = '#adb5bd';
                  }}
                >
                  <FaTrashAlt size={16} />
                </button>
              )}
            </div>
          ))}
        </div>
      </main>

      {editUserOpen && (
        <EditUserDetails user={user} onClose={() => setEditUserOpen(false)} />
      )}
      {openSearchUser && (
        <SearchUser onClose={() => setOpenSearchUser(false)} />
      )}
    </div>
  );
};

export default Sidebar;
