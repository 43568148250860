// export const PRODUCTION = process.env.NODE_ENV === 'development';
export const PRODUCTION = process.env.NODE_ENV === 'production'

// export const API_URL = 'http://localhost:3001'
//   ? 'http://localhost:3001'
//   : 'http://localhost:3001';

// export const API_URL = 'http://13.200.234.121'
//   ? 'http://13.200.234.121'
//   : 'http://13.200.234.121';
export const API_URL = 'https://api.jktradingoption.com'
  ? 'https://api.jktradingoption.com'
  : 'https://api.jktradingoption.com'; 
 
// export const API_URL = process.env.API
