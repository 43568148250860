import { Route, Router, Routes, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { userStore } from './stores';
//constant
import { homePageUrl } from './helpers/Constants';

//style
import './App.scss';

import logo from './logo.svg';

import { GlobalContext } from './contexts/GlobalContext';
import ProtectedRoutes from './compnents/protectedRoutes';
import { AuthScreen } from './compnents/pages/auth';
import CreateEmployee from './compnents/pages/create-employee/CreateEmployee';
import AdminHeader from './compnents/layouts/header/AdminHeader';
import Employee from './compnents/pages/employee/Employee';
import AdminHome from './compnents/pages/admin-home/Home';
import Branch from './compnents/pages/branch/Branch';
import UpdateEmployee from './compnents/pages/create-employee/UpdateEmployee';
import AddBranch from './compnents/pages/branch/AddBranch';
import AddPayment from './compnents/pages/payment/AddPayment';
import Payment from './compnents/pages/payment/Payment';
import Dashboard from './compnents/pages/dashboard/Dashboard';
import ApproverSettings from './compnents/pages/create-user-profile/UserSetting';

import Settings from './compnents/pages/create-user-profile/Settings';
import UserHeader from './compnents/layouts/header/UserHeader';
import EmployeeDashboard from './compnents/pages/dashboard/EmployeeDashboard';
import Customer from './compnents/pages/customer/Customer';
import AdminCustomer from './compnents/pages/admin-customer/Customer';
import EditBranch from './compnents/pages/branch/EditBranch';
import EmployeeHome from './compnents/pages/employee-home/Home';
import CreateTransaction from './compnents/pages/employee-home/CreateTransaction';
// import UpdateCustomer from './compnents/pages/create-customer/UpdateCustomer';
import CreateCustomer from './compnents/pages/create-customer/CreateCustomer';
import DetailedHome from './compnents/pages/admin-home/DetailedHome';
import Trends from './compnents/pages/trend/trends';
import AdminTrends from './compnents/pages/trend/AdminTrends';
import Home from './compnents/pages/home/Home';
import EditTransaction from './compnents/pages/employee-home/EditTransaction';
import UpdateCustomer from './compnents/pages/create-customer/UpdateCustomer';
import { EmailReset } from './compnents/pages/auth/screens/EmailReset';
import { ResetPassword } from './compnents/pages/auth/screens/ResetPassword';
import CreateMPS from './compnents/pages/mps/CreateMPS';
import EditMPS from './compnents/pages/mps/EditMPS';
import MPS from './compnents/pages/mps/Mps-list';
import SCI from './compnents/pages/sci/SCI-list';
import CreateSCI from './compnents/pages/sci/CreateSCI';
import SCIAdmin from './compnents/pages/sci/SCI-list-admin';
import DispositionAdmin from './compnents/pages/disposition/Dispositionlist-admin';
import CreateDisposition from './compnents/pages/disposition/CreateDisposition';
import EditDisposition from './compnents/pages/disposition/EditDisposition';
import CreateData from './compnents/pages/admin-data/CreatedData';
import LeadDataFilesAdmin from './compnents/pages/admin-data/LeadDataFile-admin';
import LeadDataDetailsAdmin from './compnents/pages/admin-data/LeadDataDetailsFile-admin';
import LeadDataDetailsEmp from './compnents/pages/emp-data/LeadDataDetailsFile-emp';
import LeadDataFilesEmp from './compnents/pages/emp-data/LeadDataFile-emp';
import CreateHolding from './compnents/pages/holdings/CreateHolding';
import HoldingEmp from './compnents/pages/holdings/Holding-employee';
import EditHolding from './compnents/pages/holdings/EditHolding';
import PayUpdateEmp from './compnents/pages/payment-updates/PayUpdate-employee';
import CreatePayUpdate from './compnents/pages/payment-updates/CreatePayUpdate';
import EditPayUpdate from './compnents/pages/payment-updates/EditPayUpdate';
import FtCountEmp from './compnents/pages/ft-count/ftcount-employee';
import EditFtCount from './compnents/pages/ft-count/EditFtCount';
import AdminList from './compnents/pages/employee/Admin';
import ActivityAdmin from './compnents/pages/ft-count/ActivityAdmin';
import ChatHome from './compnents/pages/chat/Home';
import MessagePage from './compnents/components/MessagePage';
import UserNotify from './compnents/pages/create-user-profile/UserNotify';

function App() {
  const location = useLocation();
  let [hideHeader, setHideHeader] = useState(false);
  let [header, setHeader] = useState('');
  const [role, setRole] = useState();
  const [notification, setNotification] = useState([]);
  const [roomId, setRoomId] = useState('');

  useEffect(() => {
    let myLocation = location.pathname;
    console.log(myLocation.includes('sign-in'));
    if (
      myLocation.includes('login') ||
      myLocation.includes('sign-in') ||
      myLocation.includes('code') ||
      myLocation.includes('chat') ||
      myLocation.includes('reset')
    ) {
      // console.log("whatsapp screen")
      setHideHeader(true);
    }
    if (myLocation.includes('admin')) {
      setHideHeader(false);
      setHeader('owner');
    } else if (myLocation.includes('employee')) {
      setHideHeader(false);
      setHeader('employee');
    }
  });
  return (
    <GlobalContext.Provider
      value={{ notification, setNotification, roomId, setRoomId }}
    >
      <React.Fragment>
        {header === 'owner' ? (
          <AdminHeader hideHeader={hideHeader} />
        ) : (
          <UserHeader hideHeader={hideHeader} />
        )}

        <Routes>
          <Route exact path={``} element={<Home />} />
          <Route exact path={`/resetpassword`} element={<EmailReset />} />
          <Route exact path={`/reset/:id/:token`} element={<ResetPassword />} />
          <Route path="/" element={<ProtectedRoutes />}>
            <Route path="chat/" element={<ChatHome />}>
              <Route path=":userId" element={<MessagePage />} />
            </Route>
            <Route path="/" element={<ProtectedRoutes roleRequired="admin" />}>
              <Route
                exact="true"
                path={`admin/add-employee`}
                element={<CreateEmployee />}
              />
              <Route exact path={`admin/employee`} element={<Employee />} />
              <Route
                exact
                path={`admin/customer`}
                element={<AdminCustomer />}
              />
              <Route exact path={`admin/SCI`} element={<SCIAdmin />} />

              <Route
                exact
                path={`admin/employee-edit/:id`}
                element={<UpdateEmployee />}
              />
              <Route exact path={`admin/home`} element={<AdminHome />} />
              <Route exact path={`admin/home/:id`} element={<DetailedHome />} />
              <Route
                exact
                path={`admin/home/:uid/:id`}
                element={<EditTransaction />}
              />
              <Route exact path={`admin/branch`} element={<Branch />} />
              <Route exact path={`admin/add-branch`} element={<AddBranch />} />
              <Route exact path={`admin/add-mps`} element={<CreateMPS />} />
              <Route exact path={`admin/add-data`} element={<CreateData />} />
              <Route
                exact
                path={`admin/activity`}
                element={<ActivityAdmin />}
              />
              <Route
                exact
                path={`admin/lead-data-file`}
                element={<LeadDataFilesAdmin />}
              />
              <Route
                exact
                path={`admin/lead-data/:id`}
                element={<LeadDataDetailsAdmin />}
              />
              <Route exact path={`admin/edit-mps/:id`} element={<EditMPS />} />
              <Route exact path={`admin/admin-list`} element={<AdminList />} />
              <Route
                exact
                path={`admin/edit-disposition/:id`}
                element={<EditDisposition />}
              />
              <Route exact path={`admin/mps`} element={<MPS />} />
              <Route
                exact
                path={`admin/disposition`}
                element={<DispositionAdmin />}
              />
              <Route
                exact
                path={`admin/add-disposition`}
                element={<CreateDisposition />}
              />
              <Route
                exact
                path={`admin/edit-branch/:id`}
                element={<EditBranch />}
              />
              <Route exact path={`admin/payment`} element={<Payment />} />

              <Route
                exact
                path={`admin/add-payment`}
                element={<AddPayment />}
              />
              <Route exact path={`admin/dashboard`} element={<Dashboard />} />
              <Route exact path={`admin/trend`} element={<AdminTrends />} />
              <Route
                exact
                path={`admin/user-details/:id`}
                element={<ApproverSettings />}
              />
              <Route
                exact
                path={`admin/notification`}
                element={<UserNotify />}
              />
            </Route>
            <Route path="/" element={<ProtectedRoutes roleRequired="user" />}>
              <Route
                exact
                path={`employee/dashboard`}
                element={<EmployeeDashboard />}
              />
              <Route exact path={`employee/home`} element={<EmployeeHome />} />
              <Route
                exact
                path={`employee/add-transaction`}
                element={<CreateTransaction />}
              />{' '}
              <Route
                exact
                path={`employee/add-customer`}
                element={<CreateCustomer />}
              />
              <Route exact path={`employee/SCI`} element={<SCI />} />
              <Route exact path={`employee/add-sci`} element={<CreateSCI />} />
              <Route exact path={`employee/customer`} element={<Customer />} />
              <Route exact path={`employee/trend`} element={<Trends />} />
              <Route
                exact
                path={`employee/lead`}
                element={<LeadDataFilesEmp />}
              />
              <Route
                exact
                path={`employee/lead-data/:id`}
                element={<LeadDataDetailsEmp />}
              />
              <Route
                exact
                path={`employee/holdings`}
                element={<HoldingEmp />}
              />
              <Route
                exact
                path={`employee/add-holdings`}
                element={<CreateHolding />}
              />
              <Route
                exact
                path={`employee/edit-holding/:id`}
                element={<EditHolding />}
              />
              <Route
                exact
                path={`employee/payment-updates`}
                element={<PayUpdateEmp />}
              />
              <Route
                exact
                path={`employee/add-payment-updates`}
                element={<CreatePayUpdate />}
              />
              <Route
                exact
                path={`employee/edit-payment-updates/:id`}
                element={<EditPayUpdate />}
              />
              <Route
                exact
                path={`employee/edit-ft-count/:id`}
                element={<EditFtCount />}
              />
              <Route
                exact
                path={`employee/ft-counts`}
                element={<FtCountEmp />}
              />
              <Route
                exact
                path={`employee/edit-customer/:id`}
                element={<UpdateCustomer />}
              />
            </Route>
          </Route>
          {/* </Route> */}
          if(!userStore.token)
          {
            <Route
              exact="true"
              path={`sign-in`}
              element={<AuthScreen userStore={userStore} />}
            />
          }
          {/* </Route> */}
        </Routes>
      </React.Fragment>
    </GlobalContext.Provider>
  );
}

export default App;
