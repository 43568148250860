import React, { useEffect, useState } from 'react';
import { IoSearchOutline, IoClose } from 'react-icons/io5';
import Loading from './Loading';
import UserSearchCard from './UserSearchCard';
import toast from 'react-hot-toast';
import { requestApi } from '../../request-api';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';

const SearchUser = ({ onClose }) => {
  const [searchUser, setSearchUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();

  const handleSearchUser = async () => {
    const URL = `${process.env.REACT_APP_BACKEND_URL}/api/search-user`;
    try {
      setLoading(true);
      const response = await requestApi(
        `/search-user`,
        {
          method: 'post',
          body: JSON.stringify({ search }),
        },
        navigate
      );
      setLoading(false);
      setSearchUser(response.data);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.message || 'An error occurred');
    }
  };

  // Debounced search function
  const debouncedSearch = debounce(() => {
    if (search.length > 0) {
      handleSearchUser();
    } else {
      setSearchUser([]);
    }
  }, 300);

  useEffect(() => {
    debouncedSearch();
    // Cleanup the debounce on unmount
    return () => {
      debouncedSearch.cancel();
    };
  }, [search]);

  return (
    <div
      style={{
        position: 'fixed',
        inset: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        padding: '1rem',
        zIndex: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          width: '100%',
          maxWidth: '400px',
          backgroundColor: '#fff',
          borderRadius: '0.75rem',
          boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
          overflow: 'hidden',
          position: 'relative',
          maxHeight: '80vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid #e5e7eb',
            backgroundColor: '#f9fafb',
            padding: '0.5rem',
          }}
        >
          <input
            type="text"
            placeholder="Search user by name, email..."
            aria-label="Search users"
            style={{
              flex: 1,
              padding: '0.5rem 1rem',
              border: '1px solid #e5e7eb',
              borderRadius: '0.75rem 0 0 0.75rem',
              fontSize: '1rem',
              outline: 'none',
            }}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
          <div
            style={{
              padding: '0.5rem',
              backgroundColor: '#f9fafb',
              borderRadius: '0 0.75rem 0.75rem 0',
            }}
          >
            <IoSearchOutline size={24} />
          </div>
        </div>

        <div
          style={{
            padding: '1rem',
            flex: 1,
            overflowY: 'auto',
          }}
        >
          {loading && (
            <div style={{ textAlign: 'center' }}>
              <Loading />
            </div>
          )}
          {!loading && searchUser.length === 0 && search && (
            <p style={{ textAlign: 'center', color: '#6b7280' }}>
              No users found!
            </p>
          )}
          {!loading && searchUser.length > 0 && (
            <div style={{ marginTop: '1rem' }}>
              {searchUser.map((user) => (
                <UserSearchCard key={user.id} user={user} onClose={onClose} />
              ))}
            </div>
          )}
        </div>

        <button
          style={{
            position: 'absolute',
            top: '0.5rem',
            right: '0.5rem',
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
            color: '#4b5563',
            transition: 'color 0.3s ease',
            padding: '0.5rem',
            borderRadius: '50%',
          }}
          onClick={onClose}
          aria-label="Close search"
          onMouseEnter={(e) => (e.target.style.color = '#1f2937')}
          onMouseLeave={(e) => (e.target.style.color = '#4b5563')}
        >
          <IoClose size={24} />
        </button>
      </div>
    </div>
  );
};

export default SearchUser;
